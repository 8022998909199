<template>
    <grid-card title="Basic information">
        <div class="grid_card-content_grid">
            <div class="content_grid-item content_grid-item--full">
                <b-field label="Building type">
                    <b-select :value="getFeatures.f_building_type" expanded disabled>
                        <option value="apartment">
                            {{ $t('value.apartment') }}
                        </option>
                        <option value="house">
                            {{ $t('value.house') }}
                        </option>
                        <option value="new_apartment">
                            {{ $t('value.new_apartment') }}
                        </option>
                        <option value="new_house">
                            {{ $t('value.new_house') }}
                        </option>
                        <option value="construction_plot">
                            {{ $t('value.construction_plot') }}
                        </option>
                    </b-select>
                </b-field>
            </div>
            <div class="content_grid-item">
                <b-field label="Building found">
                    <b-input :value="getFeatures.notfound ? 'No' : 'Yes'" disabled />
                </b-field>
            </div>
            <div class="content_grid-item content_grid-item--is_full">
                <b-field label="Level">
                    <b-input :value="getFeatures.level" disabled />
                </b-field>
            </div>
        </div>
    </grid-card>
</template>
<script>
import GridCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard'
import { mapGetters } from 'vuex'
export default {
    name: 'BasicInfo',
    components: {
        GridCard,
    },
    computed: {
        ...mapGetters('valuationStore/Address', ['getFeatures']),
    },
}
</script>
