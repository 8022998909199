<template>
    <div class="analytics_modal">
        <div class="analytics_modal-header">
            <h4 class="analytics_modal-header-title">Analytics</h4>
        </div>
        <div class="analytics_modal-content">
            <analytics-item
                title="Price"
                :population="prices"
                :target="targetPrice"
                :assessed="priceFormat(targetPrice)"
                :median="priceFormat(medianPrice)"
                :mean="priceFormat(meanPrice)"
                :deviance="priceFormat(priceDeviance)"
                :percentile="percentileFormat(priceTile)"
            />
            <template v-if="is_building">
                <analytics-item
                    title="EPC"
                    :population="epcs"
                    :target="targetEpc"
                    :median="medianEpc"
                    :mean="meanEpc"
                    :deviance="epcDeviance"
                    :percentile="percentileFormat(epcTile)"
                />
                <analytics-item
                    title="Construction year"
                    :population="years"
                    :target="targetYear"
                    :median="medianYear"
                    :mean="meanYear"
                    :deviance="yearDeviance"
                    :percentile="percentileFormat(yearTile)"
                />
                <analytics-item
                    title="Living area"
                    :population="livingAreas"
                    :target="targetLivingArea"
                    :median="medianLiving"
                    :mean="meanLiving"
                    :deviance="livingDeviance"
                    :percentile="percentileFormat(livingTile)"
                />
            </template>
            <analytics-item
                v-if="!is_apartment"
                title="Parcel area"
                :population="parcelAreas"
                :target="targetParcelArea"
                :median="medianParcel"
                :mean="meanParcel"
                :deviance="parcelDeviance"
                :percentile="percentileFormat(parcelTile)"
            />
            <template v-if="is_house">
                <analytics-item
                    title="Building area"
                    :population="buildingAreas"
                    :target="targetBuildingArea"
                    :median="medianBuilding"
                    :mean="meanBuilding"
                    :deviance="buildingDeviance"
                    :percentile="percentileFormat(buildingTile)"
                />
                <analytics-item
                    title="Garden area"
                    :population="gardenAreas"
                    :target="targetGardenArea"
                    :median="medianGarden"
                    :mean="meanGarden"
                    :deviance="gardenDeviance"
                    :percentile="percentileFormat(gardenTile)"
                />
                <analytics-item
                    title="Touching sides"
                    :population="touchingSides"
                    :target="targetTouchingSides"
                    :median="medianSides"
                    :mean="meanSides"
                    :deviance="sidesDeviance"
                    :percentile="percentileFormat(sidesTile)"
                />
            </template>
        </div>
    </div>
</template>
<script>
import AnalyticsItem from './AnalyticsItem.vue'
import utils from '@/shared/plugins/utils'
import * as d3 from 'd3'
import { mapGetters } from 'vuex'

export default {
    name: 'Analytics',
    components: {
        AnalyticsItem,
    },
    computed: {
        ...mapGetters('valuationStore/Address', [
            'getAvmTransaction',
            'getAvmTransactionEstimations',
            'getFeatures',
        ]),
        building_type() {
            return this.getFeatures.f_building_type
        },
        is_house() {
            return utils.is_house(this.building_type)
        },
        is_apartment() {
            return utils.is_apartment(this.building_type)
        },
        is_plot() {
            return utils.is_plot(this.building_type)
        },
        is_building() {
            return utils.is_building(this.building_type)
        },
        prices() {
            const array = this.getAvmTransaction.body.reference_listings.map(
                ({ prijs }) => prijs
            )
            array.sort((a, b) => a - b)
            return array
        },
        targetPrice() {
            return this.getAvmTransactionEstimations.asking_price_q50
        },
        medianPrice() {
            return d3.quantileSorted(this.prices, 0.5)
        },
        meanPrice() {
            return d3.mean(this.prices)
        },
        priceDeviance() {
            return d3.deviation(this.prices)
        },
        priceTile() {
            return (d3.bisect(this.prices, this.targetPrice) / this.prices.length) * 100
        },

        epcs() {
            const array = this.getAvmTransaction.body.reference_listings.map(
                ({ f_epc }) => f_epc
            )
            array.sort((a, b) => a - b)
            return array
        },
        targetEpc() {
            return this.getAvmTransaction.body.input_data.f_epc
        },
        medianEpc() {
            return Math.round(d3.quantileSorted(this.epcs, 0.5) * 100) / 100
        },
        meanEpc() {
            return Math.round(d3.mean(this.epcs) * 100) / 100
        },
        epcDeviance() {
            return Math.round(d3.deviation(this.epcs) * 100) / 100
        },
        epcTile() {
            return (d3.bisect(this.epcs, this.targetEpc) / this.epcs.length) * 100
        },

        years() {
            const array = this.getAvmTransaction.body.reference_listings.map(
                ({ f_construction_year }) => f_construction_year
            )

            if (
                this.targetYear > Math.max(...array) ||
                this.targetYear < Math.min(...array)
            ) {
                array.push(this.targetYear)
            }

            array.sort((a, b) => a - b)
            return array
        },
        targetYear() {
            return this.getAvmTransaction.body.input_data.f_construction_year
        },
        medianYear() {
            return Math.round(d3.quantileSorted(this.years, 0.5))
        },
        meanYear() {
            return Math.round(d3.mean(this.years))
        },
        yearDeviance() {
            return Math.round(d3.deviation(this.years))
        },
        yearTile() {
            return (d3.bisect(this.years, this.targetYear) / this.years.length) * 100
        },

        livingAreas() {
            const array = this.getAvmTransaction.body.reference_listings.map(
                ({ f_living_area }) => f_living_area
            )
            array.sort((a, b) => a - b)
            return array
        },
        targetLivingArea() {
            return this.getAvmTransaction.body.input_data.f_living_area
        },
        medianLiving() {
            return Math.round(d3.quantileSorted(this.livingAreas, 0.5) * 100) / 100
        },
        meanLiving() {
            return Math.round(d3.mean(this.livingAreas) * 100) / 100
        },
        livingDeviance() {
            return Math.round(d3.deviation(this.livingAreas) * 100) / 100
        },
        livingTile() {
            return (
                (d3.bisect(this.livingAreas, this.targetLivingArea) /
                    this.livingAreas.length) *
                100
            )
        },

        parcelAreas() {
            const array = this.getAvmTransaction.body.reference_listings.map(
                ({ f_parcel_area }) => f_parcel_area
            )
            array.sort((a, b) => a - b)
            return array
        },
        targetParcelArea() {
            return this.getAvmTransaction.body.input_data.f_parcel_area
        },
        medianParcel() {
            return Math.round(d3.quantileSorted(this.parcelAreas, 0.5) * 100) / 100
        },
        meanParcel() {
            return Math.round(d3.mean(this.parcelAreas) * 100) / 100
        },
        parcelDeviance() {
            return Math.round(d3.deviation(this.parcelAreas) * 100) / 100
        },
        parcelTile() {
            return (
                (d3.bisect(this.parcelAreas, this.targetParcelArea) /
                    this.parcelAreas.length) *
                100
            )
        },

        buildingAreas() {
            const array = this.getAvmTransaction.body.reference_listings.map(
                ({ f_building_area }) => f_building_area
            )
            array.sort((a, b) => a - b)
            return array
        },
        targetBuildingArea() {
            return this.getAvmTransaction.body.input_data.f_building_area
        },
        medianBuilding() {
            return Math.round(d3.quantileSorted(this.buildingAreas, 0.5) * 100) / 100
        },
        meanBuilding() {
            return Math.round(d3.mean(this.buildingAreas) * 100) / 100
        },
        buildingDeviance() {
            return Math.round(d3.deviation(this.buildingAreas) * 100) / 100
        },
        buildingTile() {
            return (
                (d3.bisect(this.buildingAreas, this.targetBuildingArea) /
                    this.buildingAreas.length) *
                100
            )
        },

        gardenAreas() {
            const array = this.getAvmTransaction.body.reference_listings.map(
                ({ f_garden_area }) => f_garden_area
            )
            array.sort((a, b) => a - b)
            return array
        },
        targetGardenArea() {
            return this.getAvmTransaction.body.input_data.f_garden_area
        },
        medianGarden() {
            return Math.round(d3.quantileSorted(this.gardenAreas, 0.5) * 100) / 100
        },
        meanGarden() {
            return Math.round(d3.mean(this.gardenAreas) * 100) / 100
        },
        gardenDeviance() {
            return Math.round(d3.deviation(this.gardenAreas) * 100) / 100
        },
        gardenTile() {
            return (
                (d3.bisect(this.gardenAreas, this.targetGardenArea) /
                    this.gardenAreas.length) *
                100
            )
        },

        touchingSides() {
            const array = this.getAvmTransaction.body.reference_listings.map(
                ({ f_touching_sides }) => f_touching_sides
            )
            array.sort((a, b) => a - b)
            return array
        },
        targetTouchingSides() {
            return this.getAvmTransaction.body.input_data.f_touching_sides
        },
        medianSides() {
            return d3.quantileSorted(this.touchingSides, 0.5)
        },
        meanSides() {
            return d3.mean(this.touchingSides)
        },
        sidesDeviance() {
            return d3.deviation(this.touchingSides)
        },
        sidesTile() {
            return (
                (d3.bisect(this.touchingSides, this.targetTouchingSides) /
                    this.touchingSides.length) *
                100
            )
        },
    },
    methods: {
        priceFormat(x, thousands = true) {
            return utils.priceFormat(x, thousands)
        },
        percentileFormat(x) {
            return (Math.round(x * 100) / 100).toString()
        },
    },
}
</script>
<style lang="scss" scoped>
.analytics_modal {
    height: fit-content;
    width: 1310px;
    border-radius: 8px;
    border: 1px solid #d6e2e9;
    background: white;
    box-shadow: 0px 1px 2px 0px rgba(0, 24, 55, 0.06),
        0px 4px 6px 0px rgba(0, 24, 55, 0.06);
    overflow: hidden;

    &-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        padding: 24px;
        border-bottom: 1px solid #d6e2e9;

        &-title {
            color: #001837;
            font-size: 20px;
            font-weight: 700;
            flex-grow: 1;
        }
    }

    &-content {
        background: #d6e2e9;
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 1fr 1fr;
        gap: 1px;
        overflow: scroll;
        max-height: 75vh;
    }
}
</style>
