<template>
    <div id="ref_map_page">
        <div class="ref_map_page-header">
            <clear-button class="back_buttn" @click="goBack">
                <img class="chevron chevron__back" :src="chevron" />
                <span>Back</span>
            </clear-button>
        </div>
        <div class="reference_list">
            <listing
                v-for="listing in reference_listings"
                :id="`ref_listing_${listing.index}`"
                :key="`ref_listing_${listing.index}`"
                :listing="listing"
                :selected="selectedListing === listing.index"
                @select="selectListing"
            />
        </div>
        <div class="map-container">
            <Map
                v-if="isRequestLoaded"
                :center="[getFeatures.f_lat, getFeatures.f_lng]"
                :region="getFeatures.region"
                :population="reference_listings"
                :assessed="getFeatures"
                :price="getAvmTransactionEstimations.asking_price_q50"
                :prices="prices"
                :price-per-sqm-array="pricePerSqmArray"
                :address="getAddress"
                interactive
                :selected-listing="selectedListing"
                @updateRemarks="setInternalRemarks"
                @select="selectListing"
            />
            <b-skeleton
                height="320px"
                width="100%"
                :rounded="false"
                :active="!isRequestLoaded"
            />
        </div>
    </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import Map from '../../elements/RefMapComponent.vue'
import Listing from './Listing.vue'
import ClearButton from '@/components/elements/ClearButton.vue'
import chevron from '@/shared/assets/icons/chevron.svg'

export default {
    name: 'RefMap',
    components: {
        Map,
        ClearButton,
        Listing,
    },
    data: () => ({
        chevron,
        selectedListing: null,
    }),
    computed: {
        ...mapGetters('valuationStore/Address', [
            'getFeatures',
            'getAvmTransaction',
            'getAvmTransactionEstimations',
            'getAddress',
            'getDVMFeatures',
            'isRequestLoaded',
        ]),
        prices() {
            const array = this.getAvmTransaction.body.reference_listings.map(
                ({ prijs }) => prijs
            )
            array.sort((a, b) => a - b)
            return array
        },
        pricePerSqmArray() {
            const array = this.getAvmTransaction.body.reference_listings.map(
                ({ prijs, f_living_area }) => Math.round(prijs / f_living_area)
            )
            array.sort((a, b) => a - b)
            return array
        },
        reference_listings() {
            let list = [...this.getAvmTransaction.body.reference_listings]
            list.forEach((_, i) => {
                list[i].index = i
            })

            return list
        },
    },
    mounted() {
        if (this.$route.params.hasOwnProperty('listing')) {
            setTimeout(
                () =>
                    this.selectListing({
                        listing: this.$route.params.listing,
                        src: 'map',
                    }),
                50
            )
        }
    },
    methods: {
        ...mapMutations('valuationStore/Address', ['SET_DVM_FEATURES']),
        setInternalRemarks(remarks) {
            let internal_remarks = this.getDVMFeatures.internal_remarks

            if (!utils.isEmptyStr(internal_remarks)) internal_remarks += '\n'

            internal_remarks += remarks

            this.SET_DVM_FEATURES({ internal_remarks: internal_remarks })
        },
        goBack() {
            this.$router.push({
                name: 'valuation.valuer-app.general',
                params: { lang: this.$route.params.lang },
                query: this.$route.query,
            })
        },
        selectListing({ listing, src }) {
            if (this.selectedListing !== listing.index) {
                this.selectedListing = listing.index

                if (src !== 'list') {
                    document
                        .getElementById(`ref_listing_${listing.index}`)
                        .scrollIntoView()
                }
            } else {
                this.selectedListing = null
            }
        },
    },
}
</script>
<style lang="scss" scoped>
#ref_map_page {
    display: grid;
    grid-template: min-content auto / 400px auto;
    height: calc(100vh - 52px);
    width: 100%;

    .map-container {
        align-self: stretch;
        width: 100%;
        grid-column: 2;
        grid-row: 2;
    }

    .reference_list {
        grid-column: 1;
        grid-row: 2;
        border-right: 1px solid #d6e2e9;
        align-self: stretch;
        overflow-y: scroll;
    }

    .ref_map_page-header {
        padding: 16px;
        grid-column: 1 / -1;
        border-bottom: 1px solid #d6e2e9;

        .back_buttn {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: #001837;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            .chevron {
                transform: rotate(90deg);
                height: 8px;
                margin-right: 12px;
            }
        }
    }
}
</style>
