<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="ers-page-content">
        <div class="ers-page-title">
            <h1>
                {{ $t('energy.epc_score.question') }}
                <Tooltip :label="$t('energy.epc_score.tooltip')" />
            </h1>
            <p>{{ $t('energy.epc_score.subtitle') }}</p>
        </div>

        <multiple-choice v-model="epc_type" :answers="epc_types" />

        <div v-if="is_epc_number" class="epc-section">
            <p>{{ $t('energy.epc_score.subtitle_2') }}</p>
            <b-field
                class="ers-input-field"
                :type="{
                    'is-danger': !$v.f_epc_numeric.numeric,
                }"
                :message="[
                    !$v.f_epc_numeric.numeric ? $t('validation.only_numbers') : null,
                ]"
            >
                <b-input v-model="f_epc_numeric" size="is-medium" class="" />
                <p class="unit">
                    {{ $t('unit.kwh/m2year') }}
                </p>
            </b-field>
        </div>

        <div v-if="is_epc_label" class="epc-section">
            <p>{{ $t('features_question.enter_epc_label') }}</p>
            <multiple-choice v-model="f_epc_label" :answers="epc_labels" small center />
        </div>

        <div class="is-flex is-justify-content-space-between">
            <b-button
                size="is-medium"
                type="is-dark"
                icon-left="arrow-left"
                @click="changePage(false)"
            >
                {{ $t('common.previous') }}
            </b-button>
            <submit-button
                @on-click="
                    $emit('open-summary-modal', {
                        unsubmit: false,
                    })
                    changePage(true)
                "
            />
            <b-button
                v-if="showNextButton"
                :class="{ 'ers-next-bttn-dark': hasRequestBeenUnsubmitted }"
                size="is-medium"
                type="is-primary"
                icon-right="arrow-right"
                @click="changePage(true)"
            >
                <span>{{ $t('common.next') }}</span>
            </b-button>
        </div>
        <div v-if="warningModal" class="ers-page-modal-warning">
            <img src="@/assets/icons/ic-tooltip-warning.svg" alt="" srcset="" />
            <div
                class="ers-page-modal-warning-text"
                v-html="$t('energy.epc_score.warning_tooltip')"
            />
            <b-button
                class="ers-page-modal-warning-bttn"
                size="is-medium"
                type="is-primary"
                @click="warningModal = false"
            >
                <span>{{ $t('energy.end_section.got_it') }}</span>
            </b-button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import utils from '@/shared/plugins/utils'
import MultipleChoice from '../../elements/MultipleChoice.vue'
import YesNo from '../../elements/YesNo.vue'
import Tooltip from '../../elements/Tooltip.vue'
import SubmitButton from '../../elements/SubmitButton.vue'
import epcIcon from '@/assets/icons/ic-epc-grey.svg'
import energyIcon from '@/assets/icons/ic-energy-grey.svg'
import { numeric } from 'vuelidate/lib/validators'

export default {
    name: 'EpcScore',
    components: { MultipleChoice, SubmitButton, Tooltip },
    props: {
        proceedFn: {
            type: Function,
            required: false,
            default: null,
        },
        cancelFn: {
            type: Function,
            required: false,
            default: null,
        },
    },
    data: () => ({
        isFirstPass: true,
        epc_type: null,
        f_epc_label: null,
        f_epc_numeric: 0,
        has_epc: null,
        warningModal: true,
    }),
    validations: {
        f_epc_numeric: {
            numeric,
        },
    },
    computed: {
        ...mapGetters('ersStore', [
            'getEpcVars',
            'getFeatures',
            'getOtherData',
            'hasRequestBeenUnsubmitted',
        ]),
        epc_labels() {
            return Object.keys(
                utils.epc_mapping[utils.iso_regions[this.getFeatures.region]]
            ).map((label) => ({
                name: label,
                value: label,
            }))
        },
        epc_types() {
            return [
                {
                    name: this.$t('energy.epc_score.answer_kwh'),
                    value: 'units',
                    picto: epcIcon,
                },
                {
                    name: this.$t('energy.epc_score.answer_label'),
                    value: 'label',
                    picto: energyIcon,
                },
            ]
        },
        is_epc_number() {
            return this.epc_type === 'units'
        },
        is_epc_label() {
            return this.epc_type === 'label'
        },
        showNextButton() {
            return (
                (this.epc_type === 'units' && this.f_epc_numeric) ||
                (this.epc_type === 'label' && this.f_epc_label)
            )
        },
    },
    watch: {
        epc_type(val, prevVal) {
            if (prevVal != val) {
                if (val !== 'units') {
                    this.f_epc_numeric = null
                }
                if (val !== 'label') {
                    this.f_epc_label = null
                }

                if (val === 'idk' && this.isFirstPass) {
                    if (this.isFirstPass) {
                        this.changePage(true)
                    } else {
                        this.isFirstPass = true
                    }
                }
            }
        },
        f_epc_label(val, prevVal) {
            if (val && prevVal != val && this.isFirstPass) {
                this.changePage(true)
            } else {
                this.isFirstPass = true
            }
        },
    },
    mounted() {
        this.fetch_features().then((res) => {
            const prevEpcVars = this.getEpcVars

            this.epc_type = prevEpcVars.is_epc_number
                ? 'units'
                : prevEpcVars.is_epc_label
                ? 'label'
                : null

            if (this.epc_type) {
                this.isFirstPass = false
            }

            this.f_epc_label = prevEpcVars.f_epc_label
            this.f_epc_numeric = prevEpcVars.f_epc_numeric

            this.features = res
        })
    },
    methods: {
        ...mapMutations('ersStore', ['UPDATE_STATUS', 'SET_FEATURES']),
        ...mapActions('ersStore', [
            'submitFeatures',
            'fetch_features',
            'set_and_submit_feature',
        ]),
        changePage(isNextPage) {
            const nextPage = isNextPage ? 'ers.construction-year' : 'ers.epc'
            const feature = {
                is_epc_number: this.is_epc_number,
                is_epc_label: this.is_epc_label,
                f_epc_label: this.f_epc_label,
                f_epc_numeric: +this.f_epc_numeric,
            }
            this.set_and_submit_feature({
                feature,
                nextPage,
            })
            this.$router.push({
                name: nextPage,
                params: {
                    lang: this.$route.params.lang,
                },
                query: this.$route.query,
            })
        },
    },
}
</script>

<style lang="scss">
.ers-input-field {
    max-width: 5.75rem !important;
}

.ers-page-modal-warning {
    position: absolute;
    top: 33%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    padding: 2rem;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    border-radius: 0.3125rem;
    background: #fff;
    box-shadow: 4px 5px 10px 2px rgba(0, 0, 0, 0.07);
    img {
        width: 2.8125rem;
        height: 2.625rem;
    }
    &-text {
        display: flex;
        gap: 2rem;
        flex-direction: column;

        h1 {
            color: #001837;
            text-align: center;
            font-size: 0.975rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
        p {
            color: #001837;
            text-align: center;
            font-size: 0.78rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    &-bttn {
    }
}
</style>

<style scoped lang="scss">
.epc-section {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.epc-input {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    & > * {
        flex-grow: 1;
    }
}

.is-small-tooltip {
    font-size: 14px;
    .info {
        height: 20px;
        width: 20px;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.is-tootip-btn {
    height: 24px;
    width: 24px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.is-input-number {
    .icons {
        width: 40px;
        font-size: 20px;
        div {
            height: 25px;
            &:first-child {
                border-top-right-radius: 4px;
                border-top: 1px solid;
                border-bottom: 1px solid;
                border-right: 1px solid;
                border-color: #e5eaed;
            }
            &:last-child {
                border-bottom: 1px solid;
                border-right: 1px solid;
                border-color: #e5eaed;
                border-bottom-right-radius: 4px;
            }
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease-in-out;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.unit {
    display: grid;
    align-items: center;
    z-index: 4;
    transform: translateX(-150%);
}
</style>
