var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"address_map is-relative"},[_c('b-loading',{attrs:{"is-full-page":false,"can-cancel":false},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}}),(_vm.noBuildingMatch)?_c('div',{staticClass:"is-user-message"},[_c('b-message',{attrs:{"active":_vm.noBuildingMatch,"title":_vm.$t('message'),"type":"is-info","has-icon":"","aria-close-label":"Close message"}},[_c('span',{staticClass:"is-flex is-flex-direction-column"},[_c('span',[_c('strong',{staticClass:"is-capitalized"},[_vm._v(" "+_vm._s(_vm.$t('Address'))+" ")]),_vm._v(" "+_vm._s(_vm.getAddress.full_address)+" ")]),_c('span',[_vm._v(_vm._s(_vm.$t('full_address')))])])])],1):_vm._e(),_c('re-map',{attrs:{"building-id":_vm.getFeatures.building_id,"parcel-ids":_vm.getFeatures.parcel_ids,"level":_vm.getFeatures.level,"center":{
            lat: _vm.get_map_scene2d.center.lat,
            lng: _vm.get_map_scene2d.center.lng,
        },"map-scene2d":_vm.get_map_scene2d,"read-only":!_vm.isDraft ||
                _vm.$route.params.action === 'confirm-main-building' ||
                _vm.$route.params.action === 'confirm-main-parcel',"force-update-parcels":_vm.isDraft &&
                (_vm.$route.params.action === 'confirm-main-building' ||
                    _vm.$route.params.action === 'confirm-main-parcel'),"focus-layer":_vm.focusLayer,"layers-control-visible":false,"flood-visible":false},on:{"update:mapScene2d":function($event){return _vm.SET_MAP_SCENE2D($event)},"update:buildingId":_vm.update_buildingid_and_address,"update:parcelIds":_vm.update_parcel_ids}}),(_vm.show_parcel_area && !_vm.noParcelSelected)?_c('div',{staticClass:"card-wrapper is-absolute area-indicator"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content"},[_vm._v(" "+_vm._s(_vm.$t('features.f_parcel_area'))+" "),_c('b',{staticClass:"has-text-primary"},[_vm._v(_vm._s(_vm.parcel_area)+" "+_vm._s(_vm.$t('unit.m2')))])])])]):_vm._e(),_c('div',{staticClass:"card-wrapper is-absolute"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"is-flex is-justify-content-center"},[_c('b-button',{attrs:{"tag":"router-link","size":"is-medium","type":"is-dark","icon-left":"arrow-left","to":{
                            name: 'valuation.request',
                            params: {
                                lang: _vm.$route.params.lang,
                                action: _vm.prev_action,
                            },
                            query: _vm.$route.query,
                        }}},[_c('span',[_vm._v(_vm._s(_vm.$t('previous')))])]),(_vm.$te('explanation-' + _vm.$route.params.action))?_c('div',{staticClass:"px-4 has-text-black has-text-centered is-w100"},[_vm._v(" "+_vm._s(_vm.$t( 'request.map_select.explanation-' + _vm.explanation_action_copy ))+" "),_c('b-tooltip',{staticClass:"pl-3 is-clickable is-relative",attrs:{"size":"is-medium","label":_vm.tooltip_label,"multilined":""}},[_c('div',{staticClass:"is-rounded has-text-primary has-background-link is-tootip-btn is-flex is-align-items-center is-justify-content-center has-text-weight-bold"},[_vm._v(" ? ")])])],1):_vm._e(),_c('b-button',{attrs:{"disabled":!_vm.next_possible,"size":"is-medium","type":"is-primary","icon-right":"arrow-right"},on:{"click":_vm.nextPage}},[_c('span',[_vm._v(" "+_vm._s(_vm.next_action === 'extra-info' && _vm.isDraft ? _vm.$t('confirm') : _vm.$route.params.action === 'confirm-main-building' ? _vm.$t('yes') : _vm.$t('next'))+" ")])])],1)]),(_vm.$te('cannot-find-' + _vm.$route.params.action) && !_vm.noParcelSelected)?_c('footer',{staticClass:"card-footer"},[_c('div',{staticClass:"is-flex is-justify-content-center is-w100"},[_c('a',{staticClass:"has-text-primary has-text-centered py-3",on:{"click":_vm.unhappyFlow}},[_vm._v(" "+_vm._s(_vm.$t( 'request.map_select.cannot-find-' + _vm.$route.params.action ))+" ")])])]):_vm._e()])]),_c('b-modal',{staticClass:"modal",attrs:{"has-modal-card":"","trap-focus":"","can-cancel":['escape'],"destroy-on-hide":true,"aria-role":"dialog","aria-modal":"","on-cancel":_vm.cancelMultipleAddresses},on:{"click":function () {
                return
            }},model:{value:(_vm.isAddressesModalActive),callback:function ($$v) {_vm.isAddressesModalActive=$$v},expression:"isAddressesModalActive"}},[(_vm.isAddressesModalActive)?_c('div',{staticClass:"exit-modal"},[_c('h2',{staticClass:"title is-size-4 has-text-weight-bold mgt-medium is-flex is-align-items-center pb-4"},[_vm._v(" "+_vm._s(_vm.$t('mutliple_addresses_modal.title'))+" ")]),_c('p',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('mutliple_addresses_modal.content')))]),_vm._l((_vm.buildingAddresses),function(el){return _c('b-button',{key:("" + (el.properties.building_id) + (el.properties.streetname) + (el.properties.streetnumber) + (el.properties.language)),staticClass:"button is-ghost ghost-button",attrs:{"type":"is-ghost"},on:{"click":function () { return _vm.setAddress(el); }}},[_vm._v(" "+_vm._s(el.properties.streetname)+" "+_vm._s(el.properties.streetnumber)+" ")])}),_c('b-button',{staticClass:"mt-2",attrs:{"size":"is-medium","type":"is-dark"},on:{"click":_vm.cancelMultipleAddresses}},[_vm._v(" "+_vm._s(_vm.$t('mutliple_addresses_modal.cancel'))+" ")])],2):_vm._e()]),_c('b-modal',{attrs:{"trap-focus":"","has-modal-card":""},model:{value:(_vm.isDestructiveSave),callback:function ($$v) {_vm.isDestructiveSave=$$v},expression:"isDestructiveSave"}},[_c('div',{staticClass:"card modal-card p-5 destructive-save-modal"},[_c('h4',{staticClass:"is-size-5 has-text-weight-bold has-text-black"},[_vm._v(" "+_vm._s(_vm.$t('destructive_safeguard.title'))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('destructive_safeguard.content'))+" ")]),_c('div',{staticClass:"btn-group"},[_c('b-button',{on:{"click":_vm.closeDestructiveSaveModal}},[_vm._v(" "+_vm._s(_vm.$t('destructive_safeguard.cancel'))+" ")]),_c('b-button',{attrs:{"type":"is-primary"},on:{"click":_vm.confirmDestructiveSave}},[_vm._v(" "+_vm._s(_vm.$t('destructive_safeguard.confirm'))+" ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }