<template>
    <div class="floorplan_layout">
        <div class="floorplan_layout-header">
            <h1 class="floorplan_layout-header-breadcrumbs">
                <span>Floors</span>
                <img :src="chevron" class="chevron" />
                <span>Floor {{ floor_level }}</span>
            </h1>
            <div class="floorplan_layout-header-actions">
                <b-button
                    v-if="showGenerate"
                    type="is-primary"
                    icon-right="flash"
                    @click="generateRooms"
                >
                    Generate rooms
                </b-button>
                <b-button type="is-primary" icon-right="plus" @click="openRoomsModal">
                    Add a room
                </b-button>
            </div>
        </div>
        <empty-list v-if="is_rooms_empty" type="rooms" />
        <div v-else class="rooms">
            <grid-card
                v-for="(room, i) in rooms"
                :key="`room_${i}`"
                class="room"
                no-padding
                @click="editRoom(i)"
            >
                <div class="room-header">
                    <h2>{{ room.type }}</h2>
                    <div class="room-header-actions">
                        <icon-button icon="delete" @click="deleteRoom(i)" />
                        <icon-button icon="edit" @click="editRoom(i)" />
                    </div>
                </div>
                <div class="room-content">
                    <div class="room-content-item">
                        <label>{{ $t('features.f_living_area') }}</label>
                        <span>{{ room.living_area }} {{ $t('unit.m2') }}</span>
                    </div>
                    <div class="room-content-item">
                        <label>{{ $t('global.comments') }}</label>
                        <span>{{ room.comment }}</span>
                    </div>
                </div>
                <div />
            </grid-card>
        </div>
        <b-modal
            v-if="is_room_modal_open"
            v-model="is_room_modal_open"
            destroy-on-hide
            has-modal-card
            trap-focus
            :can-cancel="['escape']"
            aria-role="dialog"
            aria-modal
            class="modal"
            @close="closeRoomsModal"
        >
            <div class="room_modal">
                <div class="room_modal-header">
                    <h4 class="room_modal-header-title">
                        Add a room
                    </h4>
                </div>
                <div class="room_modal-content">
                    <b-field
                        label="What is the type of room?"
                        :type="{ 'is-danger': $v.new_room.type.$invalid }"
                        :message="
                            !$v.new_room.type.singleIteration
                                ? `A room named ${new_room.type} already exists.`
                                : null
                        "
                    >
                        <b-input v-model="new_room.type" type="text" />
                    </b-field>
                    <b-field :label="$t('features.f_living_area')">
                        <b-input v-model="new_room.living_area" type="number" />
                    </b-field>
                    <b-field :label="$t('global.comments')" class="input--full">
                        <b-input v-model="new_room.comment" type="textarea" />
                    </b-field>
                    <div class="upload_container">
                        <div class="upload_container-pictures">
                            <img
                                v-for="(pic, i) in picsInRoom"
                                :key="`new_room_pic_${i}`"
                                :src="pic[i]"
                            />
                        </div>
                        <upload-area
                            purpose="interior_picture"
                            :doc-params="modalDocData"
                            :request-ref="getRequestRef"
                            :disabled="isModalUploadDisabled"
                            type="picture"
                            @uploaded="add_documents"
                        />
                    </div>
                </div>
                <div class="room_modal-footer">
                    <b-button @click="closeRoomsModal">Cancel</b-button>
                    <b-button
                        type="is-primary"
                        :disabled="isConfirmRoomDisabled"
                        @click="confirmRoom()"
                    >
                        Save
                    </b-button>
                    <b-button
                        v-if="isEditing === false"
                        type="is-primary"
                        :disabled="isConfirmRoomDisabled"
                        @click="confirmRoom(true)"
                    >
                        Save and create a new room
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import IconButton from '@/components/elements/IconButton.vue'
import UploadArea from '@/apps/Valuation/components/ValuerTool/elements/UploadArea.vue'
import GridCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard'
import EmptyList from './EmptyList.vue'
import chevron from '@/shared/assets/icons/chevron.svg'
import utils from '@/shared/plugins/utils'

let newRoom = () => ({
    type: '',
    living_area: 0,
    comment: '',
})

export default {
    name: 'Rooms',
    components: {
        EmptyList,
        IconButton,
        GridCard,
        UploadArea,
    },
    data: () => ({
        rooms: [],
        is_room_modal_open: false,
        new_room: newRoom(),
        chevron,
        isEditing: false,
    }),
    validations: {
        new_room: {
            type: {
                singleIteration: function(value) {
                    let foundItem = this.rooms.findIndex((x) => x.type === value)
                    return foundItem === -1 || foundItem === this.isEditing
                },
            },
        },
    },
    computed: {
        ...mapGetters('auth', ['hasRole']),
        ...mapGetters('valuationStore', ['getDocuments', 'getRequestRef']),
        ...mapGetters('valuationStore/Address', ['getOVMFeatures', 'getFeatures']),
        showGenerate() {
            return this.is_rooms_empty && this.hasRole('admin')
        },
        is_rooms_empty() {
            return Array.isArray(this.rooms) ? !this.rooms.length : true
        },
        floor_level() {
            return this.$route.query.floor
        },
        isModalUploadDisabled() {
            return utils.isEmptyStr(this.new_room.type)
        },
        floor_id() {
            return this.getOVMFeatures.floors.find(
                (floor) => floor.level === this.floor_level
            ).id
        },
        modalDocData() {
            return {
                floor_id: this.floor_id,
                location: this.new_room.type,
            }
        },
        isConfirmRoomDisabled() {
            return utils.isEmptyStr(this.new_room.type) || !this.new_room.living_area
        },
    },
    mounted() {
        let level = this.floor_level
        let floor = this.getOVMFeatures.floors?.find((floor) => floor.level === level)
        if (!floor) {
            this.$buefy.toast.open({
                duration: 5000,
                message: 'Redirected because no floors were found.',
                position: 'is-bottom',
                type: 'is-warning',
            })
            this.$router.push({
                name: 'valuation.valuer-app.interior.floorplan',
                query: { valuation_request_ref: this.$route.query.valuation_request_ref },
            })
        }
        this.rooms = floor?.rooms || []
    },
    methods: {
        ...mapActions('valuationStore', ['remove_document', 'add_documents']),
        ...mapMutations('valuationStore/Address', ['SET_OVM_FEATURES', 'SET_FEATURES']),
        openRoomsModal() {
            this.new_room = newRoom()
            this.is_room_modal_open = true
        },
        closeRoomsModal() {
            this.is_room_modal_open = false
            this.isEditing = false
            this.new_room = newRoom()
        },
        confirmRoom(continueCreating = false) {
            if (this.isEditing === false) {
                this.rooms.push(this.new_room)
            } else {
                this.rooms[this.isEditing] = this.new_room
            }
            if (!continueCreating) {
                this.closeRoomsModal()
            } else {
                this.new_room = newRoom()
            }
        },
        deleteRoom(index) {
            this.rooms.splice(index, 1)
        },
        picsInRoom(room) {
            this.docu
        },
        generateRooms() {
            this.rooms = [
                {
                    type: 'Entryway (test)',
                    living_area: 5,
                    comment:
                        'This is a test comment.\nThe entryway is quite narrow. It has been renovated in 2021.',
                },
                {
                    type: 'Kitchen (test)',
                    living_area: 15,
                    comment:
                        'This is a test comment.\nThe kitchen is nice and has been renovated in 2022.',
                },
                {
                    type: 'Living room (test)',
                    living_area: 25,
                    comment:
                        'This is a test comment.\nThe living room is very spatious but the floors show signs of ageing.',
                },
            ]
        },
        editRoom(index) {
            this.isEditing = index
            this.new_room = { ...this.rooms[index] }
            this.is_room_modal_open = true
        },
        delete_document(document_ref) {
            this.$axios
                .delete(
                    utils.urlJoin(this.$config.VALUATION_API_URL, [
                        'document',
                        document_ref,
                    ])
                )
                .then((_) => {
                    this.remove_document(document_ref)
                })
        },
    },
}
</script>
<style lang="scss" scoped>
.rooms {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;

    .room {
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        &-header {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            &-actions {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 8px;
            }
        }

        &-content {
            display: flex;
            flex-direction: column;
            gap: 16px;

            &-item {
                display: flex;
                flex-direction: column;
                gap: 5px;

                label {
                    color: #798da6;
                    font-size: 12px;
                    font-weight: 500;
                }

                span {
                    color: #001837;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    white-space: pre-line;
                }
            }
        }
    }
}

.upload_container {
    grid-column: 1 / 3;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &-pictures {
        display: flex;
        flex-direction: row;
        gap: 12px;

        img {
            height: 55px;
            width: 55px;
            border-radius: 8px;
            border: 1px solid #d6e2e9;
            object-fit: cover;
            object-position: center center;
        }
    }
}

.room_modal {
    height: fit-content;
    border-radius: 8px;
    border: 1px solid #d6e2e9;
    background: white;
    box-shadow: 0px 1px 2px 0px rgba(0, 24, 55, 0.06),
        0px 4px 6px 0px rgba(0, 24, 55, 0.06);
    overflow: hidden;

    & > * {
        padding: 24px;
    }

    &-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        padding: 24px;

        &-title {
            color: #001837;
            font-size: 20px;
            font-weight: 700;
            flex-grow: 1;
        }
    }

    &-content {
        border-top: 1px solid #d6e2e9;
        border-bottom: 1px solid #d6e2e9;
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 1fr 1fr;
        gap: 24px;
        min-width: 35vw;

        .input--full {
            grid-column: 1 / 3;
        }

        .upload_draggable {
            width: 100%;
        }
    }

    &-footer {
        display: flex;
        flex-direction: row;
        gap: 16px;

        & > * {
            flex-grow: 1;
        }
    }
}
</style>
