<template>
    <div class="interior_page">
        <div class="interior_page-tabs">
            <clear-button
                :class="{ active: $route.name === 'valuation.valuer-app.interior.info' }"
                @click="goTo('valuation.valuer-app.interior.info')"
            >
                Interior
            </clear-button>
            <clear-button
                :class="{ active: $route.name.includes('floorplan') }"
                @click="goTo('valuation.valuer-app.interior.floorplan')"
            >
                Floorplan
            </clear-button>
        </div>
        <div class="interior_page-content">
            <router-view />
        </div>
    </div>
</template>
<script>
import ClearButton from '@/components/elements/ClearButton.vue'

export default {
    name: 'InteriorPage',
    components: {
        ClearButton,
    },
    methods: {
        goTo(target) {
            if (target.toLowerCase() !== this.activeRoute) {
                let { params, query } = this.$route
                this.$router.push({ name: target, params, query })
            }

            scrollTo(0, 0)
        },
    },
}
</script>
<style lang="scss" scoped>
@import '@/shared/assets/style/buefy.scss';

.interior_page {
    display: grid;
    grid-template-rows: 50px auto;
    height: 100%;
    background: #d6e2e9;
    gap: 1px;

    &-tabs {
        background: white;
        display: flex;
        align-items: stretch;
        justify-content: flex-start;

        button {
            border-bottom: 0px solid $primary;
            padding-left: 24px;
            padding-right: 24px;
            padding-bottom: 3px;

            transition: all 0.3s ease-out;

            color: rgba(0, 24, 55, 0.6);
            font-size: 14px;
            font-weight: 500;

            &.active,
            &:hover {
                padding-bottom: 0px;
                border-bottom: 3px solid $primary;

                color: #001837;
                font-weight: 700;
            }
        }
    }

    &-content {
        background: #f9fafc;
        height: 100%;
        overflow-y: scroll;
        padding: 60px;
    }
}
</style>
