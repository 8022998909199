var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"side-stepper"},[_c('div',{staticClass:"steps"},[_c('span',{attrs:{"id":"grey-line"}}),_c('span',{style:({
                'max-height': (_vm.progress + "px"),
                transition: _vm.progressTransition,
            }),attrs:{"id":"progress"}}),_vm._l((_vm.steps),function(step,index){return [_c('span',{key:("step_" + (step.name)),staticClass:"step",class:{
                    active: _vm.currentStep >= index,
                    current: _vm.currentStep === index && !_vm.currentSubstep,
                }},[_c('svg-icon',{attrs:{"icon":step.icon,"width":"20","height":"20","color":_vm.currentStep >= index ? '#fff' : '#798DA6',"fill":true}}),_c('span',{staticClass:"step-name"},[_vm._v(_vm._s(_vm.$t(("steps." + (step.name)))))])],1),_vm._l((step.substeps),function(substep,sub_index){return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentStep === index),expression:"currentStep === index"}],key:("step_" + (step.name) + "_" + substep),staticClass:"step substep",class:{
                        active:
                            _vm.currentStep === index && _vm.currentSubstep >= sub_index + 1,
                        current:
                            _vm.currentStep === index && _vm.currentSubstep === sub_index + 1,
                    }},[_c('span',{staticClass:"step-name"},[_vm._v(_vm._s(_vm.$t(("steps." + substep))))])])]})]}),_c('span',{staticClass:"step",class:{
                active: _vm.currentStep >= 5,
            }},[_c('svg-icon',{attrs:{"icon":"checkered-flag","width":"20","height":"20","color":_vm.currentStep >= 5 ? '#fff' : '#798DA6',"fill":true}}),_c('span',{staticClass:"step-name"},[_vm._v(_vm._s(_vm.$t("steps.finish")))])],1)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }