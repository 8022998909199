<template>
    <div>
        <h5 v-if="question">{{ question }}</h5>
        <div class="checkboxes" :class="{ 'checkboxes--wide': wide }">
            <b-checkbox
                v-for="(option, i) in options"
                :key="`${feature}-answer_${i}`"
                v-model="checked"
                :native-value="option.value"
                :value="option.value"
            >
                {{ option.name }}
            </b-checkbox>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Checkboxes',
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        question: {
            type: String,
            default: null,
        },
        options: {
            type: Array,
            required: true,
        },
        wide: {
            type: Boolean,
            default: false,
        },
        feature: {
            type: String,
            required: true,
        },
    },
    computed: {
        checked: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('select', value)
            },
        },
    },
}
</script>
<style lang="scss" scoped>
.checkboxes {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
    row-gap: 16px;
    justify-items: stretch;
    align-items: center;

    &--wide {
        grid-template-columns: repeat(4, 1fr);
    }

    // &-button {
    //     display: flex;
    //     padding: 16px;
    //     justify-content: center;
    //     align-items: center;
    //     align-self: stretch;
    //     border-radius: 8px;
    //     border: 1px solid #d6e2e9;
    //     background: #fff;
    //     color: rgba(0, 24, 55, 0.7);

    //     box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    //         0px 1px 3px 0px rgba(16, 24, 40, 0.1);

    //     &.selected {
    //         background: #009cff;
    //         color: white;
    //     }
    // }
}
</style>
