<template>
    <div class="people-select">
        <svg-icon
            v-for="index in max"
            :key="`person_${index - 1}`"
            height="90"
            width="56"
            icon="person"
            color="#798DA6"
            class="person"
            :class="{
                selected: amount === max - (index - 1),
            }"
            @click="() => onSelect(index - 1)"
        />
    </div>
</template>
<script>
export default {
    name: 'PeopleSelect',
    model: {
        prop: 'amount',
        event: 'select',
    },
    props: {
        max: {
            type: Number,
            default: 5,
        },
        amount: {
            type: Number,
            required: true,
        },
    },
    methods: {
        onSelect(i) {
            this.$emit('select', this.max - i)
        },
    },
}
</script>
<style lang="scss">
@import '@/shared/assets/style/buefy.scss';
.people-select {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;

    @media screen and (max-width: 768px) {
        row-gap: 20px;
        flex-wrap: wrap-reverse;
    }

    .person {
        path {
            opacity: 0.4;
            transition: all 0.2s ease-in;
        }

        &:hover,
        &:hover ~ .person {
            cursor: pointer;
            path {
                opacity: 1;
                fill: $primary !important;
            }
        }

        &.selected,
        &.selected ~ .person {
            path {
                opacity: 1;
                fill: $primary !important;
            }
        }
    }
}
</style>
