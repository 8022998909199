<template>
    <div>
        <h5 v-if="question">{{ question }}</h5>
        <div
            class="multichoice"
            :class="{
                'multichoice--vertical': vertical,
                'multichoice--wide_options': wideOptions,
            }"
        >
            <clear-button
                v-for="(option, i) in options"
                :key="`${feature}-answer_${i}`"
                class="multichoice-button"
                :class="{ selected: option.selected }"
                @click="$emit('select', option.value)"
            >
                {{ option.name }}
            </clear-button>
        </div>
    </div>
</template>
<script>
import ClearButton from '@/components/elements/ClearButton.vue'

export default {
    name: 'MultiChoice',
    components: {
        ClearButton,
    },
    props: {
        question: {
            type: String,
            default: null,
        },
        options: {
            type: Array,
            required: true,
        },
        vertical: {
            type: Boolean,
            default: false,
        },
        wideOptions: {
            type: Boolean,
            default: false,
        },
        feature: {
            type: String,
            required: true,
        },
    },
}
</script>
<style lang="scss" scoped>
.multichoice {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 12px;
    justify-items: stretch;
    align-items: center;

    &--vertical {
        display: flex;
        flex-direction: column;
    }

    &--wide_options {
        display: flex;
        flex-direction: row;

        & > * {
            flex-grow: 1;
        }
    }

    &-button {
        display: flex;
        padding: 16px;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid #d6e2e9;
        background: #fff;
        color: rgba(0, 24, 55, 0.7);

        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
            0px 1px 3px 0px rgba(16, 24, 40, 0.1);

        &.selected {
            background: #009cff;
            color: white;
        }
    }
}
</style>
