<template>
    <div class="error-page has-text-centered">
        <h1 class="has-text-black has-text-weight-bold is-size-2 mb-2">
            {{ $t('error_page.title') }}
        </h1>
        <p class="is-size-5 mb-3">
            {{ $t('error_page.intro') }}
        </p>

        <img src="@/assets/img/ers/house_reconstruction.gif" />
    </div>
</template>
<script>
export default {
    name: 'Unavailable',
}
</script>
<style lang="scss" scoped>
.error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #f9fafc;
    background-size: cover;
    width: 100vw;
    min-height: 100vh;
    padding: 0 6rem;
    background: #f5f6fa;

    h1 {
        width: 60%;
        min-width: 850px;
        max-width: 90vw !important;
    }

    & > *:not(.skyline) {
        z-index: 1;
    }

    .return-button {
        padding: 14px 40px;
        background-color: #d85f32;
        border-radius: 5px;
        color: white;
        font-weight: 500;
        border: 2px solid #d85f32;

        &:hover {
            background-color: lighten(#d85f32, 10%);
        }
    }
}
</style>
