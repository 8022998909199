<template>
    <div class="ers-page-content">
        <div class="ers-page-title">
            <h1>
                {{ `${$t('customer_ref_page.title')}` }}
                <Tooltip :label="$t('customer_ref_page.tooltip')" />
            </h1>
        </div>
        <form @submit.prevent="sendRequest">
            <b-field
                :message="[
                    !$v.customer_ref.argentaOnlyNumbers
                        ? $t('validation.only_numbers')
                        : null,
                ]"
                :type="{
                    'is-danger': !$v.customer_ref.argentaOnlyNumbers,
                }"
            >
                <b-input
                    v-model="customer_ref"
                    :disabled="!active"
                    :placeholder="customer_ref_placeholder"
                    @blur="$v.customer_ref.$touch()"
                    @input="$v.customer_ref.$touch()"
                ></b-input>
            </b-field>
        </form>
        <div class="ers-page-buttons">
            <b-button
                tag="router-link"
                size="is-medium"
                type="is-dark"
                icon-left="arrow-left"
                :to="{
                    name: 'ers.welcome',
                    params: {
                        lang: $route.params.lang,
                    },
                }"
            >
                <span>{{ $t('common.previous') }}</span>
            </b-button>

            <b-button
                v-if="customer_ref.length && !$v.customer_ref.$invalid"
                size="is-medium"
                type="is-primary"
                icon-right="arrow-right"
                @click="sendRequest"
            >
                <span>{{ $t('common.next') }}</span>
            </b-button>
        </div>
        <b-modal
            v-model="isConflictModalActive"
            has-modal-card
            trap-focus
            :can-cancel="['escape']"
            :destroy-on-hide="false"
            aria-role="dialog"
            aria-modal
        >
            <div v-if="isConflictModalActive" class="exit-modal">
                <h2
                    class="title is-size-4 has-text-weight-bold mgt-medium is-flex is-align-items-center pb-4"
                >
                    {{ $t('global.shared.customer_ref_modal.title') }}
                </h2>
                <p>{{ $t('global.shared.customer_ref_modal.content') }}</p>
                <p>{{ $t('global.shared.customer_ref_modal.cta') }}</p>
                <div>
                    <b-button
                        size="is-medium"
                        type="is-dark"
                        @click="isConflictModalActive = false"
                    >
                        {{ $t('common.previous') }}
                    </b-button>
                    <b-button
                        size="is-medium"
                        type="is-dark"
                        tag="router-link"
                        :to="{
                            name: 'dashboard',
                            params: { lang: $route.params.lang },
                            query: {
                                valuation_request_refs: conflicting_valuation_requests.join(
                                    ','
                                ),
                            },
                        }"
                    >
                        {{ $t('global.shared.customer_ref_modal.dashboard') }}
                    </b-button>
                    <b-button size="is-medium" type="is-primary" @click="ignoreConflict">
                        {{ $t('global.shared.customer_ref_modal.continue') }}
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { decimal, required } from 'vuelidate/lib/validators'
import Vue from 'vue'
import { mapMutations, mapGetters, mapActions } from 'vuex'
import Tooltip from '../elements/Tooltip.vue'
import utils from '@/shared/plugins/utils'

let argentaOnlyNumbers = (value) => {
    const isArgentaEnv = Vue.prototype.$config?.ENVIRONMENT.includes('argenta')
    if (isArgentaEnv) {
        return decimal(value)
    } else {
        return true
    }
}

export default {
    name: 'CustomerRef',
    components: {
        Tooltip,
    },
    data() {
        return {
            isConflictModalActive: false,
            conflicting_valuation_requests: null,
            active: true,
            customer_ref: '',
        }
    },
    validations: {
        customer_ref: {
            argentaOnlyNumbers,
        },
    },
    computed: {
        ...mapGetters('ersStore', ['getCustomerReference', 'getRequestRef']),
        customer_ref_placeholder() {
            return this.$config.CUSTOMER_REF_PLACEHOLDER
        },
    },
    watch: {
        getCustomerReference(newVal, oldVal) {
            this.active = false
            this.customer_ref = this.getCustomerReference
        },
    },
    mounted() {
        if (this.getCustomerReference) {
            this.customer_ref = this.getCustomerReference
            this.active = false
        }
    },
    methods: {
        ...mapActions('ersStore', ['create_valuation_request']),
        ...mapMutations('ersStore', ['SET_REQUEST_REF', 'SET_CUSTOMER_REF']),
        saveCurrentPage(nextPage) {
            return this.$axios.patch(
                utils.urlJoin(
                    this.$config.VALUATION_API_URL,
                    `request/${this.getRequestRef}`
                ),
                {
                    features: { f_building_type: 'house' },
                    other_data: {
                        last_page_info: {
                            name: nextPage,
                        },
                    },
                }
            )
        },
        async sendRequest() {
            if (this.$v.customer_ref.$invalid) return
            if (this.getCustomerReference) {
                this.saveCurrentPage('ers.building-type')
                return this.$router.push({
                    name: 'ers.building-type',
                    params: { lang: this.$i18n.locale },
                    ...this.$route.query,
                })
            }
            const response = await this.create_valuation_request({
                customer_ref: this.customer_ref,
                ignore_conflicts: false,
            })
            if (!response.hasOwnProperty('data')) {
                this.conflicting_valuation_requests = response
                this.isConflictModalActive = true
            } else {
                this.SET_REQUEST_REF(response.data.valuation_request_ref)
                this.SET_CUSTOMER_REF(this.customer_ref)
                this.saveCurrentPage('ers.building-type')
                this.$router.push({
                    name: 'ers.building-type',
                    params: { lang: this.$i18n.locale },
                    query: {
                        ref: response.data.valuation_request_ref,
                    },
                })
            }
        },
        ignoreConflict() {
            this.create_valuation_request({
                customer_ref: this.customer_ref,
                ignore_conflicts: true,
            }).then((res) => {
                this.SET_REQUEST_REF(res.data.valuation_request_ref)
                this.SET_CUSTOMER_REF(this.customer_ref)
                this.$v.$reset()
                this.$router.push({
                    name: 'ers.building-type',
                    params: { lang: this.$i18n.locale },
                    query: {
                        ref: res.data.valuation_request_ref,
                    },
                })
            })
        },
    },
}
</script>

<style scoped lang="scss">
.ers-page-content {
    display: flex;
    flex-wrap: wrap;
    flex: 1 0 0;
    min-width: 100%;
    margin-inline: auto;
}

h1 {
    margin-bottom: 3rem;
}

.ers-page-buttons {
    margin-top: 3rem;
    min-width: 100%;
    display: flex;
    justify-content: space-between;
}
</style>
