<template>
    <svg
        width="100%"
        viewBox="0 0 446 37"
        :class="{ interactive: interactive }"
        class="epc_array"
    >
        <g
            class="epc_arrow"
            :class="{ selected: currentLabel === 'G' }"
            @click="$emit('select-epc', 'G')"
        >
            <path
                d="M0,0h161.7l8,18.1l-8,18.1H0l8-18.1L0,0z"
                fill="#d62015"
                class="epc_arrow-bg"
            />
            <path
                d="M85.1,15.4c-2,0-3.1,1.4-3.1,3.6c0,2.2,1.3,3.4,3.1,3.4c1.7,0,2.8-0.9,2.8-2.5v0h-3v-1.5h4.6v5.6H88l-0.1-1.1
                c-0.5,0.8-1.7,1.3-3,1.3c-2.8,0-4.7-2-4.7-5.1c0-3,2-5.1,4.9-5.1c2.3,0,4,1.3,4.3,3.3h-1.8C87.3,16,86.3,15.4,85.1,15.4z"
                class="epc_arrow-label"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabel === 'F' }"
            @click="$emit('select-epc', 'F')"
        >
            <path
                d="M165.3,0h42.4l8,18.1l-8,18.1h-42.4l8-18.1L165.3,0z"
                fill="#fa9101"
                class="epc_arrow-bg"
            />
            <path
                d="M193.5,19.8h-3.7v4.1h-1.7V14h6.1v1.6h-4.4v2.7h3.7V19.8z"
                class="epc_arrow-label"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabel === 'E' }"
            @click="$emit('select-epc', 'E')"
        >
            <path
                d="M211.2,0h42.4l8,18.1l-8,18.1h-42.4l8-18.1L211.2,0z"
                fill="#fdd400"
                class="epc_arrow-bg"
            />
            <path
                d="M240.6,23.9h-6.2V14h6.2v1.6h-4.4v2.5h4v1.5h-4v2.6h4.4V23.9z"
                class="epc_arrow-label"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabel === 'D' }"
            @click="$emit('select-epc', 'D')"
        >
            <path
                d="M257.2,0h42.4l8,18.1l-8,18.1h-42.4l8-18.1L257.2,0z"
                fill="#fdf300"
                class="epc_arrow-bg"
            />
            <path
                d="M283.2,23.9h-3.6V14h3.5c2.9,0,4.9,2,4.9,5C288.1,21.9,286.1,23.9,283.2,23.9z M283,15.6h-1.6v6.7h1.7
                c2,0,3.2-1.3,3.2-3.3C286.2,16.8,285,15.6,283,15.6z"
                class="epc_arrow-label"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabel === 'C' }"
            @click="$emit('select-epc', 'C')"
        >
            <path
                d="M303.2,0h42.4l8,18.1l-8,18.1h-42.4l8-18.1L303.2,0z"
                fill="#d8db1a"
                class="epc_arrow-bg"
            />
            <path
                d="M328.7,24c-2.9,0-4.8-2-4.8-5.1c0-3.1,1.9-5.1,4.8-5.1c2.3,0,4.1,1.4,4.4,3.5h-1.8c-0.3-1.2-1.3-1.8-2.6-1.8
                c-1.8,0-3,1.3-3,3.4c0,2.1,1.2,3.5,3,3.5c1.3,0,2.4-0.7,2.7-1.8h1.8C332.8,22.7,331,24,328.7,24z"
                class="epc_arrow-label"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabel === 'B' }"
            @click="$emit('select-epc', 'B')"
        >
            <path
                d="M349.2,0h42.4l8,18.1l-8,18.1h-42.4l8-18.1L349.2,0z"
                fill="#9ade27"
                class="epc_arrow-bg"
            />
            <path
                d="M370.3,23.9V14h3.9c2,0,3.1,1,3.1,2.7c0,1.1-0.5,1.9-1.4,2.3c1,0.3,1.6,1.1,1.6,2.3c0,1.7-1.2,2.7-3.2,2.7
                H370.3z M374.1,15.5H372v2.7h2.1c0.9,0,1.5-0.5,1.5-1.4C375.6,16,375.1,15.5,374.1,15.5z M374.2,19.7H372v2.7h2.2
                c1,0,1.5-0.5,1.5-1.4C375.8,20.1,375.2,19.7,374.2,19.7z"
                class="epc_arrow-label"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabel === 'A' }"
            @click="$emit('select-epc', 'A')"
        >
            <path
                d="M395.2,0h42.4l8,18.1l-8,18.1h-42.4l8-18.1L395.2,0z"
                fill="#00bd00"
                class="epc_arrow-bg"
            />
            <path
                d="M417.6,23.9h-1.8l3.6-9.9h1.8l3.6,9.9h-1.8l-0.8-2.3h-3.7L417.6,23.9z M420,16.9l-1.1,3.2h2.7l-1.1-3.2
                c-0.1-0.3-0.2-0.6-0.2-0.8C420.2,16.4,420.1,16.7,420,16.9z"
                class="epc_arrow-label"
            />
        </g>
    </svg>
</template>
<script>
export default {
    name: 'EpcArrayBru',
    props: {
        interactive: {
            type: Boolean,
            default: false,
        },
        currentLabel: {
            type: String,
            default: null,
        },
    },
}
</script>
<style lang="scss" scoped>
@import './epcArrayStyle.scss';
</style>
