<template>
    <div class="app_card" @click="goToApp">
        <img :src="appImg" />
        <div class="app_card-text">
            <h2>{{ $t(`monolith.apps.${appId}.name`) }}</h2>
            <p v-if="$te(`monolith.apps.${appId}.description`)">
                {{ $t(`monolith.apps.${appId}.description`) }}
            </p>
        </div>
    </div>
</template>
<script>
export default {
    name: 'AppCard',
    props: {
        appId: {
            type: String,
            required: true,
        },
        appUrl: {
            type: String,
            default: null,
        },
    },
    computed: {
        appImg() {
            // necessary for dynamic asset import because Vue adds a hash to asset names
            let imgImport = require.context('@/assets/img/apps', false, /\.jpg$/)
            return imgImport(`./${this.appId}.jpg`)
        },
    },
    methods: {
        goToApp() {
            this.$router.push({
                name: this.appUrl || this.appId,
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.app_card {
    background: white;
    border-radius: 5px;
    border: 1px solid;
    padding: 0;
    overflow: hidden;
    width: 30%;
    transition: all 0.2s ease-out;
    border-color: #d6e2e9;
    box-shadow: 0 2px 14px 0 rgba(0, 47, 80, 0);

    &:hover {
        cursor: pointer;
        border-color: #009cff;
        box-shadow: 0 2px 14px 0 rgba(0, 47, 80, 0.1);
    }

    &-text {
        padding: 24px;

        h2 {
            color: #001837;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        p {
            margin-top: 8px;
            color: #798da6;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
    }
}
</style>
