<template>
    <div id="valuer_tool">
        <sidebar v-if="!isRouteFullWidth" />
        <div id="valuer_tool-content" :class="{ 'full-width': isRouteFullWidth }">
            <router-view />
        </div>
    </div>
</template>
<script>
import Sidebar from './elements/Sidebar'

export default {
    name: 'ValuerTool',
    components: {
        Sidebar,
    },
    computed: {
        isRouteFullWidth() {
            return this.$route.name === 'valuation.valuer-app.ref_map'
        },
    },
}
</script>
<style lang="scss">
#valuer_tool {
    display: grid;
    grid-template: auto / 300px auto;
    height: calc(100vh - 52px);

    & > *:not(#sidebar) {
        min-height: 100%;
    }

    #valuer_tool-content {
        overflow: scroll;

        &.full-width {
            grid-column: 1 / -1;
            align-self: stretch;
        }
    }

    .valuer_tool-title {
        color: #001837;
        font-size: 24px;
        font-weight: 700;
        grid-column: 1 / -1;

        &:not(:first-child) {
            margin-top: 24px;
        }
    }

    .valuer_tool-default-layout {
        height: 100%;
        justify-content: flex-start;

        &-grid {
            display: grid;
            grid-template: auto / 1fr 1fr;
            padding: 64px;
            gap: 24px;
            background: #f9fafc;
            width: 100%;
            height: auto;
            flex-grow: 1;
            align-items: stretch;
        }

        &-map_container {
            height: 246px;
        }
    }

    .single_line_card {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 16px;

        h5 {
            color: #798da6;
            font-size: 12px;
            font-weight: 500;
        }

        .is-expanded {
            flex-grow: 2;
        }
    }
}
</style>
