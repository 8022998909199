var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ers-content section results-page is-fluid pt-0 pb-0 mb-0 mt-0",class:[_vm.$vssWidth < 768 && 'pt-0']},[_c('div',{staticClass:"sticky-column"},[_c('div',{staticClass:"column is-12 mt-0"},[(_vm.simulation.status == 'started')?_c('h1',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('processing_page.title_started'))+" ")]):_vm._e(),(_vm.simulation.status == 'finished')?_c('h1',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('processing_page.title_finished'))+" ")]):_vm._e(),(['failed', 'expired'].includes(_vm.simulation.status))?_c('h1',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('processing_page.title_failed'))+" ")]):_vm._e(),(_vm.simulation.status == 'pending')?_c('h1',{staticClass:"pending-title title",domProps:{"innerHTML":_vm._s(
                    _vm.$t(("processing_page.title_pending_" + _vm.pending_range), {
                        waiting: _vm.pending_time,
                    })
                )}}):_vm._e()]),_c('div',{staticClass:"column is-12"},[(_vm.simulation.status === 'started')?_c('b-progress',{staticClass:"large",attrs:{"type":"is-primary","value":_vm.progress_value}}):_vm._e(),(_vm.simulation.status === 'finished')?_c('b-button',{attrs:{"tag":"router-link","to":{
                    name: 'ers.result',
                    params: { lang: _vm.$route.params.lang },
                    query: Object.assign({}, this.$route.query),
                },"type":"is-primary","value":_vm.progress_value}},[_c('span',[_vm._v(_vm._s(_vm.$t('processing_page.check_results')))])]):_vm._e(),(['failed', 'expired'].includes(_vm.simulation.status))?_c('b-button',{attrs:{"tag":"router-link","to":{ name: 'ers' },"type":"is-primary","value":_vm.progress_value}},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.new_request.title')))])]):_vm._e()],1),(['started', 'pending'].includes(_vm.simulation.status))?_c('img',{staticClass:"house-img",attrs:{"src":require("@/assets/img/ers/house_scanning.gif")}}):(_vm.simulation.status == 'finished')?_c('img',{staticClass:"house-img",attrs:{"src":require("@/assets/img/ers/house_basic.gif")}}):(['failed', 'expired'].includes(_vm.simulation.status))?_c('img',{staticClass:"house-img",attrs:{"src":require("@/assets/img/ers/house_reconstruction.gif")}}):_c('img',{staticClass:"house-img",attrs:{"src":require("@/assets/img/ers/house_basic.gif")}})])])}
var staticRenderFns = []

export { render, staticRenderFns }