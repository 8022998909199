<template>
    <div class="sidemenu" :class="{ wide: isWide }">
        <router-link
            v-if="$config.ENABLED_APPS.length > 1"
            class="sidemenu-item"
            :class="{ active: $route.name === 'appDashboard' }"
            :to="{ name: 'appDashboard' }"
        >
            <svg-icon icon="apps" scale="1" />
            <h3>{{ $t('section.apps') }}</h3>
        </router-link>
        <router-link
            class="sidemenu-item"
            :class="{ active: $route.name === 'dashboard' }"
            :to="{ name: 'dashboard' }"
        >
            <svg-icon icon="monitor" scale="1" />
            <h3>{{ $t('section.dashboard') }}</h3>
        </router-link>
        <router-link
            class="sidemenu-item"
            :class="{ active: $route.name === 'support' }"
            :to="{
                name: 'support',
                params: {
                    lang: $route.params.lang,
                },
                query: from_query_args,
            }"
        >
            <svg-icon icon="help" scale="1" />
            <h3>{{ $t('section.helpcenter') }}</h3>
        </router-link>
        <router-link
            v-if="hasRole('admin')"
            class="sidemenu-item"
            :class="{ active: $route.name === 'admin' }"
            :to="{
                name: 'admin',
                params: {
                    lang: $route.params.lang,
                },
            }"
        >
            <svg-icon icon="user-admin" scale="1" />
            <h3>{{ $t('section.user-admin') }}</h3>
        </router-link>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    name: 'Sidemenu',
    props: {
        isWide: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters('auth', ['hasRole']),
        from_query_args() {
            let q = {}
            if (this.$route.name === null) {
                q.from_module = '-'
            } else if (this.$route.name.startsWith('ers.')) {
                q.from_module = 'ers'
                q.valuation_request_ref = this.$route.query.ref
            } else if (this.$route.name.startsWith('valuation.')) {
                q.from_module = 'dvm'
                q.valuation_request_ref = this.$route.query.valuation_request_ref
            }
            return q
        },
    },
}
</script>
<style lang="scss" scoped>
@media print {
    .sidemenu {
        display: none;
    }
}

@media screen {
    .sidemenu {
        height: clamp(calc(100vh - 52px), 100%, 100vh);
        position: sticky;
        top: 0;
        min-width: 64px;
        z-index: 996;
        background: white;
        border-right: 1px solid #d6e2e9;
        width: fit-content;
        transition: all 0.3s ease-out, width 0.2s ease-out 0.1s;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 24px 8px;

        .sidemenu-item {
            padding: 8px;
            padding-right: 8px;
            border-radius: 4px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            width: 100%;
            overflow: hidden;
            cursor: pointer;
            gap: 0px;
            transition: all 0.3s ease-out;

            svg {
                fill: #99afcc;
                transition: fill 0.2s ease-out;
            }

            h3 {
                display: block;
                width: 0px;
                opacity: 0;
                color: rgba(0, 56, 128, 0.4);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                white-space: nowrap;
                line-height: normal;
                transition: all 0.2s ease-out, width 0.2s ease-out,
                    opacity 0.2s ease-out 0.1s;
            }

            &.active {
                background: rgba(0, 156, 255, 0.2);
            }

            &.active,
            &:hover {
                svg {
                    fill: #009cff !important;
                }
                h3 {
                    color: #009cff;
                    font-weight: 700;
                }
            }
        }

        &.wide,
        &:hover {
            padding: 24px 24px;
            min-width: 300px;
            align-items: stretch;
            z-index: 9999;

            .sidemenu-item {
                padding-right: 16px;
                gap: 20px;
                h3 {
                    width: fit-content;
                    opacity: 1;
                }
            }
        }
    }
}
</style>
