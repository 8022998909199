<template>
    <div class="ers-page-content">
        <div class="ers-page-title">
            <h1>
                {{ $t('energy.construction_year.question') }}
                <Tooltip :label="$t('energy.construction_year.tooltip')" />
            </h1>
        </div>
        <div class="column is-12">
            <year-selector
                v-model="f_construction_year"
                :label="$t('energy.construction_year.label_input')"
            />
        </div>

        <div class="column is-12 mt-2">
            <div class="is-flex is-justify-content-space-between">
                <b-button
                    size="is-medium"
                    type="is-dark"
                    icon-left="arrow-left"
                    @click="changePage(false)"
                >
                    {{ $t('common.previous') }}
                </b-button>
                <submit-button
                    @on-click="
                        $emit('open-summary-modal', {
                            unsubmit: false,
                        })
                        changePage(true)
                    "
                />
                <b-button
                    v-if="f_construction_year"
                    :class="{ 'ers-next-bttn-dark': hasRequestBeenUnsubmitted }"
                    size="is-medium"
                    type="is-primary"
                    icon-right="arrow-right"
                    @click="changePage(true)"
                >
                    <span>{{ $t('common.next') }}</span>
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import {
    required,
    requiredIf,
    decimal,
    minValue,
    maxValue,
} from 'vuelidate/lib/validators'
import utils from '@/shared/plugins/utils'
import YearSelector from '../../elements/YearSelector.vue'
import Tooltip from '../../elements/Tooltip.vue'
import SubmitButton from '../../elements/SubmitButton.vue'

export default {
    name: 'ConstructionYear',
    components: {
        SubmitButton,
        Tooltip,
        YearSelector,
    },
    data: () => ({
        f_construction_year: 1950,
    }),
    computed: {
        ...mapGetters('ersStore', [
            'getRequestRef',
            'getFeatures',
            'getOtherData',
            'hasRequestBeenUnsubmitted',
        ]),
    },
    mounted() {
        const { f_construction_year } = this.getFeatures
        if (f_construction_year !== null) {
            this.f_construction_year = f_construction_year
        }
    },
    methods: {
        ...mapMutations('ersStore', ['SET_FEATURES', 'UPDATE_RENOVATION_YEAR']),
        ...mapActions('ersStore', [
            'submitFeatures',
            'fetch_features',
            'set_and_submit_feature',
        ]),
        changePage(isNextPage) {
            this.UPDATE_RENOVATION_YEAR(this.f_construction_year)

            const feature = {
                f_construction_year: this.f_construction_year,
            }
            let nextPage

            if (isNextPage) {
                nextPage = 'ers.roof-insulation'
            } else {
                nextPage = `ers.epc${
                    this.getFeatures.has_epc_certificate ? '-score' : ''
                }`
            }

            this.set_and_submit_feature({ feature, nextPage })
            this.$router.push({
                name: nextPage,
                params: {
                    lang: this.$route.params.lang,
                },
                query: this.$route.query,
            })
        },
    },
}
</script>

<style lang="scss">
@import '@/shared/assets/style/buefy.scss';

.is-small-tooltip {
    font-size: 14px;
    .info {
        height: 20px;
        width: 20px;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.is-tootip-btn {
    height: 24px;
    width: 24px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.is-input-number {
    .icons {
        width: 40px;
        font-size: 20px;
        div {
            height: 25px;
            &:first-child {
                border-top-right-radius: 4px;
                border-top: 1px solid;
                border-bottom: 1px solid;
                border-right: 1px solid;
                border-color: #e5eaed;
            }
            &:last-child {
                border-bottom: 1px solid;
                border-right: 1px solid;
                border-color: #e5eaed;
                border-bottom-right-radius: 4px;
            }
        }
    }
}
</style>
