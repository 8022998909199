<template>
    <grid-card no-padding>
        <template slot="header">
            <img :src="titleIcon" />
            <h5 class="grid_card-title">
                {{ $t(`dvm_features.other_${type}`) }}
            </h5>
        </template>
        <template slot="default">
            <div
                v-if="elements && elements.length"
                class="content_block impacting_elements"
            >
                <template v-for="(element, i) in elements">
                    <b-input
                        v-if="editing === i"
                        :key="`${feature}_element_${i}_text`"
                        v-model="elements[i]"
                        expanded
                    />
                    <h6 v-else :key="`${feature}_element_${i}_text`">{{ element }}</h6>
                    <icon-button
                        :key="`${feature}_element_${i}_edit`"
                        :icon="editing === i ? 'save' : 'edit'"
                        @click="editElement(i)"
                    />
                    <icon-button
                        :key="`${feature}_element_${i}_delete`"
                        icon="delete"
                        @click="removeElement(i)"
                    />
                </template>
            </div>
            <div class="content_block add_element">
                <b-input ref="newElementInput" v-model="newElement" expanded />
                <b-button type="is-primary" :disabled="isAddDisabled" @click="addElement">
                    +
                </b-button>
            </div>
        </template>
    </grid-card>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import GridCard from '..'
import IconButton from '@/components/elements/IconButton.vue'

export default {
    name: 'OtherImpactingCard',
    components: {
        GridCard,
        IconButton,
    },
    props: {
        type: {
            validator(value) {
                return ['positive', 'negative'].includes(value)
            },
            required: true,
        },
        feature: {
            type: String,
            required: true,
        },
        configLocation: {
            type: String,
            default: 'dvm_features',
        },
    },
    data: () => ({
        newElement: '',
        elements: [],
        editing: -1,
    }),
    computed: {
        ...mapGetters('valuationStore/Address', ['getFullBuildingData']),
        isAddDisabled() {
            return !(
                (typeof this.newElement === 'string' ||
                    this.newElement instanceof String) &&
                this.newElement.length
            )
        },
        titleIcon() {
            // necessary for dynamic asset import because Vue adds a hash to asset names
            let imgImport = require.context('@/assets/img/valuer-tool', false, /\.svg$/)
            return imgImport(`./${this.type}-impact.svg`)
        },
    },
    watch: {
        elements(val) {
            let key = this.configLocation
            let values = {}
            values[this.feature] = val
            this.SET_UNIVERSAL({ key, values })
        },
    },
    mounted() {
        this.elements = this.getFullBuildingData[this.configLocation].hasOwnProperty(
            this.feature
        )
            ? this.getFullBuildingData[this.configLocation][this.feature]
            : []
    },
    methods: {
        ...mapMutations('valuationStore/Address', ['SET_UNIVERSAL']),
        addElement() {
            this.elements.push(this.newElement)
            this.newElement = ''
            this.$refs.newElementInput.focus()
        },
        removeElement(i) {
            this.elements.splice(i, 1)
        },
        editElement(i) {
            if (i === this.editing) {
                this.editing = -1
            } else {
                this.editing = i
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.content_block {
    border-top: 1px solid #d6e2e9;
    padding: 24px;
}
.impacting_elements {
    display: grid;
    grid-template-columns: auto 32px 32px;
    gap: 8px;
}

.add_element {
    display: flex;
    display: flex;
    align-items: space-between;
    gap: 16px;
    width: 100%;

    & > *:first-child {
        flex-grow: 1;
    }
}
</style>
