<template>
    <div id="ers">
        <transition appear mode="out-in" name="fade">
            <div class="ers-container">
                <div v-if="showSideNav && !isMobile" class="side-nav">
                    <side-steps />
                </div>
                <top-step v-if="showSideNav && isMobile" />
                <router-view />
            </div>
        </transition>
    </div>
</template>

<script>
import SideSteps from './components/elements/SideSteps-v2.vue'
import TopStep from './components/elements/TopStep.vue'

function popupOnClose(e) {
    e.preventDefault()
    e.returnValue = ''
}

export default {
    name: 'ERSLayout',
    components: {
        SideSteps,
        TopStep,
    },
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'Rock.estate Energy Renovation Simulator',
        // all titles will be injected into this template
        // titleTemplate: '%s | Rockestate estimator',
    },
    data() {
        return {
            state: {
                countdown: null,
                countdownInterval: null,
            },
            transitionName: 'fade',
        }
    },
    computed: {
        showSideNav() {
            return this.$route.path.includes('/questionnaire')
        },
        isMobile() {
            return this.$vssWidth < 768
        },
    },
    watch: {
        $route(to, from) {
            if (to.name === 'ers.processing') {
                window.addEventListener('beforeunload', popupOnClose)
            } else {
                window.removeEventListener('beforeunload', popupOnClose)
            }
            if (
                this.isMobile ||
                [to.name, from.name].includes('map') ||
                from.name === 'welcome'
            ) {
                this.transitionName = 'fade'
            } else {
                this.transitionName = 'slide-fade'
            }
        },
    },
    mounted() {
        if (this.isMobile) {
            this.transitionName = 'slide-fade-x'
        }
    },
    methods: {},
}
</script>

<style src="@/assets/style/index.scss" lang="scss"></style>

<style lang="scss">
@import '@/shared/assets/style/buefy.scss';

#ers {
    -webkit-print-color-adjust: exact;
    min-height: calc(100vh - 52px);
    background: #f4f6f9;
    overflow-x: hidden;
}

.ers-content {
    padding: 0 5%;
    @media screen and (min-width: 768px) {
        padding: 3rem 6rem;
    }
    max-width: 100vw;
    flex-grow: 1;
}

.ers-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 100vw !important;
    min-height: 100%;

    @media screen and (max-width: 768px) {
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        flex-direction: column;
    }
}

.side-nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 54px);
    width: 20%;
    max-width: 300px;
    min-width: 260px;
    position: sticky;
    top: 0;
    background: #fff;
    border-right: 1px solid #d6e2e9;
    padding-top: 20px;
    padding-bottom: 1rem;
}

.is-rounded {
    border-radius: 50%;
}

.skyline {
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 0;
    height: 100vh;
    background: url('../../assets/img/skyline_bg.svg');
    background-size: cover;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.3s ease-in-out;
}
.slide-fade-leave-to {
    transform: translateY(-100vh);
    opacity: 0;
}
.slide-fade-enter {
    transform: translateY(100vh);
    opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 3s ease-in-out;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.input-instructions {
    color: rgba(0, 24, 55, 0.4);
    margin-bottom: 20px;
    font-size: 0.8rem;
    white-space: pre-line;

    @media screen and (min-width: 768px) {
        max-width: 60%;
    }

    &.larger-gap {
        margin-bottom: 30px;
    }
}

.mobile-ing-logo {
    width: 120px;
    margin: 1rem 0;
    background: #f4f6f9;
}

.input-row {
    margin: 0px 24px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;

    & > * {
        grid-column: 1 / 3;
    }

    .input-instructions {
        grid-column: 1 / 4;
    }

    .input-field {
        position: relative;

        &__input {
            width: 100%;

            input[type='number'] {
                -moz-appearance: textfield;
            }

            input {
                border-radius: 4px !important;

                &::-webkit-inner-spin-button,
                &::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                }
            }
        }
    }

    .is-unit {
        height: auto;
        position: absolute;
        bottom: 0.6rem;
        right: 15px;
        z-index: 999;
    }
}

.tooltip-qmark {
    display: inline-block;
    background: rgba(0, 156, 255, 0.2);
    height: 28px;
    width: 28px;
    border-radius: 50%;
    cursor: help;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    bottom: 2px;

    color: $primary;
    font-size: 18px;
    line-height: 18px;
}

@media print {
    #ers {
        width: 210mm;
        overflow: visible;
    }

    .ers-container {
        max-width: auto !important;
        background: transparent;
        padding: 0;
    }

    .title__tooltip,
    .text__tooltip {
        display: none !important;
    }
}
</style>
