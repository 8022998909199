<i18n>
{
    "en-BE": {
        "actions": "Actions",
        "address": "Address",
        "automatic_valuation": "Automatic valuation",
        "cancel": "Cancel",
        "customer_ref": "Customer ref",
        "edit": "Edit",
        "web_report": "Web report",
        "pdf_report": "PDF report",
        "downloading": "Downloading...",
        "features": {
            "f_epc": "EPC",
            "f_touching_sides": "Number of facades",
            "f_construction_year": "Construction year",
            "f_living_area": "Living area",
            "f_x": "Longitude",
            "f_y": "Latitude"
        },
        "info": "Info",
        "info_title": "More information",
        "last_modified": "Last modified",
        "created_at": "Created",
        "missing_features": "The following features are missing, preventing an automatic valuation:",
        "ok": "OK",
        "per_page": "per page",
        "request_ref": "Request ref",
        "search": "Search",
        "status_col": "Status",
        "status": {
            "awaiting-onsite": "Awaiting onsite",
            "under-dvm-valuation": "Submitted",
            "draft": "Draft",
            "submitted": "Submitted",
            "exotic": "Awaiting onsite",
            "needs-review": "More information needed",
            "cancelled": "Cancelled",
            "valued": "Valued"
        },
        "badges": {
            "resubmitted": "Resubmitted",
            "needs_on_site": "OVM Needed"
        },
        "tooltip_text": "Follow here the status of your valuation requests",
        "review_tooltip": "This request has been analyzed by a valuation specialist and some answers need to be reviewed",
        "view_request": "View request",
        "lang": "Language",
        "valuer": "Valuer",
        "unassigned": "Unassigned",
        "create_report": "Generate report",
        "refresh_report": "Generate new report",
        "download_report": "Save report",
        "report_loading": "Report loading",
        "owner": "Owner",
        "take_ownership": "Take ownership",
        "sub_modal": {
            "content": "Do you confirm you want to take ownership of this request?\n The current owner will be notified of the ownership transfer.",
            "actions": {
                "cancel": "Cancel",
                "confirm": "Confirm"
            }
        },
        "valuer_change_modal": {
            "content": "This request is already assigned. Do you confirm you want to change the assigned valuer? This will prevent the previously assigned valuer from working on the request.",
            "actions": {
                "cancel": "Cancel",
                "confirm": "Confirm"
            }
        },
        "tabs": {
            "my_requests": "My Requests",
            "all_requests": "All Requests",
            "search_request": "Search Request"
        },
        "need_someone_else": "Need to work on someone else's request?",
        "ing": {
            "customer_ref": "LOP ID"
        }
    },
    "fr-BE": {
        "actions": "Actions",
        "address": "Adresse",
        "automatic_valuation": "Valorisation automatique",
        "cancel": "Annuler",
        "customer_ref": "Référence interne",
        "edit": "Modifier",
        "web_report": "Web report",
        "pdf_report": "PDF report",
        "downloading": "Downloading...",
        "features": {
            "f_epc": "PEB",
            "f_touching_sides": "Nombre de façades",
            "f_construction_year": "Année de construction",
            "f_living_area": "Surface habitable",
            "f_x": "Longitude",
            "f_y": "Latitude"
        },
        "info": "Info",
        "info_title": "Plus d'information",
        "last_modified": "Dernière modification",
        "created_at": "Créé",
        "missing_features": "Les données suivantes sont manquantes, ce qui empêche une valorisation automatique:",
        "ok": "OK",
        "per_page": "par page",
        "request_ref": "Référence de demande",
        "search": "Rechercher",
        "status_col": "Statut",
        "status": {
            "awaiting-onsite": "Expertise sur place nécessaire",
            "under-dvm-valuation": "Envoyé",
            "draft": "À compléter",
            "submitted": "Envoyé",
            "exotic": "Expertise sur place nécessaire",
            "needs-review": "Plus d'information nécessaire",
            "cancelled": "Annulé",
            "valued": "Evalué"
        },
        "badges": {
            "resubmitted": "Soumis de nouveau",
            "needs_on_site": "Expertise sur site recommandée"
        },
        "tooltip_text": "Suivez ici l'évolution de vos demandes",
        "review_tooltip": "Cette demande a été analysée par un expert immobilier et certaines réponses doivent être révisées",
        "view_request": "Voir la demande",
        "lang": "Language",
        "valuer": "Expert immobilier",
        "unassigned": "Non assignée",
        "create_report": "Générer un rapport",
        "refresh_report": "Générer un rapport de nouveau",
        "download_report": "Télécharger un rapport",
        "report_loading": "Chargement du rapport",
        "owner": "Demandeur",
        "take_ownership": "Prendre en charge",
        "sub_modal": {
            "content": "Confirmez-vous prendre en charge cette demande ? Le demandeur actuel sera informé du changement d'utilisateur.",
            "actions": {
                "cancel": "Annuler",
                "confirm": "Confirmer"
            }
        },
        "valuer_change_modal": {
            "content": "Cette demande est déjà attribuée. Confirmez-vous que vous voulez changer l'expert assigné? Cela empêchera l'expert précédemment assigné de travailler sur la demande.",
            "actions": {
                "cancel": "Annuler",
                "confirm": "Confirmer"
            }
        },
        "tabs": {
            "my_requests": "Mes demandes",
            "all_requests": "Toutes les demandes",
            "search_request": "Rechercher une demande"
        },
        "need_someone_else": "Besoin de travailler sur une demande d'un collègue?",
        "ing": {
            "customer_ref": "LOP ID"
        }
    },
    "nl-BE": {
        "actions": "Acties",
        "address": "Adres",
        "automatic_valuation": "Automatische waardering",
        "cancel": "Annuleren",
        "customer_ref": "Interne referentie",
        "edit": "Bewerken",
        "web_report": "Web report",
        "pdf_report": "PDF report",
        "downloading": "Downloading...",
        "features": {
            "f_epc": "EPC",
            "f_touching_sides": "Aantal gevels",
            "f_construction_year": "Bouwjaar",
            "f_living_area": "Bewoonbare oppervlakte",
            "f_x": "Longitude",
            "f_y": "Latitude"
        },
        "info": "Info",
        "info_title": "Meer informatie",
        "last_modified": "Laatst gewijzigd",
        "created_at": "Gecreëerd",
        "missing_features": "De volgende functies ontbreken, waardoor automatische waardering niet mogelijk is:",
        "ok": "OK",
        "per_page": "per pagina",
        "request_ref": "Aanvraag referentie",
        "search": "Zoeken",
        "status_col": "Status",
        "status": {
            "awaiting-onsite": "On-site expertise vereist",
            "under-dvm-valuation": "Ingediend",
            "draft": "Ontwerp",
            "submitted": "Ingediend",
            "exotic": "On-site expertise vereist",
            "needs-review": "Meer informatie nodig",
            "cancelled": "Geannuleerd",
            "valued": "Gewaardeerd"
        },
        "badges": {
            "resubmitted": "Opnieuw ingediend",
            "needs_on_site": "Expertise ter plaatse aanbevolen"
        },
        "tooltip_text": "Volg hier de status van uw aanvraagen",
        "review_tooltip": "Deze aanvraag is door een vastgoeddeskundige geanalyseerd en sommige antwoorden moeten worden herzien",
        "view_request": "Aanvraag bekijken",
        "lang": "Taal",
        "valuer": "Vastgoeddeskundige",
        "unassigned": "Niet toegewezen",
        "create_report": "Genereer een verslag",
        "refresh_report": "Genereer een nieuw verslag",
        "download_report": "Verslag opslaan",
        "report_loading": "Verslag is aan het laden",
        "owner": "Aanvrager",
        "take_ownership": "Aanvraag overnemen",
        "sub_modal": {
            "content": "Graag nogmaals bevestiging om deze aanvraag over te nemen. De huidige aanvrager zal hiervan een bericht krijgen.",
            "actions": {
                "cancel": "Annuleren",
                "confirm": "Bevestigen"
            }
        },
        "valuer_change_modal": {
            "content": "Dit verzoek is al toegewezen. Bevestigt u dat u de toegewezen expert wilt veranderen? Hierdoor kan de eerder toegewezen expert niet meer aan de aanvraag werken.",
            "actions": {
                "cancel": "Annuleren",
                "confirm": "Bevestigen"
            }
        },
        "tabs": {
            "my_requests": "Mijn aanvragen",
            "all_requests": "Alle aanvragen",
            "search_request": "Aanvraag zoeken"
        },
        "need_someone_else": "Moet werken aan iemand anders zijn aanvraag?",
        "ing": {
            "customer_ref": "LOP ID"
        }
    }
}
</i18n>

<template>
    <div class="container section is-fluid overflow-hidden">
        <h1 class="title is-capitalized is-size-4">Admin dashboard</h1>
        <div class="columns is-multiline mt-4">
            <!-- input section -->
            <div class="is-flex is-justify-content-flex-end is-w100 pr-2">
                <!-- per-page select -->
                <b-field position="is-right">
                    <b-select v-model="perPage" size="is-small">
                        <option v-for="item in perPageList" :key="item" :value="item">
                            {{ item }} {{ $t('per_page') }}
                        </option>
                    </b-select>
                </b-field>
            </div>
            <!-- input section -->
            <div class="column is-12">
                <b-table
                    :data="data"
                    hoverable
                    mobile-cards
                    paginated
                    scrollable
                    pagination-size="is-small"
                    default-sort="modified_at"
                    default-sort-direction="desc"
                    :loading="isLoading"
                    :per-page="perPage"
                    :current-page.sync="currentPage"
                >
                    <template v-for="column in columns">
                        <b-table-column
                            :key="column.valuation_request_ref"
                            v-bind="column"
                        >
                            <template
                                v-if="column.searchable && !column.date"
                                #searchable="props"
                            >
                                <b-input
                                    v-model="props.filters[props.column.field]"
                                    :placeholder="$t('search')"
                                    icon="magnify"
                                    size="is-small"
                                />
                            </template>
                            <template
                                v-else-if="column.searchable && column.date"
                                #searchable="props"
                            >
                                <span class="is-flex">
                                    <b-datepicker
                                        v-model="date_filters[props.column.field]"
                                        :placeholder="$t('search')"
                                        icon="magnify"
                                        size="is-small"
                                        class="is-flex-grow-1"
                                    />
                                    <button
                                        v-if="date_filters[props.column.field]"
                                        class="clear-button"
                                        @click="date_filters[props.column.field] = null"
                                    >
                                        <b-icon icon="close-circle" size="is-small" />
                                    </button>
                                </span>
                            </template>
                            <template v-if="'roles' === column.field" #default="props">
                                <admin-roles
                                    v-if="props.row.editable || props.row.roles.length"
                                    v-model="props.row.roles"
                                    :editable="props.row.editable"
                                    :unremovable="[props.row.is_self ? '-:admin' : null]"
                                />
                                <!-- make it impossible to soft lock yourself out of the admin panel -->
                            </template>
                            <template
                                v-else-if="'has_password' === column.field"
                                #default="props"
                            >
                                <div v-if="props.row.editable">
                                    <b-field v-if="props.row.has_password" grouped>
                                        <span
                                            v-if="props.row.password"
                                            size="is-small"
                                            class="mr-1"
                                        >
                                            {{ props.row.password }}
                                        </span>
                                        <b-button
                                            size="is-small"
                                            icon-left="key-remove"
                                            @click="
                                                $set(props.row, 'has_password', false)
                                                $set(props.row, 'password', null)
                                            "
                                        ></b-button>
                                        <b-button
                                            icon-left="sync"
                                            size="is-small"
                                            @click="
                                                $set(
                                                    props.row,
                                                    'password',
                                                    random_string(16)
                                                )
                                            "
                                        ></b-button>
                                    </b-field>
                                    <b-button
                                        v-else
                                        size="is-small"
                                        icon-left="key-plus"
                                        @click="
                                            $set(props.row, 'has_password', true)
                                            $set(props.row, 'password', random_string(16))
                                        "
                                    ></b-button>
                                </div>
                                <div v-else-if="!props.row.new_user">
                                    {{
                                        props.row[column.field]
                                            ? $t('value.yes')
                                            : $t('value.no')
                                    }}
                                </div>
                            </template>
                            <template
                                v-else-if="'editing' === column.field"
                                #default="props"
                            >
                                <b-field
                                    v-if="!props.row.editable && props.row.new_user"
                                    grouped
                                >
                                    <b-button
                                        icon-left="plus"
                                        type="is-primary is-small"
                                        @click="toggle_editing(props, true)"
                                    >
                                        <span>
                                            {{ $t('new') }}
                                        </span>
                                    </b-button>
                                </b-field>
                                <b-field v-else-if="props.row.editable" grouped>
                                    <b-button
                                        v-if="!props.row.deleting"
                                        icon-left="content-save"
                                        type="is-success is-small"
                                        @click="toggle_editing(props, true)"
                                    />
                                    <b-button
                                        icon-left="close"
                                        type="is-small"
                                        @click="toggle_editing(props, false)"
                                    />
                                </b-field>
                                <b-field v-else-if="props.row.deleting" grouped>
                                    <b-button
                                        icon-left="delete-forever"
                                        type="is-danger"
                                        size="is-small"
                                        @click="delete_row(props)"
                                    >
                                        <span v-if="props.row.deleting">
                                            confirm deletion
                                        </span>
                                    </b-button>
                                    <b-button
                                        v-if="props.row.editable || props.row.deleting"
                                        icon-left="close"
                                        size="is-small"
                                        @click="toggle_editing(props, false)"
                                    />
                                </b-field>
                                <b-field v-else grouped>
                                    <b-button
                                        icon-left="pencil"
                                        type="is-primary"
                                        size="is-small"
                                        outlined
                                        @click="toggle_editing(props, false)"
                                    ></b-button>
                                    <b-button
                                        icon-left="delete"
                                        type="is-danger"
                                        size="is-small"
                                        outlined
                                        @click="delete_row(props)"
                                    ></b-button>
                                </b-field>
                            </template>
                            <template
                                v-else-if="'plan' === column.field"
                                #default="props"
                            >
                                <b-select
                                    v-if="props.row.editable"
                                    v-model="props.row.plan"
                                    placeholder="Select a plan"
                                    required
                                >
                                    <option value="limited">Limited</option>
                                    <option value="trial">Trial</option>
                                    <option value="pro">Pro</option>
                                    <option value="unlimited">Unlimited</option>
                                </b-select>

                                <div v-else>{{ props.row[column.field] }}</div>
                            </template>
                            <template
                                v-else-if="'trial_end_at' === column.field"
                                #default="props"
                            >
                                <b-datetimepicker
                                    v-if="props.row.editable"
                                    v-model="props.row.trial_end_at"
                                    rounded
                                    icon="calendar-today"
                                    :icon-right="
                                        props.row.trial_end_at ? 'close-circle' : ''
                                    "
                                    icon-right-clickable
                                    :locale="$route.params.lang"
                                    :first-day-of-week="1"
                                    @icon-right-click="
                                        $set(props.row, 'trial_end_at', null)
                                    "
                                />
                                <div v-else>
                                    {{
                                        props.row[column.field]
                                            ? date_format(props.row[column.field])
                                            : null
                                    }}
                                </div>
                            </template>
                            <template v-else-if="column.date" #default="props">
                                <span>
                                    {{
                                        props.row[column.field]
                                            ? date_format(props.row[column.field])
                                            : null
                                    }}
                                </span>
                            </template>
                            <template
                                v-else-if="'username' === column.field"
                                #default="props"
                            >
                                <b-input
                                    v-if="props.row.editable && props.row.new_user"
                                    :value="props.row[column.field]"
                                    @input="$set(props.row, column.field, $event)"
                                />
                                <span v-else>
                                    {{ props.row[column.field] }}
                                </span>
                            </template>
                            <template v-else #default="props">
                                <b-input
                                    v-if="props.row.editable"
                                    :value="props.row[column.field]"
                                    @input="$set(props.row, column.field, $event)"
                                />
                                <span v-else>
                                    {{ props.row[column.field] }}
                                </span>
                            </template>
                        </b-table-column>
                    </template>
                </b-table>
                <!-- table section -->
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/shared/plugins/axios.js'
import utils from '@/shared/plugins/utils'

import AdminRoles from './AdminRoles.vue'
//import { localeMixin } from '@/components/mixins/locale.js'

const date_formatter = new Intl.DateTimeFormat('fr-BE', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    /*second: 'numeric',*/
    timeZone: 'Europe/Brussels',
})

export default {
    name: 'Admin',
    components: {
        AdminRoles,
    },
    // mixins: [localeMixin],
    data() {
        return {
            data: [],
            currentPage: 1,
            perPage: 25,
            perPageList: [5, 10, 25, 50],
            isLoading: true,
            date_filters: {
                trial_end_at: null,
            },
        }
    },
    computed: {
        // return filtered text
        columns() {
            return [
                {
                    field: 'editing',
                    label: this.$t('edit'),
                    sortable: false,
                    centered: true,
                    searchable: false,
                },
                {
                    field: 'username',
                    label: this.$t('username'),
                    width: '40',
                    sortable: true,
                    centered: true,
                    searchable: true,
                    editable: false,
                },
                {
                    field: 'email',
                    label: this.$t('email_address'),
                    centered: true,
                    searchable: true,
                    sortable: true,
                },
                {
                    field: 'first_name',
                    label: this.$t('first_name'),
                    centered: true,
                    searchable: true,
                    sortable: true,
                },
                {
                    field: 'last_name',
                    label: this.$t('last_name'),
                    centered: true,
                    searchable: true,
                    sortable: true,
                },
                {
                    field: 'has_password',
                    label: this.$t('password'),
                    centered: true,
                    searchable: false,
                    sortable: false,
                },
                /*{
                    field: 'organization',
                    label: this.$t('organization'),
                    centered: true,
                    searchable: true,
                    sortable: true,
                },
                {
                    field: 'plan',
                    label: this.$t('plan'),
                    centered: true,
                    searchable: true,
                    sortable: true,
                },
                {
                    field: 'trial_end_at',
                    label: this.$t('trial_end_at'),
                    centered: true,
                    sortable: true,
                    date: true,
                    searchable: true,
                },*/
                {
                    field: 'roles',
                    label: this.$t('roles'),
                    centered: true,
                    searchable: true,
                },
            ]
        },
    },
    mounted() {
        this.load_data()
    },
    metaInfo() {
        return {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Admin dashboard',
            // all titles will be injected into this template
            titleTemplate: '%s | Rock.estate App',
        }
    },
    methods: {
        // search with multiple query
        search(search) {
            localStorage.setItem('dashboard_search', search)
        },
        random_string(length) {
            const charset =
                'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
            let result = ''
            let values = new Uint32Array(length)
            window.crypto.getRandomValues(values)
            for (let i = 0; i < length; i++) {
                result += charset[values[i] % charset.length]
            }
            return result
        },
        date_format: date_formatter.format,
        load_data() {
            let url = utils.urlJoin(this.$config.VALUATION_API_URL, ['users'])
            this.isLoading = true
            this.data = []
            axios.get(url).then((result) => {
                const data = [{ username: null, roles: [] }, ...result.data]
                let index = 0
                data.forEach((el, __) => {
                    try {
                        this.$set(this.data, index, this.load_user(el, false))
                    } catch (error) {
                        console.error({ error, el })
                    }
                    index += 1
                })
                this.isLoading = false
            })
        },
        load_user(raw_user, editable) {
            return {
                ...raw_user,
                roles: utils.rolesToList(raw_user.roles),
                ...{
                    new_user: !raw_user.username,
                    editable: editable,
                    trial_end_at: raw_user.trial_end_at
                        ? new Date(raw_user.trial_end_at)
                        : null,
                    created_at: new Date(raw_user.created_at),
                },
            }
        },
        dump_user(user) {
            return {
                ...user,
                roles: utils.listToRoles(user.roles),
                trial_end_at: user.trial_end_at ? user.trial_end_at.toISOString() : null,
            }
        },
        toggle_editing(props, save) {
            if (props.row.deleting) {
                this.$set(props.row, 'deleting', false)
            } else if (props.row.editable) {
                if (save) {
                    const method = props.row.new_user ? 'POST' : 'PATCH'
                    axios({
                        url: `/user/${props.row.username}`,
                        data: this.dump_user(props.row),
                        method: method,
                    }).then((result) => {
                        let new_row = this.load_user(result.data, false)
                        Object.keys(new_row).forEach((key) =>
                            this.$set(props.row, key, new_row[key])
                        )
                        if (method == 'POST') {
                            this.data.unshift(
                                this.load_user({ username: null, roles: [] })
                            )
                        }
                    })
                } else {
                    Object.keys(props.row.previous_data).forEach((key) =>
                        this.$set(props.row, key, props.row.previous_data[key])
                    )
                    this.$set(props.row, 'previous_data', null)
                }
            } else {
                // deep copy by round-trip serializing to json, except for dates
                let data_clone = JSON.parse(JSON.stringify(props.row))
                this.columns.forEach((col) => {
                    if (col.date) {
                        data_clone[col.field] = props.row[col.field]
                            ? new Date(props.row[col.field])
                            : null
                    }
                })
                this.$set(props.row, 'previous_data', data_clone)
                this.$set(props.row, 'editable', true)
            }
        },
        delete_row(props) {
            if (props.row.deleting) {
                axios.delete(`/user/${props.row.username}`).then((r) => {
                    const index = this.data.findIndex(
                        (user) => user.username === props.row.username
                    )
                    //this.$set(props.row, 'deleted', true)
                    this.data.splice(index, 1)
                })
            } else {
                this.$set(props.row, 'deleting', true)
            }
        },
    },
}
</script>

<style lang="scss">
.overflow-hidden {
    overflow: hidden;
}

.tag > span {
    line-height: 25px;
}

.table-wrapper {
    min-height: 600px;
    position: relative;
    overflow-y: visible;
}

.searchbox {
    min-width: 300px;
}

.clear-button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    color: grey;

    &:hover {
        color: black;
    }
}

.table {
    & > thead > tr:nth-child(2) .th-wrap > span {
        width: 100%;
    }

    & > tbody > tr > td {
        vertical-align: middle !important;
    }

    & .row_highlighted {
        background: #ffdd9fdd;

        &:hover {
            background: #ffdd9fff !important;
        }
    }

    .valuer-select-badge {
        height: 1.2rem;
        width: 1.2rem;
        border-radius: 50%;
    }
}

.button-loading .icon {
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
</style>
