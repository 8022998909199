var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ref_map",staticStyle:{"height":"100%","width":"100%"}},[_c('l-map',{ref:"map",staticStyle:{"height":"100%","width":"100%","border-radius":"0 0 8px 8px"},attrs:{"center":_vm.center,"max-zoom":18,"min-zoom":11,"zoom":17}},[_c('l-tile-layer',{attrs:{"url":_vm.aerialTileUrl,"name":"Aerial view","layer-type":"base"}}),_c('l-marker',{attrs:{"lat-lng":_vm.center,"icon":_vm.propertyIcon,"options":{
                riseOnHover: true,
                riseOffset: -5000,
            }}}),_vm._l((_vm.population),function(building){return _c('l-marker',{key:'building_' + building.index,attrs:{"lat-lng":[building.lat, building.lng],"icon":_vm.markerIcon(building)},on:{"click":function($event){return _vm.$emit('select', { listing: building, src: 'map' })}}},[_c('l-tooltip',{attrs:{"options":{
                    className: ("building_listing_" + (building.index) + (building.index === _vm.selectedListing ? ' selected' : '')),
                    permanent: true,
                    direction: 'top',
                    opacity: 1,
                }}},[_vm._v(" "+_vm._s(Math.round(building.price_corr / 1000))+"K ")])],1)})],2),_c('b-modal',{attrs:{"active":!!_vm.viewedDescription,"on-cancel":_vm.resetDescription,"width":"640px"}},[_c('div',{staticClass:"modal-card"},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v("Listing description")]),_c('button',{staticClass:"delete",attrs:{"type":"button"},on:{"click":_vm.resetDescription}})]),_c('section',{staticClass:"modal-card-body"},[_vm._v(" "+_vm._s(_vm.viewedDescription)+" ")]),_c('footer',{staticClass:"modal-card-foot"},[_c('div')])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }