var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ers-page-content"},[_c('div',{staticClass:"ers-page-title"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('energy.consumers.title'))+" ")])]),_c('div',{staticClass:"ers-page-title"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t('energy.consumers.question_1a'))+" "),_c('Tooltip',{staticClass:"ers-page-title-tooltip",attrs:{"label":_vm.$t('energy.consumers.tooltip_1')}})],1)]),_c('div',{staticClass:"ers-page-section"},[_c('people-select',{attrs:{"max":12},model:{value:(_vm.n_residents),callback:function ($$v) {_vm.n_residents=$$v},expression:"n_residents"}})],1),(_vm.n_residents > 0)?_c('div',{staticClass:"ers-page-consumption"},[(_vm.getFeatures.energy_consumption_known)?_c('div',{staticClass:"ers-page-section"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t('energy.consumers.question_2a'))+" "),_c('Tooltip',{staticClass:"ers-page-title-tooltip",attrs:{"label":_vm.$t('energy.consumers.tooltip_2')}})],1),_c('div',{staticClass:"ers-page-info"},[_c('img',{attrs:{"src":require("@/assets/icons/ic-lightbulb.svg"),"alt":"","srcset":""}}),_c('p',[_vm._v(_vm._s(_vm.$t('energy.consumers.bubble_info')))])]),_c('div',{staticClass:"section-inputs"},[_c('b-field',{staticClass:"section-inputs-item",attrs:{"label":_vm.$t(("value." + _vm.getHeatingFuel)),"type":{
                        'is-danger': !_vm.$v.fuel.amount.numeric,
                    },"message":[
                        !_vm.$v.fuel.amount.numeric
                            ? _vm.$t('validation.only_numbers')
                            : null ]}},[_c('b-input',{staticClass:"ers-input-field",attrs:{"size":"is-medium"},model:{value:(_vm.fuel.amount),callback:function ($$v) {_vm.$set(_vm.fuel, "amount", $$v)},expression:"fuel.amount"}}),_c('p',{staticClass:"unit"},[_vm._v(" "+_vm._s(_vm.$t(("unit." + (_vm.fuel.unit) + "/year")))+" ")])],1),(
                        !_vm.getIsFuelElectricity && _vm.getHeatingFuel !== 'district_heating'
                    )?_c('div',{staticClass:"section-radios"},[_c('b-radio-button',{attrs:{"native-value":_vm.canonical_consumption_unit(_vm.getHeatingFuel),"size":"is-medium"},model:{value:(_vm.fuel.unit),callback:function ($$v) {_vm.$set(_vm.fuel, "unit", $$v)},expression:"fuel.unit"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t( ("unit." + (_vm.canonical_consumption_unit( _vm.getHeatingFuel )) + "/year") ))+" ")])]),_c('b-radio-button',{attrs:{"native-value":"kwh","size":"is-medium"},model:{value:(_vm.fuel.unit),callback:function ($$v) {_vm.$set(_vm.fuel, "unit", $$v)},expression:"fuel.unit"}},[_c('span',[_vm._v(_vm._s(_vm.$t('unit.kwh/year')))])])],1):_vm._e(),(!_vm.getIsFuelElectricity)?_c('b-field',{staticClass:"section-inputs-electricity",attrs:{"label":_vm.$t('value.electricity'),"type":{
                        'is-danger': !_vm.$v.electricity.amount.numeric,
                    },"message":[
                        !_vm.$v.electricity.amount.numeric
                            ? _vm.$t('validation.only_numbers')
                            : null ]}},[_c('b-input',{staticClass:"ers-input-field",attrs:{"size":"is-medium"},model:{value:(_vm.electricity.amount),callback:function ($$v) {_vm.$set(_vm.electricity, "amount", $$v)},expression:"electricity.amount"}}),_c('p',{staticClass:"unit"},[_vm._v(_vm._s(_vm.$t('unit.kwh/year')))])],1):_vm._e()],1)]):_vm._e(),(!_vm.getIsFuelElectricity && !_vm.getFeatures.energy_consumption_known)?_c('div',{staticClass:"ers-page-section"},[_c('div',{staticClass:"ers-page-title"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t('energy.consumers.question_1b'))+" "),_c('Tooltip',{staticClass:"ers-page-title-tooltip",attrs:{"html":_vm.$t(
                                'energy.consumers.tooltip_popup_1',
                                _vm.getFuelConsumptionData
                            )}})],1)]),_c('multiple-choice',{attrs:{"answers":_vm.levels(),"center":""},model:{value:(_vm.fuel.estimate),callback:function ($$v) {_vm.$set(_vm.fuel, "estimate", $$v)},expression:"fuel.estimate"}})],1):_vm._e(),(
                (_vm.getIsFuelElectricity || _vm.fuel.estimate) &&
                    !_vm.getFeatures.energy_consumption_known
            )?_c('div',{staticClass:"ers-page-section"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t('energy.consumers.question_2b'))+" "),_c('Tooltip',{staticClass:"ers-page-title-tooltip",attrs:{"html":_vm.$t('energy.consumers.tooltip_popup_2', _vm.getElecConsumptionData)}})],1),_c('multiple-choice',{attrs:{"answers":_vm.levels(),"center":""},model:{value:(_vm.electricity.estimate),callback:function ($$v) {_vm.$set(_vm.electricity, "estimate", $$v)},expression:"electricity.estimate"}})],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"column is-12 mt-2"},[_c('div',{staticClass:"is-flex is-justify-content-space-between"},[_c('b-button',{attrs:{"size":"is-medium","type":"is-dark","icon-left":"arrow-left"},on:{"click":function($event){return _vm.changePage(false)}}},[_vm._v(" "+_vm._s(_vm.$t('common.previous'))+" ")]),(_vm.showNextButton)?_c('submit-button',{on:{"on-click":function($event){_vm.$emit('open-summary-modal', {
                        unsubmit: false,
                    })
                    _vm.changePage(true)}}}):_vm._e(),(_vm.showNextButton)?_c('b-button',{class:{ 'ers-next-bttn-dark': _vm.hasRequestBeenUnsubmitted },attrs:{"size":"is-medium","type":"is-primary","icon-right":"arrow-right"},on:{"click":function($event){return _vm.changePage(true)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('common.next')))])]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }