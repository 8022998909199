<!-- eslint-disable vue/no-v-html -->
<template>
    <div :class="{ 'package_selector-on_report': onReport }">
        <div v-if="getScenariosAvailable" class="epc__text text">
            <h1 class="text__title">
                {{ $t('energy.result.package.title') }}
            </h1>
            <div class="text__subtitle">
                {{ $t('energy.result.package.subtitle') }}
            </div>
        </div>
        <div
            :class="{ 'epc__chart--no-renovation': !getScenariosAvailable }"
            class="epc__chart chart"
        >
            <div class="selector">
                <b-tooltip
                    always
                    class="epc-tooltip current-epc"
                    position="is-bottom"
                    :style="{
                        left: `${computePositionFromEpc(getCurrentScenario.epc_ind)}%`,
                    }"
                >
                    <template #content>
                        <p class="bubble__title">
                            {{ $t('ers_report.current_epc') }}
                        </p>
                        <p class="bubble__subtitle">
                            {{ Math.round(getCurrentScenario.epc_ind) }}
                            {{ $t('unit.kwh/m2year') }}
                        </p>
                    </template>
                </b-tooltip>
            </div>

            <div v-if="getScenariosAvailable" class="selector">
                <b-tooltip
                    always
                    class="epc-tooltip budget-epc epc-tooltip--active"
                    position="is-top"
                    :style="{
                        left: `${computePositionFromEpc(
                            getRenovationsSummary.futureEpcScore
                        )}%`,
                    }"
                >
                    <template #content>
                        <p class="bubble__title">
                            {{ $t('ers_report.future_epc') }}
                        </p>
                        <p class="bubble__subtitle">
                            {{ Math.round(getRenovationsSummary.futureEpcScore) }}
                            {{ $t('unit.kwh/m2year') }}
                        </p>
                    </template>
                </b-tooltip>
            </div>

            <epc-array-brussels
                v-if="getRegion === 'Brussels'"
                :interactive="true"
                :current-labels="getCurrentLabels"
            />
            <epc-array-flanders
                v-else-if="getRegion === 'Flanders'"
                :interactive="true"
                :current-labels="getCurrentLabels"
            />
            <epc-array-wallonia
                v-else
                :interactive="true"
                :current-labels="getCurrentLabels"
            />
        </div>

        <div
            v-if="getScenariosAvailable && getScenarios.length > 2"
            class="package-selector-buttons"
        >
            <div class="radio-buttons">
                <b-radio-button
                    v-model="selectedPackage"
                    native-value="budget"
                    size="is-small"
                    class="package-radio-button"
                    :class="{
                        'package-radio-button--selected': getSelectedPackage === 'budget',
                    }"
                >
                    <span>{{ $t('energy.result.package.budget') }}</span>
                </b-radio-button>
                <b-radio-button
                    v-model="selectedPackage"
                    native-value="target"
                    size="is-small"
                    class="package-radio-button package-radio-button--middle"
                    :class="{
                        'package-radio-button--selected': getSelectedPackage === 'target',
                    }"
                >
                    <span>{{ $t('energy.result.package.target') }}</span>
                </b-radio-button>
                <b-radio-button
                    v-model="selectedPackage"
                    native-value="custom"
                    size="is-small"
                    class="package-radio-button"
                    :class="{
                        'package-radio-button--selected': getSelectedPackage === 'custom',
                    }"
                >
                    <span>{{ $t('energy.result.package.custom') }}</span>
                </b-radio-button>
            </div>
            <div v-show="getSelectedPackage === 'budget'" class="budget-input-slider">
                <div class="slider-field">
                    <span>{{ int_value_formatter(getMinMaxBudget.min) }}</span>
                    <b-slider
                        v-model="budget"
                        size="is-medium"
                        :disabled="getSelectedPackage === 'custom'"
                        :type="getSelectedPackage === 'custom' ? 'is-dark' : 'is-primary'"
                        class="package-slider"
                        :min="getMinMaxBudget.min"
                        :max="getMinMaxBudget.max"
                        :custom-formatter="(val) => int_value_formatter(val)"
                        rounded
                    ></b-slider>
                    <span>{{ int_value_formatter(getMinMaxBudget.max) }}</span>
                </div>
                <b-field class="ers-input-field">
                    <b-input
                        v-model="budget"
                        :disabled="getSelectedPackage === 'custom'"
                        size="is-medium"
                        class="budget-input-slider-input"
                        lazy
                    />
                    <p class="unit-eur">
                        €
                    </p>
                </b-field>
            </div>

            <div v-show="getSelectedPackage === 'target'" class="budget-input-slider">
                <div class="slider-field">
                    <span>{{ getMinMaxEpcValues.max }}</span>
                    <!-- Here slider min/max values are negative and inverted to have a decreasing slider -->
                    <b-slider
                        v-model="targetEpc"
                        size="is-medium"
                        :disabled="getSelectedPackage === 'custom'"
                        :type="getSelectedPackage === 'custom' ? 'is-dark' : 'is-primary'"
                        class="package-slider"
                        :min="-getMinMaxEpcValues.max"
                        :max="-getMinMaxEpcValues.min"
                        :custom-formatter="(val) => val * -1 + ' kwh'"
                        rounded
                    ></b-slider>
                    <span>{{ getMinMaxEpcValues.min }}</span>
                </div>
                <b-field class="ers-input-field">
                    <b-input
                        v-model="getPositiveTargetEpc"
                        :disabled="getSelectedPackage === 'custom'"
                        size="is-medium"
                        class="budget-input-slider-input"
                        lazy
                        :custom-formatter="(val) => val * -1 + ' kwh'"
                    />
                    <p class="unit-eur">
                        kwh
                    </p>
                </b-field>
            </div>
        </div>
        <div
            v-else-if="!getScenariosAvailable"
            class="no-renovation-text"
            v-html="$t('energy.result.no_renovation')"
        />
        <div v-else class="only-one-renovation-text">
            {{ $t('energy.result.one_renovation_available') }}
        </div>
    </div>
</template>

<script>
import EpcArrayBrussels from './EpcArrayBrussels'
import EpcArrayFlanders from './EpcArrayFlanders'
import EpcArrayWallonia from './EpcArrayWallonia'
import YearSelector from '../../elements/YearSelector.vue'
import utils from '@/shared/plugins/utils'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import * as d3 from 'd3'

export default {
    name: 'PackageSelector',
    components: { EpcArrayWallonia, EpcArrayBrussels, EpcArrayFlanders },
    props: {
        onReport: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            region: 'flanders',
            budgetTooltip: false,
        }
    },
    computed: {
        ...mapGetters('ersStore', [
            'getTargetEpcScenario',
            'getBestEpcScenario',
            'getSelectedScenario',
            'getRegion',
            'getSelectedPackage',
            'getRenovationsSummary',
            'getCurrentScenario',
            'getBudgetScenario',
            'getScenarios',
            'getScenariosNames',
            'getMinMaxBudget',
            'getScenariosAvailable',
        ]),
        getTargetEpcLabel() {
            const epcs = utils.epc_mapping[this.getRegion]
            return epcs
        },
        getCurrentLabels() {
            return [
                utils.computeLabelFromScore(
                    this.getCurrentScenario.epc_ind,
                    this.getRegion
                ).label,
                utils.computeLabelFromScore(
                    this.getSelectedScenario.epc_ind,
                    this.getRegion
                ).label,
            ]
        },
        getMinMaxEpcValues() {
            if (!this.getScenariosAvailable) {
                return {
                    min: this.getCurrentScenario.epc_ind,
                    max: this.getCurrentScenario.epc_ind,
                }
            }
            let scenarios = this.getScenarios
            const compareEpc = (a, b) => {
                if (a.epc_ind < b.epc_ind) {
                    return -1
                }
                if (a.epc_ind > b.epc_ind) {
                    return 1
                }
                return 0
            }

            scenarios = scenarios.filter((sceno) => sceno.name !== 'current')

            const currentEpc = this.getCurrentScenario.epc_ind

            let max = Math.round(this.getTargetEpcScenario(currentEpc * -1).epc_ind) // currentEpc is inverted because we're working with a decreased slider
            let min = Math.round(scenarios.sort(compareEpc)[0].epc_ind)

            return {
                min,
                max,
            }
        },
        getPositiveTargetEpc: {
            get() {
                return this.$store.state.ersStore.target_epc * -1 // -1 because we're working with a decreased slider
            },
            set(value) {
                this.SET_TARGET_EPC(+value * -1) // -1 because we're working with a decreased slider
                if (this.getSelectedPackage === 'target') {
                    this.select_package({
                        packageName: 'target',
                        scenario: this.getTargetEpcScenario(+value),
                    })
                }
            },
        },
        selectedPackage: {
            get() {
                return this.$store.state.ersStore.results.selectedPackage
            },
            set(value) {
                if (value === 'custom') {
                    this.select_package({
                        packageName: 'custom',
                        scenario: this.getSelectedScenario,
                    })
                } else if (value === 'budget') {
                    this.select_package({
                        packageName: 'budget',
                        scenario: this.getBudgetScenario(+this.budget),
                    })
                } else {
                    this.select_package({
                        packageName: 'target',
                        scenario: this.getTargetEpcScenario(+this.targetEpc),
                    })
                }
                this.$router.push({
                    name: this.$route.name,
                    params: this.$route.params,
                    query: {
                        ...this.$route.query,
                        package: this.getSelectedPackage,
                        scenario: this.getSelectedScenario.name,
                    },
                })
            },
        },
        budget: {
            get() {
                return this.$store.state.ersStore.budget
            },
            set(value) {
                this.SET_BUDGET(+value)
                if (this.getSelectedPackage === 'budget') {
                    this.select_package({
                        packageName: 'budget',
                        scenario: this.getBudgetScenario(+value),
                    })
                }
            },
        },
        targetEpc: {
            get() {
                return this.$store.state.ersStore.target_epc
            },
            set(value) {
                this.SET_TARGET_EPC(+value)
                if (this.getSelectedPackage === 'target') {
                    this.select_package({
                        packageName: 'target',
                        scenario: this.getTargetEpcScenario(+value),
                    })
                }
            },
        },
    },
    mounted() {
        if (this.getSelectedPackage) {
            this.set_route()
        }
    },
    methods: {
        ...mapMutations('ersStore', [
            'SET_SELECTED_PACKAGE',
            'SET_SELECTED_SCENARIO',
            'SET_BUDGET',
            'SET_TARGET_EPC',
        ]),
        ...mapActions('ersStore', ['load_simulation', 'track_event']),
        computePositionFromEpc(epc) {
            if (!this.getRegion) return
            //Get a list of EPCs for the region
            let epcs = utils.epc_percent_mapping(this.getRegion).epcs

            //This var will represent the base percentage we have to move.
            //EPC svgs have been modified to coincide with the number of breakpoints we have for each label
            //If the lower EPC label (G or F depending on region) has X breakpoints, it has to be the size of X
            //smaller items of the SVG + their spacing (!!!!)
            let basePerc

            //if EPC bigger than the high threshold, return 0 (position is very beginning)
            if (epc > epcs[0]) {
                return 0
            }
            //if EPC smaller than the low threshold, return 100 (position is very end)
            if (epc < epcs[epcs.length - 1]) {
                return 100
            }

            for (let i = 0; i < epcs.length; i++) {
                //get which "segment" we're in and define base percentage based on that
                //this is why it's specified above that SVGs need a specific sizing for the longer part of the arrow
                //this is also the fallback if the below condition is never reached for some reason
                basePerc = (100 / epcs.length) * i
                if (epc > epcs[i]) {
                    //Above condition reached when we found the correct range
                    const minRange = epcs[i - 1]
                    const maxRange = epcs[i]

                    //get how much percents of the range is needed to be added to the basePercentage
                    //(input - min) * 100 / (max - min) = percentage of input between a range of [min, max] representing [0, 100]
                    //divide by epcs.length to get that percentage on a segment
                    const additional =
                        ((epc - minRange) * 100) / (maxRange - minRange) / epcs.length

                    return basePerc + additional
                }
            }
            //Fallback:
            // if the above loop never returned anything, return the basePercentage + half of a segment's length
            // to center the tooltips to its relative epc mlabel
            return basePerc + 100 / epcs.length / 2
        },
        select_package(package_info) {
            const oldScenarioName = this.getSelectedScenario.name
            this.SET_SELECTED_PACKAGE(package_info)

            const newScenarioName = this.getSelectedScenario.name

            if (
                // limit the speed rate at which parameters will be inserted into the url
                (this.getSelectedPackage === 'budget' ||
                    this.getSelectedPackage === 'target') &&
                oldScenarioName === newScenarioName
            ) {
                return
            }

            this.set_route()
        },
        set_route() {
            this.$router.push({
                name: this.$route.name,
                params: this.$route.params,
                query: {
                    ...this.$route.query,
                    package: this.getSelectedPackage,
                    scenario: this.getSelectedScenario.name,
                    budget: this.budget,
                    targetEpc: this.targetEpc,
                },
            })
        },
        updateBudget(val) {
            if (this.getSelectedPackage === 'budget') {
                this.budget = +val
                this.select_package({
                    packageName: 'budget',
                    scenario: this.getBudgetScenario(+this.budget),
                })
            }
        },
        int_value_formatter(x) {
            return utils.int_value_formatter(x, this.$route.params.lang)
        },
    },
}
</script>

<style lang="scss">
.ers-input-field {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.radio-buttons {
    display: flex;
    border: 1px solid #009cff;
    border-radius: 0.3125rem;
    overflow: hidden;
}

.budget-input-slider {
    display: flex;
    justify-content: space-between;
    &-label {
        color: #000;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .slider-field {
        display: flex;
        gap: 1rem;
        min-width: 21rem;
        color: #798da6;
        text-align: center;
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: auto;
        span {
            margin: auto;
        }
    }
    &-input {
        display: flex;
        justify-content: flex-end;
        margin-block-start: auto;
        input {
            color: #001837 !important;
            font-size: 0.875rem !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 1.25rem !important; /* 142.857% */
            max-width: 10rem !important;
            margin-inline-start: 1.5rem;
        }
    }
}
.unit-eur {
    display: grid;
    align-items: end;
    transform: translateX(-150%) translateY(-20%);
    z-index: 4;
}
.package-radio-button {
    &--selected {
        label {
            span {
                color: white !important;
            }
        }
    }
    label {
        min-height: 100%;
        border: none;
        border-radius: 0px !important;
        span {
            display: flex;
            color: #0096ff;
            align-items: center;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.25rem;
        }
    }
    &--middle {
        label {
            border-left: 1px solid #009cff;
            border-right: 1px solid #009cff;
        }
    }
}

.no-renovation-text {
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    ul {
        padding-left: 1.5rem;
        color: #001837;
        li {
            list-style-type: disc;
            color: #001837;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }
    p {
        color: #001837;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        &:first-child {
            color: #001837;
            font-size: 1.375rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }
    .text-primary {
        color: rgba(0, 156, 255, 1);
    }
}

.only-one-renovation-text {
    margin: 6rem 0 3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: #001837;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
</style>

<style lang="scss">
.b-tooltip.epc-tooltip .tooltip-content {
    background: #162d49 !important;
    &:before {
        border-top-color: #162d49 !important;
    }
}

.b-tooltip.current-epc .tooltip-content {
    background: #162d49 !important;
    &:before {
        border-bottom-color: #162d49 !important;
    }
}

.b-tooltip.budget-epc .tooltip-content {
    background: #162d49 !important;
    &:before {
        border-top-color: #162d49 !important;
    }
}

.b-tooltip.epc-tooltip--active .tooltip-content {
    background: #16a3fd !important;
    &:before {
        border-top-color: #16a3fd !important;
    }
}

.b-tooltip.epc-tooltip--active-bottom .tooltip-content {
    background: #16a3fd !important;
    &:before {
        border-bottom-color: #16a3fd !important;
    }
}
</style>

<style lang="scss" scoped>
.package-selector-buttons {
    display: flex;
    margin-block: 6rem 3rem;
    max-height: 2.75rem;
    gap: 1.5rem;
}

.click-choose-bttn {
    margin-block-start: auto !important;
    display: flex !important;
    padding: 0.75rem 1rem !important;
    align-items: center !important;
    gap: 0.5rem !important;
    background: #fff !important;
    border: 1px solid #d6e2e9 !important;

    &-text {
        display: flex;
        align-items: center !important;
        gap: 0.5rem;
        color: rgba(0, 24, 55, 0.7);
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}
.epc {
    margin-block-end: 3rem;
}

.package_selector-on_report {
    .epc-tooltip {
        &:not(.epc-tooltip--active):not(.current-epc) {
            display: none;
        }
    }
}

.epc__text {
    margin-block-start: 50px;
    margin-block-end: 120px;
}
.epc__chart {
    &--no-renovation {
        margin-bottom: 6rem;
        margin-top: 8rem;
    }
    display: flex;
    justify-content: center;
    position: relative;
}

.chart__step {
    display: flex;
    flex-direction: column;
}

.step__bubbles {
    display: flex;
    position: relative;
    justify-content: space-between;
}

.step__img {
    margin-block-start: auto;
    height: 41px;
}

.bubble__subtitle {
    font-size: 11px;
    color: rgba(255, 255, 255, 0.5);
}

.target-epc {
    position: absolute;
    top: -5px;
    z-index: 0;
}

.best-epc,
.target-epc,
.custom-epc,
.current-epc,
.budget-epc {
    position: absolute;
    top: -5px;
    z-index: 0;
}

.current-epc {
    top: 5px;
    pointer-events: none;
}

.selector {
    cursor: pointer;
}

.text__title {
    color: #001837;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.text__subtitle {
    color: #798da6;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 1.5rem;
}

.budget-input-slider-label-disabled {
    opacity: 0.5;
}

@media print {
    .epc__text {
        margin-bottom: 1.2cm;
        margin-block-start: 0;
    }

    .bubble {
        &__title,
        &__subtitle {
            font-size: 0.9rem;
            color: white;
        }
        &__subtitle {
            font-weight: 400;
        }
        &__title {
            font-weight: bold;
        }
    }
}
</style>
