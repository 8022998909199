<template>
    <div class="ers-page">
        <div class="ers-page-content">
            <div class="ers-page-title">
                <h1>
                    {{ $t('energy.basement.question') }}
                    <Tooltip :label="$t('energy.basement.tooltip')" />
                </h1>
            </div>
            <yes-no v-model="has_basement" />
            <div class="is-flex is-justify-content-space-between">
                <b-button
                    size="is-medium"
                    type="is-dark"
                    icon-left="arrow-left"
                    tag="router-link"
                    :to="{
                        name: 'ers.attic',
                        params: {
                            lang: $route.params.lang,
                        },
                        query: $route.query,
                    }"
                    @click="
                        set_and_submit_feature({
                            has_basement: has_basement,
                        })
                    "
                >
                    {{ $t('common.previous') }}
                </b-button>
                <submit-button
                    @on-click="
                        $emit('open-summary-modal', {
                            unsubmit: false,
                        })
                        nextPage()
                    "
                />
                <b-button
                    v-if="has_basement !== null"
                    :class="{ 'ers-next-bttn-dark': hasRequestBeenUnsubmitted }"
                    size="is-medium"
                    type="is-primary"
                    icon-right="arrow-right"
                    @click="nextPage"
                >
                    <span>{{ $t('common.next') }}</span>
                </b-button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import YesNo from '../../elements/YesNo.vue'
import Tooltip from '../../elements/Tooltip.vue'
import SubmitButton from '../../elements/SubmitButton.vue'

export default {
    name: 'Basement',
    components: { SubmitButton, Tooltip, YesNo },
    props: {
        proceedFn: {
            type: Function,
            required: false,
            default: null,
        },
        cancelFn: {
            type: Function,
            required: false,
            default: null,
        },
    },
    data: () => ({
        has_basement: null,
    }),
    computed: {
        ...mapGetters('ersStore', [
            'getFeatures',
            'getAddress',
            'getOtherData',
            'hasRequestBeenUnsubmitted',
        ]),
    },
    watch: {
        has_basement(newVal, oldVal) {
            if (newVal !== this.getFeatures.has_basement) {
                this.nextPage()
            }
        },
        getFeatures: {
            handler(newVal, oldVal) {
                this.has_basement = newVal.has_basement
            },
            deep: true,
        },
    },
    mounted() {
        const prevVal = this.getFeatures.has_basement
        if (prevVal !== null) {
            this.has_basement = prevVal
        }
    },
    methods: {
        ...mapMutations('ersStore', ['UPDATE_STATUS']),
        ...mapMutations('ersStore', ['SET_FEATURES']),
        ...mapActions('ersStore', ['set_and_submit_feature']),
        nextPage() {
            let nextPage = 'ers.living-area'
            if (+this.getFeatures.effective_level < 3)
                nextPage = 'ers.building-dimensions'
            this.set_and_submit_feature({
                feature: {
                    has_basement: this.has_basement,
                },
                nextPage,
            })
            this.$router.push({
                name: nextPage,
                params: {
                    lang: this.$route.params.lang,
                },
                query: this.$route.query,
            })
        },
    },
}
</script>
