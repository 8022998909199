<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="bubble py-2 px-3 mb-2" :class="{ self: isOwn }">
        <div class="message-meta">
            <h4
                class="chat-sender"
                v-html="
                    isOwn
                        ? 'You'
                        : isAgent
                        ? `Bank agent (<b>${surname} ${name}</b>)`
                        : 'Rock.estate Valuation Specialist' +
                          (isValuer ? ` (<b>${surname} ${name}</b>)` : '')
                "
            />
            <h4 class="chat-time">
                <b-tooltip
                    :label="detailedTime"
                    :position="isOwn ? 'is-left' : 'is-right'"
                    type="is-black"
                >
                    {{ timespan }}
                </b-tooltip>
            </h4>
        </div>
        <p>{{ content }}</p>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    name: 'ChatBubble',
    props: {
        content: {
            type: String,
            required: true,
        },
        isOwn: {
            type: Boolean,
            default: false,
        },
        isAgent: {
            type: Boolean,
            default: true,
        },
        time: {
            type: Date,
            required: true,
        },
        surname: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapGetters('auth', ['hasRole']),
        isValuer() {
            return this.hasRole('valuer', 'dvm')
        },
        detailedTime() {
            let month = this.time.getMonth() + 1
            let day = this.time.getDate()
            let hours = this.time.getHours()
            let minutes = this.time.getMinutes() + 1

            let returnStr = ''
            returnStr += day < 10 ? '0' : ''
            returnStr += day
            returnStr += '/'
            returnStr += month < 10 ? '0' : ''
            returnStr += month
            returnStr += '/'
            returnStr += this.time.getFullYear()
            returnStr += ' '
            returnStr += hours < 10 ? '0' : ''
            returnStr += hours
            returnStr += ':'
            returnStr += minutes < 10 ? '0' : ''
            returnStr += minutes

            return returnStr
        },
        timespan() {
            const now = new Date()
            const diffInWeeks = Math.floor(
                (now.getTime() - this.time.getTime()) / (7 * 24 * 60 * 60 * 1000)
            )
            if (diffInWeeks > 0) {
                return diffInWeeks + ' week' + (diffInWeeks > 1 ? 's ago' : ' ago')
            }

            const diffInDays = Math.floor(
                (now.getTime() - this.time.getTime()) / (24 * 60 * 60 * 1000)
            )
            if (diffInDays > 0) {
                return diffInDays + ' day' + (diffInDays > 1 ? 's ago' : ' ago')
            }

            const diffInHours = Math.floor(
                (now.getTime() - this.time.getTime()) / (60 * 60 * 1000)
            )
            if (diffInHours > 0) {
                return diffInHours + ' hour' + (diffInHours > 1 ? 's ago' : ' ago')
            }

            const diffInMins = Math.floor(
                (now.getTime() - this.time.getTime()) / (60 * 1000)
            )
            if (diffInMins > 0) {
                return diffInMins + ' minute' + (diffInMins > 1 ? 's ago' : ' ago')
            }

            return 'Just now'
        },
    },
}
</script>
<style lang="scss" scoped>
@import '@/shared/assets/style/buefy.scss';

.bubble {
    max-width: 60%;
    display: flex;
    flex-direction: column;

    border-radius: $bubbleRadius $bubbleRadius $bubbleRadius 0;
    color: $black;
    background: #e4e4e4;
    align-self: flex-start;
    align-items: flex-start;

    .message-meta {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        font-size: 0.6rem;
        width: 100%;
        gap: 1rem;

        flex-direction: row;
    }

    &.self {
        border-radius: $bubbleRadius $bubbleRadius 0 $bubbleRadius;
        color: white;
        background-color: $primary;
        align-self: flex-end;
        align-items: flex-end;

        .message-meta {
            flex-direction: row-reverse;
        }
    }
}
</style>
