<template>
    <grid-card no-padding is-full class="flood_map_card">
        <re-map
            :building-id="getFeatures.building_id"
            :parcel-ids="getFeatures.parcel_ids"
            :level="getFeatures.level"
            :region="getFeatures.region"
            :center="{
                lat: getFeatures.f_lat,
                lng: getFeatures.f_lng,
            }"
            :map-scene2d="get_map_scene2d"
            :read-only="true"
            :focus-layer="'building'"
            :layers-control-visible="true"
            :options="{
                zoomControl: true,
                attributionControl: false,
                scrollWheelZoom: false,
                attributionControl: false,
                scrollWheelZoom: false,
                doubleClickZoom: true,
                boxZoom: true,
                dragging: true,
            }"
            :aerial-tile-visible="false"
            :cadastral-tile-visible="false"
            class="has-ratio"
            @update:mapScene2d="SET_MAP_SCENE2D($event)"
        ></re-map>
    </grid-card>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import GridCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard'
import ReMap from '@/components/common/Map.vue'

export default {
    name: 'FloodMap',
    components: {
        GridCard,
        ReMap,
    },
    computed: {
        ...mapGetters('valuationStore/Address', ['getFeatures', 'get_map_scene2d']),
    },
    methods: {
        ...mapMutations('valuationStore/Address', ['SET_MAP_SCENE2D']),
    },
}
</script>
<style lang="scss" scoped>
.flood_map_card {
    height: 350px;
    padding-top: 0;
}
</style>
