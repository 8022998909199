import { render, staticRenderFns } from "./Renderer3D.vue?vue&type=template&id=28e9a7fa&scoped=true&"
import script from "./Renderer3D.vue?vue&type=script&lang=js&"
export * from "./Renderer3D.vue?vue&type=script&lang=js&"
import style0 from "./Renderer3D.vue?vue&type=style&index=0&id=28e9a7fa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../Monolith/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28e9a7fa",
  null
  
)

export default component.exports