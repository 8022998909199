<template>
    <grid-card title="State">
        <template slot="header">
            <white-button class="docs_buttn" @click="openDocuments">
                <span>Documents</span>
                <svg-icon icon="documents" height="16" scale="1" color="#001837" />
                <div v-if="getDocuments && getDocuments.length" class="docs_buttn-badge">
                    <h5>{{ getDocuments.length }}</h5>
                </div>
            </white-button>
        </template>
        <template slot="default">
            <div class="grid_card-content_grid">
                <div v-if="f_epc_label" class="content_grid-item content_grid-item--full">
                    <b-field label="EPC Label">
                        <epc-array-flanders
                            v-if="isFlanders"
                            interactive
                            :current-label="f_epc_label"
                            @select-epc="selectEpc"
                        />
                        <epc-array-bru
                            v-else-if="isBrussels"
                            interactive
                            :current-label="f_epc_label"
                            @select-epc="selectEpc"
                        />
                        <epc-array-wal
                            v-else-if="isWallonia"
                            interactive
                            :current-label="f_epc_label"
                            @select-epc="selectEpc"
                        />
                    </b-field>
                </div>
                <div class="content_grid-item">
                    <b-field label="EPC Value">
                        <b-input v-model="f_epc_numeric" @input="updateEpcLabel" />
                    </b-field>
                </div>
                <div class="content_grid-item">
                    <b-field label="Construction year">
                        <b-input v-model="f_construction_year" />
                    </b-field>
                </div>
            </div>
        </template>
        <b-modal v-model="documentsShown" width="fit-content">
            <documents />
        </b-modal>
    </grid-card>
</template>
<script>
import GridCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard'
import WhiteButton from '@/apps/Valuation/components/ValuerTool/elements/WhiteButton.vue'
import Documents from './Documents.vue'
import EpcArrayFlanders from './EpcArrayFlanders.vue'
import EpcArrayBru from './EpcArrayBru.vue'
import EpcArrayWal from './EpcArrayWal.vue'
import utils from '@/shared/plugins/utils'
import { mapGetters, mapMutations } from 'vuex'
import debounce from 'lodash/debounce'

export default {
    name: 'State',
    components: {
        GridCard,
        WhiteButton,
        EpcArrayFlanders,
        EpcArrayBru,
        EpcArrayWal,
        Documents,
    },
    data: () => ({
        f_epc_label: null,
        f_epc_numeric: null,
        f_construction_year: null,
        documentsShown: false,
    }),
    computed: {
        ...mapGetters('valuationStore/Address', ['getFeatures']),
        ...mapGetters('valuationStore', ['getDocuments']),
        isFlanders() {
            return ['Flanders', 'BE-VLG'].includes(this.getFeatures.region)
        },
        isBrussels() {
            return ['Brussels', 'BE-BRU'].includes(this.getFeatures.region)
        },
        isWallonia() {
            return ['Wallonia', 'BE-WAL'].includes(this.getFeatures.region)
        },
        epc_color() {
            let region = this.getFeatures.region

            return utils.epc_color(region, label)
        },
    },
    watch: {
        // Debounce everything to not trigger updates on every keystroke
        f_epc_label: debounce(function(val) {
            this.SET_FEATURES({
                f_epc_label: val,
            })
        }, 250),
        f_construction_year: debounce(function(val) {
            this.SET_FEATURES({
                f_construction_year: +val,
            })
        }, 250),
        f_epc_numeric: debounce(function(val) {
            if (val === null || typeof val === 'undefined') {
                this.f_epc_numeric = 0
            }

            this.SET_FEATURES({
                f_epc_numeric: +val,
            })
        }, 250),
    },
    mounted() {
        this.f_epc_label = (() => {
            if (this.getFeatures.f_epc_label) return this.getFeatures.f_epc_label

            let region = this.getFeatures.region
            let epc_value = this.getFeatures.f_epc_numeric

            return utils.epc_label(region, epc_value)
        })()

        this.f_epc_numeric = (() => {
            if (this.getFeatures.f_epc_numeric) return this.getFeatures.f_epc_numeric

            let region = this.getFeatures.region
            let epc_label = this.getFeatures.f_epc_label

            return utils.epc_mapping[region][epc_label]
        })()

        this.f_construction_year = this.getFeatures.f_construction_year
    },
    methods: {
        ...mapMutations('valuationStore/Address', ['SET_FEATURES']),
        // Debounce to not trigger updates on every keystroke
        updateEpcLabel: debounce(function() {
            this.f_epc_label = utils.epc_label(
                this.getFeatures.region,
                this.f_epc_numeric
            )
        }, 250),
        selectEpc(epc) {
            this.f_epc_numeric = utils.epc_mapping[this.getFeatures.region][epc]
            this.f_epc_label = epc

            // this.SET_FEATURES({
            //     f_epc_label: this.f_epc_label,
            //     f_epc_numeric: this.f_epc_numeric,
            // })
        },
        openDocuments() {
            this.documentsShown = true
        },
        closeDocuments() {
            this.documentsShown = false
        },
    },
}
</script>
<style lang="scss" scoped>
.docs_buttn {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    width: fit-content;

    span {
        color: #001837;
        font-size: 12px;
        font-weight: 700;
        line-height: normal;
    }

    &-badge {
        background: #009cff33;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        h5 {
            color: #009cff;
            font-size: 8px;
            font-weight: 700;
        }
    }
}
</style>
