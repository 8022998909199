var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ers-page-content"},[_c('div',{staticClass:"ers-page-title"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('energy.heating.question'))+" "),_c('Tooltip',{attrs:{"label":_vm.$t('energy.heating.tooltip')}})],1)]),_c('div',{staticClass:"ers-page-section"},[_c('div',{staticClass:"ers-page-section__title"},[_c('svg-icon',{attrs:{"icon":"heating-fuel-gas","width":"1rem","height":"1.57rem","color":"#798DA6"}}),_c('p',[_vm._v(_vm._s(_vm.$t("value.gas")))])],1),_c('div',{staticClass:"ers-page-section-choices"},_vm._l((_vm.gasTypes),function(type){return _c('div',{key:type.value,staticClass:"ers-page-section-choices__item",class:{
                    active: _vm.isActive('gas', type.value),
                },on:{"click":function($event){return _vm.setHeating('gas', type.value)}}},[_c('div',{staticClass:"ers-page-icon-wrapper"},[_c('svg-icon',{attrs:{"icon":"heating-fuel-gas","width":"1.625rem","height":"2.56613rem","color":_vm.isActive('gas', type.value) ? '#0096ff' : '#798DA6'}})],1),_c('p',[_vm._v(_vm._s(_vm.$t(("value." + (type.value)))))])])}),0)]),_c('div',{staticClass:"ers-page-section"},[_c('div',{staticClass:"ers-page-section__title"},[_c('svg-icon',{attrs:{"icon":"heating-fuel-oil","width":"1rem","height":"1.57rem","color":"#798DA6"}}),_c('p',[_vm._v(_vm._s(_vm.$t("value.oil")))])],1),_c('div',{staticClass:"ers-page-section-choices"},_vm._l((_vm.oilTypes),function(type){return _c('div',{key:type.value,staticClass:"ers-page-section-choices__item",class:{
                    active: _vm.isActive('oil', type.value),
                },on:{"click":function($event){return _vm.setHeating('oil', type.value)}}},[_c('div',{staticClass:"ers-page-icon-wrapper"},[_c('svg-icon',{attrs:{"icon":"heating-fuel-oil","width":"1.625rem","height":"2.56613rem","color":_vm.isActive('oil', type.value) ? '#0096ff' : '#798DA6'}})],1),_c('p',[_vm._v(_vm._s(_vm.$t(("value." + (type.value)))))])])}),0)]),_c('div',{staticClass:"ers-page-section"},[_c('div',{staticClass:"ers-page-section__title"},[_c('svg-icon',{attrs:{"icon":"heating-fuel-electric","width":"1rem","height":"1.57rem","color":"#798DA6"}}),_c('p',[_vm._v(_vm._s(_vm.$t("value.electricity")))])],1),_c('div',{staticClass:"ers-page-section-choices"},_vm._l((_vm.electricityTypes),function(type){return _c('div',{key:type.value,staticClass:"ers-page-section-choices__item",class:{
                    active: _vm.isActive('electricity', type.value),
                },on:{"click":function($event){return _vm.setHeating('electricity', type.value)}}},[_c('div',{staticClass:"ers-page-icon-wrapper"},[_c('svg-icon',{attrs:{"icon":"heating-fuel-electric","width":"1.625rem","height":"2.56613rem","color":_vm.isActive('electricity', type.value)
                                ? '#0096ff'
                                : '#798DA6'}})],1),_c('p',[_vm._v(_vm._s(_vm.$t(("value." + (type.value)))))])])}),0)]),_c('div',{staticClass:"ers-page-section"},[_c('div',{staticClass:"ers-page-section__title"},[_c('p',[_vm._v(_vm._s(_vm.$t("value.other")))])]),_c('div',{staticClass:"ers-page-section-types"},_vm._l((_vm.fuels),function(type){return _c('div',{key:type.value,staticClass:"ers-page-section-choices__item",class:{
                    active: _vm.getSelectedFuel === type.value,
                },on:{"click":function($event){_vm.setHeating(type.value, _vm.getOtherTypes(type.value))}}},[_c('div',{staticClass:"ers-page-icon-wrapper"},[_c('svg-icon',{attrs:{"icon":type.icon,"width":"1.625rem","height":"2.56613rem","color":_vm.getSelectedFuel === type.value ? '#0096ff' : '#798DA6'}})],1),_c('p',[_vm._v(_vm._s(_vm.$t(("value." + (type.value)))))])])}),0)]),(_vm.showInstallYear)?_c('div',[_c('h1',{staticClass:"installation-year-title"},[_vm._v(" "+_vm._s(_vm.$t('features_question.installation_year'))+" ")]),_c('year-selector',{attrs:{"minimum":_vm.getFeatures.f_construction_year || 1800},model:{value:(_vm.heating_attributes[0].installation_year),callback:function ($$v) {_vm.$set(_vm.heating_attributes[0], "installation_year", $$v)},expression:"heating_attributes[0].installation_year"}})],1):_vm._e(),_c('div',{staticClass:"column is-12 mt-2"},[_c('div',{staticClass:"is-flex is-justify-content-space-between"},[_c('b-button',{attrs:{"id":"nextButton","size":"is-medium","type":"is-dark","icon-left":"arrow-left"},on:{"click":function($event){return _vm.changePage(false)}}},[_vm._v(" "+_vm._s(_vm.$t('common.previous'))+" ")]),_c('submit-button',{on:{"on-click":function($event){_vm.$emit('open-summary-modal', {
                        unsubmit: false,
                    })
                    _vm.changePage(true)}}}),(_vm.showNextButton)?_c('b-button',{class:{ 'ers-next-bttn-dark': _vm.hasRequestBeenUnsubmitted },attrs:{"size":"is-medium","type":"is-primary","icon-right":"arrow-right"},on:{"click":function($event){return _vm.changePage(true)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('common.next')))])]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }