var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ers-page-content"},[_c('div',{staticClass:"ers-page-title"},[_c('h1',[_vm._v(" "+_vm._s(("" + (_vm.$t('customer_ref_page.title'))))+" "),_c('Tooltip',{attrs:{"label":_vm.$t('customer_ref_page.tooltip')}})],1)]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.sendRequest($event)}}},[_c('b-field',{attrs:{"message":[
                !_vm.$v.customer_ref.argentaOnlyNumbers
                    ? _vm.$t('validation.only_numbers')
                    : null ],"type":{
                'is-danger': !_vm.$v.customer_ref.argentaOnlyNumbers,
            }}},[_c('b-input',{attrs:{"disabled":!_vm.active,"placeholder":_vm.customer_ref_placeholder},on:{"blur":function($event){return _vm.$v.customer_ref.$touch()},"input":function($event){return _vm.$v.customer_ref.$touch()}},model:{value:(_vm.customer_ref),callback:function ($$v) {_vm.customer_ref=$$v},expression:"customer_ref"}})],1)],1),_c('div',{staticClass:"ers-page-buttons"},[_c('b-button',{attrs:{"tag":"router-link","size":"is-medium","type":"is-dark","icon-left":"arrow-left","to":{
                name: 'ers.welcome',
                params: {
                    lang: _vm.$route.params.lang,
                },
            }}},[_c('span',[_vm._v(_vm._s(_vm.$t('common.previous')))])]),(_vm.customer_ref.length && !_vm.$v.customer_ref.$invalid)?_c('b-button',{attrs:{"size":"is-medium","type":"is-primary","icon-right":"arrow-right"},on:{"click":_vm.sendRequest}},[_c('span',[_vm._v(_vm._s(_vm.$t('common.next')))])]):_vm._e()],1),_c('b-modal',{attrs:{"has-modal-card":"","trap-focus":"","can-cancel":['escape'],"destroy-on-hide":false,"aria-role":"dialog","aria-modal":""},model:{value:(_vm.isConflictModalActive),callback:function ($$v) {_vm.isConflictModalActive=$$v},expression:"isConflictModalActive"}},[(_vm.isConflictModalActive)?_c('div',{staticClass:"exit-modal"},[_c('h2',{staticClass:"title is-size-4 has-text-weight-bold mgt-medium is-flex is-align-items-center pb-4"},[_vm._v(" "+_vm._s(_vm.$t('global.shared.customer_ref_modal.title'))+" ")]),_c('p',[_vm._v(_vm._s(_vm.$t('global.shared.customer_ref_modal.content')))]),_c('p',[_vm._v(_vm._s(_vm.$t('global.shared.customer_ref_modal.cta')))]),_c('div',[_c('b-button',{attrs:{"size":"is-medium","type":"is-dark"},on:{"click":function($event){_vm.isConflictModalActive = false}}},[_vm._v(" "+_vm._s(_vm.$t('common.previous'))+" ")]),_c('b-button',{attrs:{"size":"is-medium","type":"is-dark","tag":"router-link","to":{
                        name: 'dashboard',
                        params: { lang: _vm.$route.params.lang },
                        query: {
                            valuation_request_refs: _vm.conflicting_valuation_requests.join(
                                ','
                            ),
                        },
                    }}},[_vm._v(" "+_vm._s(_vm.$t('global.shared.customer_ref_modal.dashboard'))+" ")]),_c('b-button',{attrs:{"size":"is-medium","type":"is-primary"},on:{"click":_vm.ignoreConflict}},[_vm._v(" "+_vm._s(_vm.$t('global.shared.customer_ref_modal.continue'))+" ")])],1)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }