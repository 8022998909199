<template>
    <router-view />
</template>
<script>
export default {
    name: 'Floorplan',
}
</script>
<style lang="scss">
.floorplan_layout {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    width: 100%;
    gap: 24px;

    &-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &-actions {
            display: flex;
            flex-direction: row;
            gap: 8px;
            align-items: center;
            justify-content: flex-end;
        }

        &-breadcrumbs {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;
            color: rgba(0, 44, 101, 0.6);
            font-size: 24px;
            font-weight: 700;

            & > *:last-child {
                color: #002c65;
            }

            .chevron {
                opacity: 0.6;
                width: 16px;
                transform-origin: center;
                transform: rotate(-90deg);
            }
        }
    }
}
</style>
