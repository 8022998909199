<template>
    <div class="container section is-fluid">
        <div class="faq-container">
            <h1
                class="title is-size-2 mgt-medium is-family-secondary has-text-weight-bold"
            >
                {{ $t('section.helpcenter') }}
            </h1>

            <b-input
                v-model="searchField"
                placeholder="Search"
                class="mb-6"
                @blur="$v.searchField.$touch()"
                @input="$v.searchField.$touch()"
            ></b-input>

            <div v-for="category in Object.keys(items)" :key="category">
                <h2 class="has-text-primary is-size-5 has-text-weight-medium mgb-small">
                    {{ t(`section.${category}`) }}
                </h2>

                <div class="questions faq-content mb-6">
                    <question
                        v-for="item in items[category]"
                        :id="item.name"
                        :key="item.name"
                        :open="item.shouldOpen"
                        :q-data="{
                            question: $t(`faq.${item.name}_question`),
                            answer: $t(`faq.${item.name}_answer`),
                            modules: item.modules.filter((m) =>
                                accessibleModules.includes(m)
                            ),
                        }"
                    />
                </div>
            </div>
            <div v-if="shouldShowContact" class="contact-link faq-content mb-6">
                <h2
                    class="has-text-black is-size-5 has-text-weight-medium is-flex is-align-items-center"
                >
                    {{ $t('global.helpcenter.question') }}
                </h2>
                <b-button size="is-medium" type="is-primary" @click="showContactModal">
                    {{ $t('global.helpcenter.contact_cta') }}
                </b-button>
            </div>
        </div>
        <b-modal
            v-model="isContactModalActive"
            has-modal-card
            trap-focus
            :can-cancel="['escape']"
            aria-role="dialog"
            aria-modal
            class="contact-modal"
        >
            <div v-if="!isFeedbackSent" class="exit-modal">
                <button class="closeModalBttn" @click="hideContactModal">
                    <img src="@/assets/icons/bttn-close-black.svg" alt="" />
                </button>
                <h2
                    class="title is-size-4 has-text-weight-bold mgt-medium is-flex is-align-items-center pb-4"
                >
                    {{ $t('global.helpcenter.contact_form.title') }}
                </h2>
                <p class="mb-5">
                    {{ $t('global.helpcenter.contact_form.content') }}
                </p>
                <form class="contact-form">
                    <b-field :label="$t('common.module')">
                        <b-select v-model="contactForm.module">
                            <option
                                v-for="m in accessibleModules.concat(['-'])"
                                :key="`module_${m}`"
                                :value="m"
                            >
                                {{ $t(`value.${m}`) }}
                            </option>
                        </b-select>
                    </b-field>
                    <b-field :label="$t('global.helpcenter.contact_form.subject_label')">
                        <b-select v-model="contactForm.subject">
                            <option
                                v-for="(subject, index) in subjects"
                                :key="`subject_option_${index}`"
                                :value="subject"
                            >
                                {{
                                    $t(
                                        `global.helpcenter.contact_form.subject_${subject}`
                                    )
                                }}
                            </option>
                        </b-select>
                    </b-field>
                    <b-field
                        v-if="contactForm.subject === 'other'"
                        :label="$t('global.helpcenter.contact_form.subject_other')"
                    >
                        <b-input v-model="contactForm.otherSubject" />
                    </b-field>
                    <b-field :label="t('global.helpcenter.contact_form.customer_ref')">
                        <b-select v-model="contactForm.valuation_request_ref" required>
                            <option
                                v-for="request in userRequests"
                                :key="request.valuation_request_ref"
                                :value="request.valuation_request_ref"
                            >
                                {{ request.customer_ref }} -
                                {{ display_address(request) }}
                            </option>
                            <option disabled>-------</option>
                            <option value="N/A">
                                {{
                                    $t('global.helpcenter.contact_form.not_specific_req')
                                }}
                            </option>
                        </b-select>
                    </b-field>
                    <b-field :label="$t('global.helpcenter.contact_form.message')">
                        <b-input v-model="contactForm.content" type="textarea" />
                    </b-field>
                    <div
                        v-for="(file, index) in contactForm.screenshots"
                        :key="`screenshot_${index}`"
                        class="screenshot-item"
                    >
                        <img src="@/assets/icons/ic-file.svg" alt="" class="file-icon" />
                        <span class="screenshot-name">
                            {{ file.name }}
                        </span>
                        <b-button
                            class="delete-button is-light"
                            rounded
                            @click="removeScreenshot(index)"
                        >
                            <b-icon class="file-icon" icon="delete"></b-icon>
                        </b-button>
                    </div>
                    <b-field class="file is-primary" multiple>
                        <b-upload
                            v-model="contactForm.screenshots"
                            class="file-upload"
                            rounded
                            multiple
                            outlined
                            accept=".jpg,.jpeg,.png,image/png,image/jpeg"
                        >
                            <span class="file-cta mt-1">
                                <span class="file-label has-text-weight-bold">
                                    {{
                                        $t(
                                            'global.helpcenter.contact_form.screenshot_label'
                                        )
                                    }}
                                </span>
                                <b-icon class="file-icon" icon="image"></b-icon>
                            </span>
                            <h5
                                :class="{
                                    'is-size-7': true,
                                    'has-text-centered': true,
                                    'mt-2': true,
                                    'has-text-danger': isWrongFormat,
                                }"
                            >
                                {{ t('global.helpcenter.contact_form.screenshot_info') }}
                            </h5>
                        </b-upload>
                    </b-field>
                </form>

                <span class="separator" />

                <b-button
                    type="is-primary"
                    class="has-text-weight-bold submit-btn"
                    :disabled="isFormSubmitDisabled"
                    @click="sendFeedback"
                >
                    {{ $t('global.helpcenter.contact_form.submit') }}
                </b-button>
            </div>
            <div v-if="isFeedbackSent" class="exit-modal">
                <button class="closeModalBttn" @click="hideContactModal">
                    <img src="@/assets/icons/bttn-close-black.svg" alt="" />
                </button>
                <h2
                    class="title is-size-4 has-text-weight-bold mgt-medium is-flex is-align-items-center pb-4"
                >
                    {{ $t('global.helpcenter.contact_form.feedback_title') }}
                </h2>
                <p>
                    {{ $t('global.helpcenter.contact_form.feedback_content') }}
                </p>
                <b-button
                    type="is-primary"
                    class="has-text-weight-bold mt-5"
                    @click="hideContactModal"
                >
                    {{ $t('global.helpcenter.contact_form.close') }}
                </b-button>
            </div>
        </b-modal>
    </div>
</template>
<script>
import utils from '@/shared/plugins/utils'
import axios from '@/shared/plugins/axios'
import { localeMixin } from '@/components/mixins/locale.js'
import { config } from '@/shared/assets/config/config'
import Question from './Question.vue'
import { mapGetters } from 'vuex'
export default {
    name: 'FAQ',
    components: {
        Question,
    },
    mixins: [localeMixin],
    data() {
        return {
            searchField: '',
            isContactModalActive: false,
            isFeedbackSent: false,

            contactForm: {
                subject: 0,
                module: '-',
                valuation_request_ref: '',
                otherSubject: '',
                content: '',
                screenshots: [],
            },

            userRequests: [],
        }
    },
    metaInfo() {
        return {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Support',
            // all titles will be injected into this template
            titleTemplate: '%s | Rock.estate Valuation App',
        }
    },
    computed: {
        ...mapGetters('auth', ['accessibleModules']),
        isFormSubmitDisabled() {
            return (
                this.isWrongFormat ||
                utils.isEmptyStr(this.contactForm.valuation_request_ref)
            )
        },
        shouldShowContact() {
            return this.$config.SUPPORT_CONTACT
        },
        items() {
            let filtered_config = config.filter((item) => {
                var needed =
                    item.functionality === 'faq' &&
                    item.modules.some((mod) => this.accessibleModules.includes(mod))
                if (needed && this.searchField && !utils.isEmptyStr(this.searchField)) {
                    needed =
                        this.$t(`faq.${item.name}_question`)
                            .toUpperCase()
                            .includes(this.searchField.toUpperCase()) ||
                        this.$t(`faq.${item.name}_answer`)
                            .toUpperCase()
                            .includes(this.searchField.toUpperCase())

                    item.shouldOpen = false

                    if (
                        needed &&
                        this.$t(`faq.${item.name}_answer`)
                            .toUpperCase()
                            .includes(this.searchField.toUpperCase())
                    ) {
                        item.shouldOpen = true
                    }
                    if (
                        needed &&
                        this.$t(`faq.${item.name}_question`)
                            .toUpperCase()
                            .includes(this.searchField.toUpperCase())
                    ) {
                        item.shouldOpen = false
                    }
                } else if (this.$route.hash.substring(1) === item.name) {
                    item.shouldOpen = true
                } else {
                    item.shouldOpen = false
                }
                return needed
            })

            // Sort by category if needed
            // filtered_config.sort((a, b) => {
            //     if (a.sections[0].toUpperCase() === b.sections[0].toUpperCase()) {
            //         return 0
            //     }

            //     if (
            //         this.t(`section`).hasOwnProperty(a.sections[0]) &&
            //         !this.t(`section`).hasOwnProperty(b.sections[0])
            //     ) {
            //         return 1
            //     }

            //     if (
            //         !this.t(`section`).hasOwnProperty(a.sections[0]) &&
            //         this.t(`section`).hasOwnProperty(b.sections[0])
            //     ) {
            //         return -1
            //     }

            //     return b.sections[0].toUpperCase() > a.sections[0].toUpperCase() ? -1 : 1
            // })
            const categorized = {}

            if (this.searchField && !utils.isEmptyStr(this.searchField)) {
                categorized['faq-search-results'] = [...filtered_config]
            } else {
                filtered_config.forEach((item) => {
                    if (!categorized.hasOwnProperty(item.sections[0])) {
                        categorized[item.sections[0]] = []
                    }

                    categorized[item.sections[0]].push(item)
                })
            }

            return categorized
        },
        isWrongFormat() {
            return (
                this.contactForm.screenshots.length > 0 &&
                !this.contactForm.screenshots.every((x) =>
                    ['image/png', 'image/jpeg'].includes(x.type)
                )
            )
        },
        subjects() {
            const selectedModule = this.contactForm.module
            if (selectedModule === 'ers') {
                return [
                    'req-failing',
                    'cant-download-val-report',
                    'req-not-in-dashboard',
                    'cancel-req',
                    'other',
                ]
            } else if (selectedModule === 'dvm') {
                return [
                    'req-not-eval-yet',
                    'req-back-to-draft',
                    'cant-download-val-report',
                    'req-not-in-dashboard',
                    'cancel-req',
                    'other',
                ]
            } else if (selectedModule === '-') {
                return [
                    'req-not-eval-yet',
                    'req-back-to-draft',
                    'cant-download-val-report',
                    'req-not-in-dashboard',
                    'cancel-req',
                    'other',
                ]
            }
            return []
        },
    },
    mounted() {
        this.getRequests()
        if (this.$route.query.from_module) {
            this.contactForm.module = this.$route.query.from_module
        }
    },
    methods: {
        display_address(el) {
            let a = utils.full_address(el.address, el.features)
            if (utils.via_address(el.address, el.features) !== '') {
                a = `${a} (via ${utils.via_address(el.address, el.features)})`
            }
            return a
        },
        showContactModal() {
            if (this.$route.query.valuation_request_ref) {
                this.contactForm.valuation_request_ref = this.$route.query.valuation_request_ref
            }
            this.isContactModalActive = true
            this.isFeedbackSent = false
        },
        hideContactModal() {
            this.isContactModalActive = false
            this.isFeedbackSent = false
        },
        removeScreenshot(index) {
            this.contactForm.screenshots.splice(index, 1)
        },
        resetContactForm() {
            this.contactForm = {
                subject: 0,
                valuation_request_ref: '',
                otherSubject: '',
                content: '',
                screenshots: [],
            }
        },
        getRequests() {
            this.$axios
                .get(utils.urlJoin(this.$config.VALUATION_API_URL, ['user', 'requests']))
                .then((res) => {
                    this.userRequests = res.data
                })
        },
        sendFeedback() {
            const {
                valuation_request_ref,
                module,
                otherSubject,
                content,
                screenshots,
            } = this.contactForm

            const subject = this.contactForm.subject

            // TODO : Replace below commented API call to the proper endpoint and
            // remove the lines above this comment block and below the commented
            // API call

            let formData = new FormData()
            formData.append('subject_tech', subject)
            if (subject === 'other') {
                formData.append('subject', otherSubject)
            } else {
                formData.append(
                    'subject',
                    this.$t(`global.helpcenter.contact_form.subject_${subject}`)
                )
            }
            formData.append('module', module)
            formData.append('valuation_request_ref', valuation_request_ref)
            formData.append(
                'url',
                JSON.stringify({
                    params: this.$route.params,
                    fullPath: this.$route.fullPath,
                    query: this.$route.query,
                    hash: this.$route.hash,
                    name: this.$route.name,
                })
            )
            formData.append('content', content)
            for (let i = 0; i < screenshots.length; i++) {
                formData.append(`screenshot_${i}`, screenshots[i])
            }

            axios
                .post(
                    utils.urlJoin(this.$config.VALUATION_API_URL, 'contact'),
                    formData,
                    { headers: { 'content-type': 'multipart/form-data' } }
                )
                .then((res) => {
                    this.isFeedbackSent = true
                    this.resetContactForm()
                })
        },
    },
}
</script>
<style lang="scss" scoped>
@import '@/shared/assets/style/buefy.scss';

.container {
    background: url('../../assets/icons/img-skyline.svg') no-repeat;
    background-position-y: bottom;
    background-position-x: center;
    height: calc(100vh - 53px);
    min-height: calc(100vh - 53px);
    width: 100%;
    overflow: scroll;
}

.faq-container {
    max-width: 800px;
    margin: auto auto;
}

.faq-content {
    border: 1px solid #d6e2e9;
    border-radius: 5px;
    box-shadow: 0px 2px 14px 0px #002f501a;
    background: white;
}

.contact-link {
    padding: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.contact-modal {
    .exit-modal {
        min-width: 490px;
        max-width: 800px;
        width: fit-content;
        padding: 30px;
        position: relative;
        max-height: 90vh;
        overflow-y: scroll;
        overflow-x: hidden;

        .closeModalBttn {
            all: unset;
            position: absolute;
            top: 30px;
            right: 30px;
            cursor: pointer;
        }
    }
}

.control .select {
    background: red;
}

.contact-form {
    display: flex;
    flex-direction: column;
    width: 100%;

    .field {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: stretch;
    }

    .screenshot-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.5rem;

        .file-icon {
            height: 37px;
            width: auto;
        }

        .screenshot-name {
            flex-grow: 1;
            color: $black;
        }

        .delete-button {
            height: 2.5rem;
            width: 2.5rem;
            background: #99a7cc33;
            color: #99a7cc99;
            padding: 0;

            &:hover {
                background: #99a7cc66;
                color: #99a7ccff;
            }
        }
    }

    .file-upload {
        display: flex;
        flex-direction: column;
        width: 100%;

        .file-cta {
            color: $primary !important;
            border-color: $primary !important;
            background: white !important;
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover {
                color: white !important;
                background: $primary !important;
            }

            .icon {
                margin-left: 10px;
            }
        }
    }
}

.submit-btn {
    width: 100%;
}

.separator {
    width: calc(100% + 60px);
    height: 1px;
    background: #e5eaed;
    display: inline-block;
    margin-top: 50px;
    margin-bottom: 30px;
    position: relative;
}
</style>

<style lang="scss">
.contact-modal {
    .control {
        .select,
        select {
            width: 100% !important;
        }
    }
}
</style>
