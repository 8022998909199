<template>
    <grid-card title="Reference points" no-padding>
        <template slot="header">
            <white-button @click="goToMap">
                <div class="map_buttn-content">
                    <span>Open map</span>
                    <svg-icon icon="map" height="16" scale="1" color="#001837" />
                </div>
            </white-button>
        </template>
        <template slot="default">
            <div class="map-container">
                <Map
                    v-if="isRequestLoaded"
                    :center="[getFeatures.f_lat, getFeatures.f_lng]"
                    :region="getFeatures.region"
                    :population="reference_listings"
                    :assessed="getFeatures"
                    :price="getAvmTransactionEstimations.asking_price_q50"
                    :prices="prices"
                    :price-per-sqm-array="pricePerSqmArray"
                    :address="getAddress"
                    @select="goToMap"
                />
                <b-skeleton
                    height="320px"
                    width="100%"
                    :rounded="false"
                    :active="!isRequestLoaded"
                />
            </div>
        </template>
    </grid-card>
</template>
<script>
import { mapGetters } from 'vuex'
import GridCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard'
import Map from '@/apps/Valuation/components/ValuerTool/elements/RefMapComponent.vue'
import WhiteButton from '@/apps/Valuation/components/ValuerTool/elements/WhiteButton.vue'

export default {
    name: 'RefPoints',
    components: {
        GridCard,
        Map,
        WhiteButton,
    },
    computed: {
        ...mapGetters('valuationStore/Address', [
            'getFeatures',
            'getAvmTransaction',
            'getAvmTransactionEstimations',
            'getAddress',
            'getDVMFeatures',
            'isRequestLoaded',
        ]),
        prices() {
            const array = this.getAvmTransaction.body.reference_listings.map(
                ({ prijs }) => prijs
            )
            array.sort((a, b) => a - b)
            return array
        },
        pricePerSqmArray() {
            const array = this.getAvmTransaction.body.reference_listings.map(
                ({ prijs, f_living_area }) => Math.round(prijs / f_living_area)
            )
            array.sort((a, b) => a - b)
            return array
        },
        reference_listings() {
            let list = [...this.getAvmTransaction.body.reference_listings]
            list.forEach((_, i) => {
                list[i].index = i
            })

            return list
        },
    },
    methods: {
        goToMap(targetListing = {}) {
            let params = {
                lang: this.$route.params.lang,
            }

            if (targetListing.hasOwnProperty('listing')) {
                params.listing = targetListing.listing
            }

            this.$router.push({
                name: 'valuation.valuer-app.ref_map',
                params,
                query: this.$route.query,
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.map_buttn-content {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    span {
        margin-right: 8px;
    }
}
.map-container {
    height: 320px;
    width: 100%;
    border-top: 1px solid #d6e2e9;
}
</style>
