<i18n>
{
  "nl-BE": {
    "logout": "Afmelden",
    "login": "Log in"
  },
  "en-BE": {
    "logout": "Logout",
    "login": "Log in"
  },
  "fr-BE": {
    "logout": "Déconnexion",
    "login": "S'identifier"
  }
}
</i18n>

<template>
    <b-navbar id="navbar" type="is-white" :class="{ 'is-fixed-top': fixed }">
        <!-- this can be link to main page -->
        <template slot="brand">
            <b-navbar-item
                tag="router-link"
                :to="
                    email
                        ? {
                              path: '/',
                          }
                        : { name: 'login', params: { lang: this.$i18n.locale } }
                "
            >
                <img
                    src="@/assets/icons/rockestate-logo-horizontal-dark.svg"
                    class="logo"
                    width="148"
                />
            </b-navbar-item>
        </template>

        <template v-if="showMenu" slot="start">
            <div v-if="isValuer && isDispatchDashboard" class="deployment-select mr-2">
                <b-select expanded :disabled="isLoading">
                    <option disabled>Deployment</option>
                </b-select>
            </div>
            <div
                v-if="isValuer"
                ref="reference_field"
                class="ref-search"
                :class="{
                    'apply-shake': shake,
                }"
            >
                <div class="ref-form">
                    <b-input
                        v-model="dvm_ref"
                        placeholder="Go to DVM (enter ref)"
                        :disabled="isLoading"
                    />
                    <b-button
                        class="ml-2"
                        type="is-primary"
                        :disabled="isDvmRefEmpty || isLoading"
                        @click="goToDvm(false)"
                    >
                        Go
                    </b-button>
                    <b-button
                        class="ml-2"
                        type="is-primary"
                        :disabled="isDvmRefEmpty || isLoading"
                        @click="goToDvm(true)"
                    >
                        Go (new tab)
                    </b-button>
                </div>
            </div>
        </template>

        <template slot="end">
            <b-dropdown
                v-if="accessibleModules.length > 1"
                class="new-request-dropdown"
                aria-role="list"
            >
                <template #trigger>
                    <b-button
                        :label="$t('dashboard.new_request.title')"
                        icon-right="plus"
                        size="is-small"
                    />
                </template>

                <b-dropdown-item
                    v-for="m in accessibleModules"
                    :key="`appToggle_${m}`"
                    aria-role="listitem"
                    class="new_request-dropdown-item"
                    @click="goTo(appMapping[m])"
                >
                    <svg-icon :icon="m" scale="1" />
                    {{ $t(`dashboard.type_toggles.${m}`) }}
                </b-dropdown-item>
            </b-dropdown>
            <b-navbar-item
                v-if="accessibleModules.length === 1"
                tag="router-link"
                :to="{
                    name: appMapping[accessibleModules[0]],
                }"
            >
                {{ $t('dashboard.new_request.title') }}
            </b-navbar-item>
            <b-dropdown
                v-if="hasRole('admin')"
                aria-role="list"
                :close-on-click="false"
                right
                paddingless
            >
                <template #trigger>
                    <b-navbar-item>
                        <b-icon icon="shield-account" />
                    </b-navbar-item>
                </template>

                <b-dropdown-item
                    aria-role="listitem"
                    paddingless
                    class="roles-dropdown"
                    :focusable="false"
                    custom
                >
                    <admin-roles
                        :roles="get_roles"
                        :editable="true"
                        :unremovable="['-:admin', '*:admin']"
                        class="role-widget"
                        @input="update_roles($event)"
                    ></admin-roles>
                </b-dropdown-item>
            </b-dropdown>

            <b-navbar-item tag="div">
                <div class="langs buttons has-addons my-0">
                    <b-button
                        size="is-small"
                        class="my-0"
                        :type="{
                            'is-primary': this.$i18n.locale === 'nl-BE',
                        }"
                        @click.native="changeLang('nl-BE')"
                    >
                        <span>NL</span>
                    </b-button>
                    <b-button
                        size="is-small"
                        class="my-0"
                        :type="{
                            'is-primary': this.$i18n.locale === 'fr-BE',
                        }"
                        @click.native="changeLang('fr-BE')"
                    >
                        <span>FR</span>
                    </b-button>
                    <b-button
                        size="is-small"
                        class="my-0"
                        :type="{
                            'is-primary': this.$i18n.locale === 'en-BE',
                        }"
                        @click.native="changeLang('en-BE')"
                    >
                        <span>EN</span>
                    </b-button>
                </div>
                <div class="mr-4 px-1 is-size-7 user" :class="[email ? 'ml-6' : 'ml-4']">
                    {{ email }}
                </div>
                <div class="buttons">
                    <a v-if="email != null" :href="logout_url">
                        <b-button size="is-small" type="is-primary" outlined>
                            <span>{{ $t('logout') }}</span>
                        </b-button>
                    </a>
                </div>
            </b-navbar-item>
        </template>
    </b-navbar>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import AdminRoles from '@/shared/components/AdminRoles.vue'

import utils from '@/shared/plugins/utils'
export default {
    name: 'Navbar',
    components: {
        AdminRoles,
    },
    props: {
        email: {
            type: String,
            default: null,
        },
        fixed: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        shake: false,
        dvm_ref: null,
        isLoading: false,
    }),
    computed: {
        ...mapGetters('auth', ['hasRole', 'accessibleModules']),
        logout_url: function() {
            return utils.urlJoin(this.$config.AUTH_API_URL, 'logout')
        },
        showMenu() {
            return !this.$config.CHECK_LOGIN || this.$store.state.auth.authenticated
        },
        get_roles() {
            return utils.rolesToList(this.$store.state.auth.roles)
        },
        isValuer() {
            return this.hasRole('valuer', 'dvm')
        },
        isDispatchDashboard() {
            return !!this.$config.DISPATCHED_DEPLOYMENTS?.length
        },
        isDvmRefEmpty() {
            return !this.dvm_ref || utils.isEmptyStr(this.dvm_ref)
        },
        newDVMEnabled() {
            return this.$config.ENABLE_NEW_DVM
        },
        requestTypeAppMapping() {
            return {
                ers: 'ers',
                valuation: 'dvm',
            }
        },
        appMapping() {
            return {
                ers: 'ers',
                dvm: 'valuation', // TODO: find a way for this to work when already on the valuation route
            }
        },
    },
    watch: {
        fixed() {
            document.body.classList.toggle('has-navbar-fixed-top', this.fixed)
        },
    },
    mounted() {
        try {
            this.$nextTick(() => {
                if (this.$refs.reference_field) {
                    this.$refs.reference_field.addEventListener('animationend', () => {
                        this.shake = false
                    })
                }
            })
        } catch (e) {
            console.log({ e })
        }
    },
    methods: {
        ...mapMutations(['valuationStore', 'RESET_VAL']),
        ...mapActions('auth', ['update_current_user']),

        changeLang(lang) {
            let params = this.$route.params
            params.lang = lang
            this.$router.push({
                name: this.$route.name,
                query: this.$route.query,
                params: params,
            })
        },
        goTo(app) {
            this.$router.push({ name: app })
        },
        update_roles(roles) {
            this.update_current_user({ roles: utils.listToRoles(roles) })
        },
        shakeAnimation() {
            this.shake = true
        },
        goToDvm(newTab = false) {
            if (
                this.$route.name === 'valuation.dvm' &&
                this.dvm_ref === this.$route.query.valuation_request_ref &&
                !newTab
            ) {
                this.shakeAnimation()
                return
            }

            let name = this.newDVMEnabled ? 'valuation.valuer-app' : 'valuation.dvm'

            this.isLoading = true
            this.$axios
                .get(
                    utils.urlJoin(this.$config.VALUATION_API_URL, [
                        'request',
                        this.dvm_ref,
                    ])
                )
                .then(() => {
                    if (newTab) {
                        const routeData = this.$router.resolve({
                            name,
                            query: {
                                valuation_request_ref: this.dvm_ref,
                            },
                        })
                        window.open(routeData.href, '_blank')
                        this.isLoading = false
                        return
                    }
                    this.$router.push({
                        name,
                        query: {
                            valuation_request_ref: this.dvm_ref,
                        },
                    })
                    this.isLoading = false
                })
                .catch((e) => {
                    this.isLoading = false
                    console.log({ e })
                    this.shakeAnimation()
                })
        },
    },
}
</script>

<style lang="scss">
.new-request-dropdown {
    margin-right: 1.5rem;
    .dropdown-trigger {
        margin: auto;
        button {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            border-radius: 0.3125rem;
            background: #009cff;
            border-color: #009cff;
            color: #fff;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }
    .dropdown-menu {
        top: 2.4rem;
    }
}
</style>

<style lang="scss" scoped>
.new_request_navbar {
    color: #001837;

    &:hover {
        background: #f2f2f2;
        color: #001837;
    }
}

// no need for separate file less load with scope
@keyframes shake {
    10%,
    90% {
        transform: translate3d(-2px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(4px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-8px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(8px, 0, 0);
    }
}

.apply-shake {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

#navbar {
    padding: 0 15px;
    border-bottom: 1px solid #d6e2e9;
}

.roles-dropdown {
    height: 100%;
    padding: 10px !important;
    min-width: 250px;
}

.ref-search {
    z-index: 99998;
    transition: all 0.3s ease-in-out;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

    .ref-form {
        display: flex;
        flex-direction: row;
    }
}

.deployment-select {
    display: flex;
    flex-direction: row;
    align-items: center;
}
</style>
