<i18n>
{
  "nl-BE": {
    "title": "Wat is het adres van het te financieren pand?",
    "tooltip_text": "Selecteer het adres van het pand dat wordt gefinancierd. Gelieve dit niet te verwarren met het adres van eventuele andere panden die gebruikt worden als extra zekerheid.",
    "control_address": "Vervolledig het adres indien nodig",
    "street": "Straat",
    "number": "Nummer",
    "box": "Bus",
    "postalcode": "Postcode",
    "municipality": "Stad",
    "destructive_safeguard": {
        "title": "Opgelet: indien u verdergaat met deze wijziging, wordt het input formulier gereset.",
        "content": "Bent u zeker dat u wilt verdergaan?",
        "cancel": "Annuleren",
        "confirm": "Bevestig"
    },
    "previous": "Vorige",
    "next": "Volgende",
    "field_required": "Dit veld is verplicht",
    "only_numbers": "Gelieve enkel cijfers in te vullen",
    "min_4_characters": "Dit veld vereist min. 4 karakters"
  },
  "en-BE": {
    "title": "What is the address of the property being financed?",
    "tooltip_text": "Select the address of the property that is being financed. Please do not confuse this with the address of possible other properties that are being used as additional collateral." ,
    "control_address": "Complete the address if needed",
    "street": "Street",
    "number": "Number",
    "box": "Box number",
    "postalcode": "Postal code",
    "municipality": "City",
    "destructive_safeguard": {
        "title": "Warning: this change will reset the rest of your input.",
        "content": "Are you sure you want to proceed with that change?",
        "cancel": "Cancel",
        "confirm": "Confirm"
    },
    "previous": "Previous",
    "next": "Next",
    "field_required": "This field is required",
    "only_numbers": "Please enter only numeric values",
    "min_4_characters": "Input should contain min. 4 characters"
  },
  "fr-BE": {
    "title": "Quelle est l'adresse du bien financé ?",
    "tooltip_text": "Sélectionnez l'adresse du bien financé. Merci de ne pas confondre ceci avec l'adresse d'autres bien éventuels qui peuvent servir comme sûreté.",
    "control_address": "Complétez l'adresse si nécessaire",
    "street": "Rue",
    "number": "Numéro",
    "box": "Boîte",
    "postalcode": "Code postal",
    "municipality": "Commune",
    "destructive_safeguard": {
        "title": "Attention : ce changement va réinitialiser le reste de votre demande.",
        "content": "Êtes-vous sûr de vouloir procéder à ce changement ?",
        "cancel": "Annuler",
        "confirm": "Confirmer"
    },
    "previous": "Précédent",
    "next": "Suivant",
    "field_required": "Ce champ est obligatoire",
    "only_numbers": "Veuillez entrer uniquement des chiffres",
    "min_4_characters": "Ce champ requiert 4 caractères au minimum"
  }
}
</i18n>

<template>
    <div class="container section address-section">
        <title-with-tooltip :label="$t('tooltip_text')">
            <template #title>{{ $t('title') }}</template>
        </title-with-tooltip>
        <div class="columns is-mobile is-multiline pt-5 pb-1">
            <div class="column is-10-tablet is-12-mobile">
                <auto-complete
                    :placeholder="placeholder"
                    :read-only="!isDraft"
                    :search-mode="searchMode"
                    @selectedItem="setAddress($event)"
                />
            </div>
            <div
                v-if="show_structured_address"
                class="column is-10-tablet is-12-mobile pt-3 "
            >
                <h3 class="has-text-weight-medium has-text-black pt-5 mt-1">
                    {{ $t('control_address') }}
                </h3>
            </div>

            <div
                v-if="show_structured_address"
                id="extra"
                class="column is-10-tablet is-12-mobile "
            >
                <!-- streetname input section -->
                <div class="columns">
                    <div class="column is-12">
                        <b-field
                            class="mt-1"
                            :label="$t('street')"
                            expanded
                            :type="{ 'is-danger': $v.addressInfo.streetname.$error }"
                            :message="[
                                !$v.addressInfo.streetname.required &&
                                $v.addressInfo.streetname.$error
                                    ? $t('field_required')
                                    : null,
                            ]"
                        >
                            <b-input
                                v-model="streetname"
                                size="is-medium"
                                placeholder="Kleine Schuurstraat"
                                :disabled="!isDraft"
                                @blur="$v.addressInfo.streetname.$touch()"
                                @input="$v.addressInfo.streetname.$touch()"
                            ></b-input>
                        </b-field>
                    </div>
                </div>
                <div class="columns is-variable is-multiline is-6">
                    <!-- streetnumber input section -->
                    <div class="column is-6">
                        <b-field
                            :label="$t('number')"
                            expanded
                            :type="{
                                'is-danger': $v.addressInfo.streetnumber.$error,
                            }"
                            :message="[
                                !$v.addressInfo.streetnumber.required &&
                                $v.addressInfo.streetnumber.$error
                                    ? $t('field_required')
                                    : null,
                            ]"
                        >
                            <b-input
                                v-model="streetnumber"
                                size="is-medium"
                                placeholder="8"
                                :disabled="!isDraft"
                                @blur="$v.addressInfo.streetnumber.$touch()"
                                @input="$v.addressInfo.streetnumber.$touch()"
                            ></b-input>
                        </b-field>
                    </div>
                    <!-- box number input section -->
                    <div class="column is-6">
                        <b-field :label="$t('box')" expanded>
                            <b-input
                                v-model="boxnumber"
                                size="is-medium"
                                placeholder="123"
                                :disabled="!isDraft"
                            ></b-input>
                        </b-field>
                    </div>
                    <!-- postalcode input section -->
                    <div class="column is-6">
                        <b-field
                            :label="$t('postalcode')"
                            expanded
                            :type="{
                                'is-danger': $v.addressInfo.postalcode.$error,
                            }"
                            :message="[
                                $v.addressInfo.postalcode.$error &&
                                !$v.addressInfo.postalcode.required
                                    ? $t('field_required')
                                    : null,
                                $v.addressInfo.postalcode.$error &&
                                !$v.addressInfo.postalcode.minLength
                                    ? $t('min_4_characters')
                                    : null,
                                $v.addressInfo.postalcode.$error &&
                                !$v.addressInfo.postalcode.numeric
                                    ? $t('only_numbers')
                                    : null,
                            ]"
                        >
                            <b-input
                                v-model="postalcode"
                                size="is-medium"
                                placeholder="0960"
                                :disabled="!isDraft"
                                @blur="$v.addressInfo.postalcode.$touch()"
                                @input="$v.addressInfo.postalcode.$touch()"
                            ></b-input>
                        </b-field>
                    </div>
                    <!-- municipality input section -->
                    <div class="column is-6">
                        <b-field
                            :label="$t('municipality')"
                            expanded
                            :type="{
                                'is-danger': $v.addressInfo.municipality.$error,
                            }"
                            :message="[
                                !$v.addressInfo.municipality.required &&
                                $v.addressInfo.municipality.$error
                                    ? $t('field_required')
                                    : null,
                            ]"
                        >
                            <b-input
                                v-model="municipality"
                                size="is-medium"
                                placeholder="Gent"
                                :disabled="!isDraft"
                                @blur="$v.addressInfo.municipality.$touch()"
                                @input="$v.addressInfo.municipality.$touch()"
                            ></b-input>
                        </b-field>
                    </div>
                </div>
            </div>
            <div class="column is-10-tablet is-12-mobile mt-5">
                <div class="is-flex is-justify-content-space-between">
                    <b-button
                        size="is-medium"
                        type="is-dark"
                        icon-left="arrow-left"
                        @click="prevPage"
                    >
                        <span>{{ $t('previous') }}</span>
                    </b-button>
                    <b-button
                        v-if="show_next"
                        :disabled="goNextDisabled || $store.state.loading"
                        size="is-medium"
                        type="is-primary"
                        icon-right="arrow-right"
                        :loading="$store.state.loading"
                        @click="nextPage(false)"
                    >
                        <span>{{ $t('next') }}</span>
                    </b-button>
                </div>
            </div>
        </div>
        <b-modal
            v-model="isDestructiveSave"
            trap-focus
            has-modal-card
            @close="closeDestructiveSaveModal"
        >
            <div class="card modal-card p-5 destructive-save-modal">
                <h4 class="is-size-5 has-text-weight-bold has-text-black">
                    {{ $t('destructive_safeguard.title') }}
                </h4>
                <p>
                    {{ $t('destructive_safeguard.content') }}
                </p>
                <div class="btn-group">
                    <b-button @click="closeDestructiveSaveModal">
                        {{ $t('destructive_safeguard.cancel') }}
                    </b-button>
                    <b-button type="is-primary" @click="nextPage(true)">
                        {{ $t('destructive_safeguard.confirm') }}
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { required, numeric, minLength } from 'vuelidate/lib/validators'
import AutoComplete from '@/shared/components/AutoComplete'
import { mapMutations, mapGetters, mapActions } from 'vuex'
import utils from '@/shared/plugins/utils'

export default {
    components: {
        AutoComplete,
    },
    props: {
        buildingType: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            isDestructiveSave: false,
            forceValueSet: false,
            prevAddress: null,
            result_type: null,
        }
    },
    validations: {
        addressInfo: {
            streetname: {
                required,
            },
            postalcode: {
                required,
                numeric,
                minLength: minLength(4),
            },
            streetnumber: {
                required,
            },
            municipality: {
                required,
            },
        },
    },
    computed: {
        ...mapGetters('valuationStore', ['isDraft']),
        ...mapGetters('valuationStore/Address', [
            'getAddress',
            'getFeatures',
            'isExtraInfoEdited',
            'get_map_scene2d',
        ]),
        show_next() {
            return (
                this.result_type !== null ||
                utils.full_address(this.getAddress, this.getFeatures)
            )
        },
        show_structured_address() {
            return (
                this.result_type === 'not_found' ||
                this.result_type === 'address' ||
                utils.full_address(this.getAddress)
            )
        },
        streetname: {
            get() {
                return this.$store.state.valuationStore.Address.address.streetname
            },
            set(value) {
                this.SET_ADDRESS({ streetname: value })
            },
        },
        streetnumber: {
            get() {
                return this.$store.state.valuationStore.Address.address.streetnumber
            },
            set(value) {
                this.SET_ADDRESS({ streetnumber: value })
            },
        },
        boxnumber: {
            get() {
                return this.$store.state.valuationStore.Address.address.boxnumber
            },
            set(value) {
                this.SET_ADDRESS({ boxnumber: value })
            },
        },
        postalcode: {
            get() {
                return this.$store.state.valuationStore.Address.address.postalcode
            },
            set(value) {
                this.SET_ADDRESS({ postalcode: value })
            },
        },
        municipality: {
            get() {
                return this.$store.state.valuationStore.Address.address.municipality
            },
            set(value) {
                this.SET_ADDRESS({ municipality: value })
            },
        },
        addressInfo() {
            return this.$store.state.valuationStore.Address.address
        },
        goNextDisabled() {
            // Prevent people from going forward without a valid address or parcel
            if (!this.isDraft) {
                return false
            }

            if (this.show_structured_address && this.$v.addressInfo.$invalid) {
                return true
            }

            if (
                !this.searchMode === 'address' &&
                this.getFeatures.parcel_ids.length === 0
            ) {
                return true
            }
            return false
        },
        hasAddress() {
            return ['house', 'apartment'].includes(this.buildingType)
        },
        searchMode() {
            if (['house', 'apartment'].includes(this.buildingType)) {
                return 'address'
            } else {
                return 'dual'
            }
        },
        placeholder() {
            return utils.full_address(this.getAddress, this.getFeatures)
        },
    },
    mounted() {
        if (this.isExtraInfoEdited) {
            if (this.searchMode === 'address') {
                this.prevAddress = {
                    ...this.$store.state.valuationStore.Address.address,
                    type: 'address',
                }
            } else {
                this.prevAddress = {
                    parcel_name: this.$store.state.valuationStore.Address.features
                        .parcel_ids[0],
                    type: 'parcel',
                }
            }
        }
        this.UPDATE_STATUS({ loading: false })
    },
    methods: {
        ...mapMutations('valuationStore', ['UPDATE_STATUS']),
        ...mapMutations('valuationStore/Address', [
            'SET_ADDRESS',
            'SET_FEATURES',
            'SET_OTHER_DATA',
            'RESET_FEATURES',
        ]),
        ...mapActions('valuationStore/Address', [
            'match_address',
            'fetch_map_data',
            'fetch_map_data_parcel',
            'auto_select_parcels',
        ]),
        closeDestructiveSaveModal() {
            this.isDestructiveSave = false
            this.setAddress(this.prevAddress)
        },
        setAddress(val) {
            if (val === null || utils.isEmptyObject(val)) {
                this.result_type = null
                this.SET_ADDRESS({
                    streetname: null,
                    streetnumber: null,
                    postalcode: null,
                    municipality: null,
                    boxnumber: null,
                    type: null,
                })
                this.SET_FEATURES({ parcel_ids: [], level: null })
                return
            }
            this.result_type = val.type
            if (val.type === 'address') {
                this.SET_ADDRESS(val)
                this.SET_FEATURES({ parcel_ids: [], level: null })
            } else if (val.type === 'parcel') {
                this.SET_ADDRESS({
                    streetname: null,
                    streetnumber: null,
                    postalcode: null,
                    municipality: null,
                    boxnumber: null,
                    type: 'parcel',
                })
                this.SET_FEATURES({
                    parcel_ids: [val.parcel_id],
                    level: '1',
                })
            } else {
            }
        },
        prevPage() {
            if (this.$v.$anyDirty) {
                this.setAddress(this.prevAddress)
            }
            this.$router.push({
                name: 'valuation.request',
                params: {
                    lang: this.$route.params.lang,
                    action: 'building-type',
                },
                query: this.$route.query,
            })
        },
        nextPage(force = false) {
            if (!this.isDraft || (this.isExtraInfoEdited && !this.$v.$anyDirty)) {
                this.$router.push({
                    name: 'valuation.request',
                    params: {
                        lang: this.$route.params.lang,
                        action: 'view-building-parcels',
                    },
                    query: this.$route.query,
                })
            } else if (!force && this.isExtraInfoEdited && this.$v.$anyDirty) {
                this.isDestructiveSave = true
            } else {
                this.isDestructiveSave = false
                if (this.isExtraInfoEdited) {
                    this.RESET_FEATURES()
                }
                if (this.show_structured_address) {
                    const body = {
                        address: {
                            streetname: this.$store.state.valuationStore.Address.address
                                .streetname,
                            streetnumber: this.$store.state.valuationStore.Address.address
                                .streetnumber,
                            postalcode: this.$store.state.valuationStore.Address.address
                                .postalcode,
                            municipality: this.$store.state.valuationStore.Address.address
                                .municipality,
                            boxnumber: this.$store.state.valuationStore.Address.address
                                .boxnumber,
                        },
                        other_data: {
                            extra_info_edited: false,
                        },
                    }
                    this.UPDATE_STATUS({ loading: true })
                    this.match_address()
                        .then(() => {
                            if (!this.get_map_scene2d.address_geojson) {
                                this.fetch_map_data()
                            }
                        })
                        .then(this.auto_select_parcels)
                        .finally(() => {
                            if (!this.hasAddress) {
                                body.features = {
                                    parcel_ids: this.$store.state.valuationStore.Address
                                        .features.parcel_ids,
                                    level: this.$store.state.valuationStore.Address
                                        .features.level,
                                }
                            }
                            this.UPDATE_STATUS({ loading: false })
                            this.$axios.patch(
                                utils
                                    .val_urls(this.$config)
                                    .request_ref(
                                        this.$store.state.valuationStore
                                            .valuation_request_ref
                                    ),
                                body
                            )
                            this.SET_OTHER_DATA({ extra_info_edited: false })
                            let next_action = 'confirm-main-building'
                            if (this.searchMode === 'dual') {
                                next_action =
                                    this.$store.state.valuationStore.Address.features
                                        .parcel_ids.length === 1
                                        ? 'confirm-main-parcel'
                                        : 'select-main-parcel'
                                this.$store.state.valuationStore.Address.features.building_id = null
                            } else if (this.getFeatures.level < '2') {
                                next_action = 'select-main-building'
                            }
                            this.$router.push({
                                name: 'valuation.request',
                                params: {
                                    lang: this.$route.params.lang,
                                    action: next_action,
                                },
                                query: this.$route.query,
                            })
                        })
                } else {
                    this.UPDATE_STATUS({ loading: true })
                    this.fetch_map_data_parcel().finally(() => {
                        this.UPDATE_STATUS({ loading: false })
                        this.$axios.patch(
                            utils
                                .val_urls(this.$config)
                                .request_ref(
                                    this.$store.state.valuationStore.valuation_request_ref
                                ),
                            {
                                address: {
                                    streetname: null,
                                    streetnumber: null,
                                    postalcode: null,
                                    municipality: null,
                                    boxnumber: null,
                                },
                                features: {
                                    parcel_ids: this.$store.state.valuationStore.Address
                                        .features.parcel_ids,
                                    level: this.$store.state.valuationStore.Address
                                        .features.level,
                                },
                                other_data: {
                                    extra_info_edited: false,
                                },
                            }
                        )
                        this.SET_OTHER_DATA({ extra_info_edited: false })
                        this.$router.push({
                            name: 'valuation.request',
                            params: {
                                lang: this.$route.params.lang,
                                action:
                                    this.$store.state.valuationStore.Address.features
                                        .parcel_ids.length === 1
                                        ? 'confirm-main-parcel'
                                        : 'select-main-parcel',
                            },
                            query: this.$route.query,
                        })
                    })
                }
            }
        },
    },
}
</script>

<style lang="scss">
.address-section {
    h3 {
        font-size: 18px;
        line-height: 22px;
    }
    .label {
        font-size: 14px;
    }
    .field.is-grouped .field:not(:last-child) {
        margin-right: 40px;
    }
}

.destructive-save-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .btn-group {
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }
}
</style>
