<template>
    <grid-card>
        <label class="historical_card single_line_card">
            <h5>Is the building listed for historical purposes?</h5>
            <b-checkbox v-model="f_building_listed" />
        </label>
    </grid-card>
</template>
<script>
import GridCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard'
import { mapGetters, mapMutations } from 'vuex'
export default {
    name: 'Historical',
    components: {
        GridCard,
    },
    data: () => ({
        f_building_listed: false,
    }),
    computed: {
        ...mapGetters('valuationStore/Address', ['getDVMFeatures']),
    },
    watch: {
        // Debounce everything to not trigger updates on every keystroke
        f_building_listed(val) {
            this.SET_DVM_FEATURES({
                f_building_listed: val,
            })
        },
    },
    mounted() {
        this.f_building_listed = this.getDVMFeatures?.f_building_listed || false
    },
    methods: {
        ...mapMutations('valuationStore/Address', ['SET_DVM_FEATURES']),
    },
}
</script>
