<template>
    <div class="down-page">
        <div class="skyline" />
        <h1 class="has-text-black has-text-weight-bold is-size-3 mb-5">
            {{ $t('common.we_are_sorry') }}
        </h1>
        <p class="is-size-5 mb-3">
            {{ $t('common.maintenance') }}
        </p>
    </div>
</template>
<script>
export default {
    name: 'Downtime',
}
</script>
<style lang="scss" scoped>
.down-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #f9fafc;
    background-size: cover;
    width: 100vw;
    min-height: 100vh;

    & > *:not(.skyline) {
        z-index: 1;
        max-width: 60%;
    }
}
</style>
