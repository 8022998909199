<i18n>
{
  "nl-BE": {
    "house": "Een huis",
    "house_comment": "Gebouw met één enkele woonentiteit",
    "apartment": "Een appartement",
    "apartment_comment": "Een woonentiteit die deel uitmaakt van een groter gebouw",
    "new_house": "Nieuwbouw woning",
    "new_house_comment": "Nieuwbouw woning",
    "new_apartment": "Nieuwbouw appartement",
    "new_apartment_comment": "Nieuwbouw appartement",
    "construction_plot": "Bouwgrond",
    "construction_plot_comment": "Bouwgrond",
    "previous": "Vorige",
    "next": "Volgende"
  },
  "en-BE": {
    "house": "A house",
    "house_comment": "Building with one single living entity",
    "apartment": "An apartment",
    "apartment_comment": "A living entity part of a larger building",
    "new_house": "New house",
    "new_house_comment": "New house",
    "new_apartment": "New apartment",
    "new_apartment_comment": "New apartment",
    "construction_plot": "A construction plot",
    "construction_plot_comment": "A construction plot",
    "previous": "Previous",
    "next": "Next"
  },
  "fr-BE": {
    "house": "Une maison",
    "house_comment": "Bâtiment comptant une seule entité",
    "apartment": "Un appartement",
    "apartment_comment": "Une entité qui fait partie d'un bâtiment plus large",
    "new_house": "Maison neuve",
    "new_house_comment": "Maison neuve",
    "new_apartment": "Appartement neuf",
    "new_apartment_comment": "Appartement neuf",
    "construction_plot": "Terrain à bâtir",
    "construction_plot_comment": "Terrain à bâtir",
    "previous": "Précédent",
    "next": "Suivant"
  }
}
</i18n>

<template>
    <div
        class="is-flex choose-type-group"
        :class="[
            $vssWidth <= 1123 && 'is-flex-direction-column is-justify-content-center',
        ]"
    >
        <transition name="fade-opacity" :duration="{ leave: 0 }">
            <div
                v-if="allowedBuildingTypes.includes('house')"
                ref="box"
                :class="{
                    'choose-type-box': true,
                    'not-choosen': value !== 'house',
                    chosen: value === 'house',
                    disable: !isDraft,
                }"
                @click="selectType('house')"
            >
                <div class="type-icon home">
                    <svg-icon icon="home" hight="50" scale="1.4" color="#798DA6" />
                </div>

                <div class="type-text">
                    <div class="type-text-title has-text-weight-bold">
                        {{ $t('house') }}
                    </div>
                    <div class="type-text-text">
                        {{ $t('house_comment') }}
                    </div>
                </div>
            </div>
        </transition>
        <transition name="fade-opacity" :duration="{ leave: 0 }">
            <div
                v-if="allowedBuildingTypes.includes('apartment')"
                class="choose-type-box"
                :class="{
                    chosen: value === 'apartment',
                    'not-choosen': value !== 'apartment',
                    disable: !isDraft,
                }"
                @click="selectType('apartment')"
            >
                <div class="type-icon apartment">
                    <svg-icon icon="apartment" hight="50" scale="1.4" color="#798DA6" />
                </div>
                <div class="type-text">
                    <div class="type-text-title has-text-weight-bold">
                        {{ $t('apartment') }}
                    </div>
                    <div class="type-text-text">
                        {{ $t('apartment_comment') }}
                    </div>
                </div>
            </div>
        </transition>
        <transition name="fade-opacity" :duration="{ leave: 0 }">
            <div
                v-if="allowedBuildingTypes.includes('new_house')"
                class="choose-type-box"
                :class="{
                    chosen: value === 'new_house',
                    'not-choosen': value !== 'new_house',
                    disable: !isDraft,
                }"
                @click="selectType('new_house')"
            >
                <div class="type-icon with-stroke">
                    <svg-icon
                        icon="new-construction"
                        hight="50"
                        scale="1.4"
                        color="#798DA6"
                        :fill="false"
                    />
                </div>
                <div class="type-text">
                    <div class="type-text-title has-text-weight-bold">
                        {{ $t('new_house') }}
                    </div>
                    <div class="type-text-text">
                        {{ $t('new_house_comment') }}
                    </div>
                </div>
            </div>
        </transition>
        <transition name="fade-opacity" :duration="{ leave: 0 }">
            <div
                v-if="allowedBuildingTypes.includes('new_apartment')"
                class="choose-type-box"
                :class="{
                    chosen: value === 'new_apartment',
                    'not-choosen': value !== 'new_apartment',
                    disable: !isDraft,
                }"
                @click="selectType('new_apartment')"
            >
                <div class="type-icon with-stroke">
                    <svg-icon
                        icon="new-construction"
                        hight="50"
                        scale="1.4"
                        color="#798DA6"
                        :fill="false"
                    />
                </div>
                <div class="type-text">
                    <div class="type-text-title has-text-weight-bold">
                        {{ $t('new_apartment') }}
                    </div>
                    <div class="type-text-text">
                        {{ $t('new_apartment_comment') }}
                    </div>
                </div>
            </div>
        </transition>
        <transition name="fade-opacity" :duration="{ leave: 0 }">
            <div
                v-if="allowedBuildingTypes.includes('construction_plot')"
                class="choose-type-box"
                :class="{
                    chosen: value === 'construction_plot',
                    'not-choosen': value !== 'construction_plot',
                    disable: !isDraft,
                }"
                @click="selectType('construction_plot')"
            >
                <div class="type-icon">
                    <svg-icon icon="apartment" hight="50" scale="1.4" color="#798DA6" />
                </div>
                <div class="type-text">
                    <div class="type-text-title has-text-weight-bold">
                        {{ $t('construction_plot') }}
                    </div>
                    <div class="type-text-text">
                        {{ $t('construction_plot_comment') }}
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'BuildingType',
    props: {
        value: {
            type: String,
            default: null,
        },
        allowedBuildingTypes: {
            type: Array,
            default: () => {
                return ['house', 'apartment']
            },
        },
    },
    data() {
        return {
            // leftIcon: '#798DA6',
            // rightIcon: '#798DA6',
            // activeIcon: '',
        }
    },
    computed: {
        ...mapGetters('valuationStore', ['isDraft']),
        ...mapGetters('valuationStore/Address', ['getFeatures']),
    },
    // mounted() {
    //     if (this.getFeatures.f_building_type === 'apartment') {
    //         this.rightIcon = ' #fff'
    //     } else if (this.getFeatures.f_building_type === 'house') {
    //         this.leftIcon = ' #fff'
    //     } else {
    //         this.rightIcon = ' #798DA6'
    //         this.leftIcon = '#798DA6'
    //     }
    // },
    methods: {
        selectType(val) {
            this.$emit('update:buildingType', val)
            // this.activeIcon = val
        },
        // leaveLeft() {
        //     if (this.getFeatures.f_building_type === 'house') {
        //         this.leftIcon = '#fff'
        //     } else {
        //         this.leftIcon = '#798DA6'
        //     }
        // },
        // leaveRight() {
        //     if (this.getFeatures.f_building_type === 'apartment') {
        //         this.rightIcon = '#fff'
        //     } else {
        //         this.rightIcon = '#798DA6'
        //     }
        // },
        // enterLeft() {
        //     this.leftIcon = '#fff'
        // },
        // enterRight() {
        //     this.rightIcon = '#fff'
        // },
    },
}
</script>

<style lang="scss">
@import '@/shared/assets/style/buefy.scss';
.choose-type-group {
    width: 100%;
    transition: 0.5s all;
    flex-wrap: wrap;
    gap: 1rem;
}
.choose-type-box {
    min-width: 360px;
    width: 34%;
    height: 140px;
    border-radius: 5px;
    box-shadow: 0 2px 14px 0 rgba(0, 47, 80, 0.1);
    border: solid 1px #d6e2e9;
    background-color: #ffffff;
    box-sizing: border-box;
    display: block;
    align-items: center;
    cursor: pointer;
    transition: 0.4s all;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 40px;
    padding-right: 40px;
    // &:first-child {
    //     margin-right: 40px;
    // }
    &.disable {
        pointer-events: none !important;
    }

    .type-icon {
        width: 80px;
        height: 80px;
        border: solid 1px rgba(78, 94, 114, 0.2);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        flex-shrink: 0;
        align-items: center;
        transition: 0.3s all;
        float: left;
        svg {
            transition: 0.3s all;
        }
        background-color: rgba(78, 94, 114, 0.1);
        &.with-stroke {
            stroke-dasharray: 3 1;
            svg {
                scale: 1.3;
            }
        }
    }

    .type-text {
        float: left;
        margin-left: 30px;
        .type-text-title {
            font-size: 18px;
            color: #001837;
            margin-bottom: 8px;
        }
        .type-text-text {
            width: 190px;
            font-size: 16px;
            line-height: 1.31;
            color: #798da6;
        }
    }

    &:hover,
    &.chosen {
        //border-color: $blue-primary;
        border: 1px solid #009cff;
        .type-icon {
            background-color: $primary;
            path {
                fill: #fff;
            }
            &.with-stroke {
                path {
                    fill: none;
                    stroke: #fff;
                }
            }
        }
        .type-text {
            .type-text-title {
                color: $primary;
            }
        }
    }
    &.not-choosen {
        opacity: 0.5;
        &:hover {
            opacity: 1;
        }
    }
}
@media screen and (max-width: 1123px) {
    .choose-type-box {
        width: 100%;
        max-width: 630px;
        padding-left: 30px;
        padding-right: 30px;
        .type-icon {
            margin-right: 20px;
        }
    }
}
</style>
