<i18n>
{
    "en-BE": {
        "assessed": "Assessed",
        "reference": "Reference",
        "listing_date": "Listing date",
        "touching_sides": "Touching sides",
        "distance": "Distance",
        "price": "Price",
        "price_sqm": "Price/sqm",
        "streetnumber": "Streetnumber",
        "use_as_reference": "Use as reference",
        "add_to_remarks": "Add to remarks"
    },
    "en-FR": {
        "assessed": "Assessed",
        "reference": "Reference",
        "listing_date": "Listing date",
        "touching_sides": "Touching sides",
        "distance": "Distance",
        "price": "Price",
        "price_sqm": "Price/sqm",
        "streetnumber": "Streetnumber",
        "use_as_reference": "Use as reference",
        "add_to_remarks": "Add to remarks"
    },
    "en-NL": {
        "assessed": "Assessed",
        "reference": "Reference",
        "listing_date": "Listing date",
        "touching_sides": "Touching sides",
        "distance": "Distance",
        "price": "Price",
        "price_sqm": "Price/sqm",
        "streetnumber": "Streetnumber",
        "use_as_reference": "Use as reference",
        "add_to_remarks": "Add to remarks"
    }
}
</i18n>
<template>
    <div style="height: 100%; width: 100%;" class="ref_map">
        <l-map
            ref="map"
            :center="center"
            style="height: 100%; width: 100%; border-radius: 0 0 8px 8px;"
            :max-zoom="18"
            :min-zoom="11"
            :zoom="17"
        >
            <l-tile-layer
                :url="aerialTileUrl"
                name="Aerial view"
                layer-type="base"
            ></l-tile-layer>
            <l-marker
                :lat-lng="center"
                :icon="propertyIcon"
                :options="{
                    riseOnHover: true,
                    riseOffset: -5000,
                }"
            />
            <l-marker
                v-for="building in population"
                :key="'building_' + building.index"
                :lat-lng="[building.lat, building.lng]"
                :icon="markerIcon(building)"
                @click="$emit('select', { listing: building, src: 'map' })"
            >
                <l-tooltip
                    :options="{
                        className: `building_listing_${building.index}${
                            building.index === selectedListing ? ' selected' : ''
                        }`,
                        permanent: true,
                        direction: 'top',
                        opacity: 1,
                    }"
                >
                    {{ Math.round(building.price_corr / 1000) }}K
                </l-tooltip>
            </l-marker>
        </l-map>
        <b-modal
            :active="!!viewedDescription"
            :on-cancel="resetDescription"
            width="640px"
        >
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Listing description</p>
                    <button type="button" class="delete" @click="resetDescription" />
                </header>
                <section class="modal-card-body">
                    {{ viewedDescription }}
                </section>
                <footer class="modal-card-foot">
                    <div />
                </footer>
            </div>
        </b-modal>
    </div>
</template>
<script>
import { LMap, LMarker, LTooltip } from 'vue2-leaflet'
import L from 'leaflet'
import * as d3 from 'd3'
import map_pin from '../scripts/map_pin'
import utils from '@/shared/plugins/utils'

export default {
    name: 'Map',
    components: {
        LMap,
        LMarker,
        LTooltip,
    },
    props: {
        center: {
            type: Array,
            required: true,
        },
        region: {
            type: String,
            required: true,
        },
        assessed: {
            type: Object,
            required: true,
        },
        price: {
            type: Number,
            required: true,
        },
        population: {
            type: Array,
            default: () => [],
        },
        prices: {
            type: Array,
            default: () => [],
        },
        pricePerSqmArray: {
            type: Array,
            default: () => [],
        },
        address: {
            type: Object,
            required: true,
        },
        interactive: {
            type: Boolean,
            default: false,
        },
        selectedListing: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            viewedDescription: null,
        }
    },
    computed: {
        aerialTileUrl() {
            return 'https://tile.openstreetmap.org/{z}/{x}/{y}.png'
        },
        referenceDate() {
            return this.$config.ESTIM_DATA_SCHEMA_DATE
        },
        propertyIcon() {
            return map_pin()
        },
        building_type() {
            return this.assessed.f_building_type
        },
        is_building() {
            return utils.is_building(this.building_type)
        },
        is_apartment() {
            return utils.is_apartment(this.building_type)
        },
        is_house() {
            return utils.is_house(this.building_type)
        },
    },
    watch: {
        selectedListing(val, prevVal) {
            if (Number.isInteger(prevVal)) {
                document
                    .getElementsByClassName(`building_listing_${prevVal}`)[0]
                    .classList.toggle('selected')
            }
            if (Number.isInteger(val)) {
                document
                    .getElementsByClassName(`building_listing_${val}`)[0]
                    .classList.toggle('selected')
            }
        },
    },
    methods: {
        viewDescription(descr) {
            this.viewedDescription = descr
        },
        resetDescription() {
            this.viewedDescription = null
        },
        listingDate(days) {
            const refDate = new Date(this.referenceDate)
            refDate.setDate(refDate.getDate() + days)
            return refDate.toISOString().split('T')[0]
        },
        googleMaps([lat, lng]) {
            return `https://www.google.com/maps?q=${lat},${lng}&z=19`
        },
        nearbyListings(ref) {
            const loc = L.latLng([ref.lat, ref.lng])
            const listings = this.population.filter(
                (listing) => loc.distanceTo([listing.lat, listing.lng]) <= 3
            )
            return listings
        },
        markerIcon(ref) {
            return L.divIcon({
                html: `<svg viewBox="0 0 40 35"><rect width="40" height="35" fill="red" fill-opacity="0"/></svg>`,
                iconSize: [40, 35],
                iconAnchor: [20, 35],
            })
        },
        addListingToRemarks(ref) {
            let content = 'Reference Listing: '
            content += ref.streetname
            content += ' '
            content += ref.streetnumber
            content += ' - '
            content += ref.postalcode
            content += ' '
            content += ref.municipality
            content += ' | EPC: '
            content += ref.f_epc
            content += ' | Listing date: '
            content += this.listingDate(ref.toegevoegd_num)
            content += ' | '
            content += Math.round(ref.price_corr)
            content += ' € | '
            content += Math.round(
                ref.price_corr /
                    (this.is_building ? ref.f_living_area : ref.f_parcel_area)
            )
            content += ' €/sqm\n'

            this.$emit('updateRemarks', content)
        },
    },
}
</script>
<style lang="scss">
.ref_map {
    .leaflet-tooltip {
        text-transform: none;
        font-size: 10px;
        color: #fff;
        background: #101828;
        font-weight: 500;
        padding: 2px 6px;
        line-height: 1.5rem;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        max-width: 200px;
        pointer-events: none;
        border-radius: 4px;
        z-index: 99;
        border: none;

        &:before {
            border-color: #101828;
            z-index: 1;
            content: '';
            position: absolute;
            pointer-events: none;
            bottom: 0;
            left: 50%;
            transform: translate3d(0, -7px, 0) rotate(45deg);
            transform-origin: center;
            width: 7px;
            height: 7px;
            border-radius: 100% 0 3px 0;
            background: #101828;
        }

        &.selected {
            background: #0096ff;
            z-index: 9999;
            font-size: 12px;

            &:before {
                border-color: #0096ff;
                background: #0096ff;
            }
        }
    }
}

.leaflet-popup {
    max-width: 750px;
}

.leaflet-popup-content-wrapper {
    width: fit-content;
    max-width: 100%;
}
.leaflet-popup-content {
    width: auto !important;
    max-width: 100%;
    overflow-x: auto;
}

.map-popup {
    width: fit-content;

    table {
        overflow-x: auto;
        max-width: 100%;

        * {
            white-space: nowrap;
        }

        a {
            color: #0096ff;

            &:hover {
                text-decoration: underline;
                color: black;
            }
        }

        tr {
            & > *:nth-child(2) {
                background: #cdeaff;

                a {
                    color: black;
                }
            }
        }

        th {
            padding: 0 0.2rem;
            border: 1px solid black;
        }
        td {
            padding: 0 0.2rem;
            border: 1px solid black;

            &:first-child {
                font-weight: bold;
            }
        }
    }
}

.leaflet-div-icon {
    background: none !important;
    border: 0;
}
</style>
