var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ers-content address-page is-fluid is-max-desktop"},[_c('div',{staticClass:"columns is-mobile is-multiline is-variable",class:[_vm.$vssWidth >= 768 ? 'is-6' : 'is-12']},[_c('div',{staticClass:"ers-content__texts px-3"},[_c('div',{staticClass:"column is-12 p-0"},[_c('div',{staticClass:"ers-content__title"},[_vm._v(" "+_vm._s(_vm.$t('energy.address.title'))+" "),_c('Tooltip',{attrs:{"label":_vm.$t('energy.address.title_tooltip')}})],1)]),_c('div',{staticClass:"column is-12 p-0 py-3"},[_c('div',{staticClass:"ers-content__subtitle"},[_vm._v(" "+_vm._s(_vm.$t('energy.address.subtitle'))+" ")])])]),_c('div',{staticClass:"column is-12 pb-1 columns"},[_c('div',{staticClass:"column p-0"},[_c('div',{staticClass:"column px-0"},[_c('auto-complete',{ref:"autoComplete",on:{"selectedItem":function($event){return _vm.setAddress($event)}}})],1),(_vm.isActive || _vm.notFound)?_c('div',{staticClass:"px-0",attrs:{"id":"extra"}},[_c('div',{staticClass:"column px-0"},[_c('div',{staticClass:"column px-0"},[_c('b-field',{staticClass:"mt-1",attrs:{"label":_vm.$t('address.streetname'),"expanded":"","type":{ 'is-danger': _vm.$v.address.streetname.$error },"message":[
                                    !_vm.$v.address.streetname.required &&
                                    _vm.$v.address.streetname.$error
                                        ? _vm.$t('validation.field_required')
                                        : null ]}},[_c('b-input',{attrs:{"size":"is-medium","lazy":"","placeholder":"Kleine Schuurstraat"},on:{"blur":function($event){_vm.updateAddress()
                                        _vm.$v.address.streetname.$touch()},"input":function($event){return _vm.$v.address.streetname.$touch()}},model:{value:(_vm.streetname),callback:function ($$v) {_vm.streetname=$$v},expression:"streetname"}})],1)],1)]),_c('div',{staticClass:"columns is-variable is-multiline is-6"},[_c('div',{staticClass:"column is-6"},[_c('b-field',{attrs:{"label":_vm.$t('address.streetnumber'),"expanded":"","type":{
                                    'is-danger': _vm.$v.address.streetnumber.$error,
                                },"message":[
                                    !_vm.$v.address.streetnumber.required &&
                                    _vm.$v.address.streetnumber.$error
                                        ? _vm.$t('validation.field_required')
                                        : null ]}},[_c('b-input',{attrs:{"size":"is-medium","placeholder":"8","lazy":""},on:{"blur":function($event){return _vm.$v.address.streetnumber.$touch()},"input":function($event){_vm.updateAddress()
                                        _vm.$v.address.streetnumber.$touch()}},model:{value:(_vm.streetnumber),callback:function ($$v) {_vm.streetnumber=$$v},expression:"streetnumber"}})],1)],1),_c('div',{staticClass:"column is-6"},[_c('b-field',{attrs:{"label":_vm.$t('address.boxnumber'),"expanded":""}},[_c('b-input',{attrs:{"size":"is-medium","placeholder":"0"},model:{value:(_vm.boxnumber),callback:function ($$v) {_vm.boxnumber=$$v},expression:"boxnumber"}})],1)],1),_c('div',{staticClass:"column is-6"},[_c('b-field',{attrs:{"label":_vm.$t('address.postalcode'),"expanded":"","type":{
                                    'is-danger': _vm.$v.address.postalcode.$error,
                                },"message":[
                                    _vm.$v.address.postalcode.$error &&
                                    !_vm.$v.address.postalcode.required
                                        ? _vm.$t('address_page.field_required')
                                        : null,
                                    _vm.$v.address.postalcode.$error &&
                                    !_vm.$v.address.postalcode.minLength
                                        ? _vm.$t('validation.min_4_characters')
                                        : null,
                                    _vm.$v.address.postalcode.$error &&
                                    !_vm.$v.address.postalcode.numeric
                                        ? _vm.$t('validation.only_numbers')
                                        : null,
                                    _vm.$v.address.postalcode.$error &&
                                    !_vm.$v.address.postalcode.postalCodeValid
                                        ? "This postal code doesn't exists"
                                        : null ]}},[_c('b-input',{attrs:{"size":"is-medium","lazy":"","placeholder":"0960"},on:{"blur":function($event){_vm.updateAddress()
                                        _vm.$v.address.postalcode.$touch()},"input":function($event){return _vm.$v.address.postalcode.$touch()}},model:{value:(_vm.postalcode),callback:function ($$v) {_vm.postalcode=$$v},expression:"postalcode"}})],1)],1),_c('div',{staticClass:"column is-6"},[_c('b-field',{attrs:{"label":_vm.$t('address.municipality'),"expanded":"","type":{
                                    'is-danger': _vm.$v.address.municipality.$error,
                                },"message":[
                                    !_vm.$v.address.municipality.required &&
                                    _vm.$v.address.municipality.$error
                                        ? _vm.$t('validation.field_required')
                                        : null ]}},[_c('b-input',{attrs:{"size":"is-medium","lazy":"","placeholder":"Gent"},on:{"blur":function($event){_vm.updateAddress()
                                        _vm.$v.address.municipality.$touch()},"input":function($event){return _vm.$v.address.municipality.$touch()}},model:{value:(_vm.municipality),callback:function ($$v) {_vm.municipality=$$v},expression:"municipality"}})],1)],1)])]):_vm._e()]),_c('address-map',{attrs:{"address":_vm.getAddress.full_address},on:{"reset-map":_vm.handleReset,"next-page":_vm.confirmBuilding,"building-not-found":_vm.handleCheckPostalCode,"update-address":function($event){return _vm.updateAddress()}}})],1)]),(_vm.is3dModelNotFound)?_c('div',{staticClass:"ers-page-modal"},[_c('div',{staticClass:"ers-page-modal__card"},[_c('img',{attrs:{"src":require("@/assets/icons/ic-cube-warning.svg"),"alt":"","srcset":""}}),_c('div',{staticClass:"ers-page-modal__card-text"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t('energy.address.map.no_model.title'))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('energy.address.map.no_model.subtitle'))+" ")])]),_c('div',{staticClass:"ers-page-modal__card-buttons"},[_c('b-button',{attrs:{"size":"is-medium","type":"is-dark"},on:{"click":_vm.resetPage}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('energy.address.map.no_model.change_address'))+" ")])]),_c('b-button',{attrs:{"size":"is-medium","type":"is-primary"},on:{"click":function($event){return _vm.nextPage(+_vm.getAddress.effective_level)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('energy.address.map.no_model.start')))])])],1)])]):_vm._e(),_c('b-loading',{staticClass:"ers-page-address-loading",attrs:{"is-full-page":true,"can-cancel":false},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}}),_c('div',{staticClass:"column is-12 mt-5 pl-0"},[_c('div',{staticClass:"is-flex is-justify-content-space-between"},[_c('b-button',{attrs:{"size":"is-medium","type":"is-dark","icon-left":"arrow-left","tag":"router-link","to":{
                    name: 'ers.building-type',
                    params: {
                        lang: _vm.$route.params.lang,
                    },
                    query: _vm.$route.query,
                }}},[_c('span',[_vm._v(_vm._s(_vm.$t('common.previous')))])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }