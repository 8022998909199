import { render, staticRenderFns } from "./BuildingDimensions.vue?vue&type=template&id=e895308a&scoped=true&"
import script from "./BuildingDimensions.vue?vue&type=script&lang=js&"
export * from "./BuildingDimensions.vue?vue&type=script&lang=js&"
import style0 from "./BuildingDimensions.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./BuildingDimensions.vue?vue&type=style&index=1&id=e895308a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e895308a",
  null
  
)

export default component.exports