var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container section is-fluid"},[_c('div',{staticClass:"columns"},[(
                    !_vm.allowedBuildingTypes.includes('new_house') &&
                        !_vm.allowedBuildingTypes.includes('new_apartment')
                )?_c('div',{staticClass:"column"},[_c('title-with-tooltip',{attrs:{"label":_vm.$t('tooltip_text')},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('title'))+" ")]},proxy:true}],null,false,2940223905)}),_c('building-type-select',{ref:"parent",staticClass:"pb-4",attrs:{"building-type":_vm.buildingType,"allowed-building-types":_vm.allowedBuildingTypes,"value":_vm.buildingType},on:{"update:buildingType":function($event){_vm.buildingType=$event},"update:building-type":function($event){_vm.buildingType=$event}}})],1):_c('div',{staticClass:"column"},[_c('building-type-split',{ref:"parent",staticClass:"pb-4",attrs:{"building-type":_vm.buildingType,"allowed-building-types":_vm.allowedBuildingTypes,"value":_vm.buildingType},on:{"update:buildingType":function($event){_vm.buildingType=$event},"update:building-type":function($event){_vm.buildingType=$event}}}),_c('div',{staticClass:"is-flex is-justify-content-space-between pt-5 is-btn-box"},[_c('b-button',{attrs:{"icon-left":"arrow-left","type":"is-dark","tag":"router-link","size":"is-medium","to":_vm.prevLink}},[_c('span',[_vm._v(_vm._s(_vm.$t('previous')))])]),(_vm.buildingType != null)?_c('b-button',{attrs:{"tag":"router-link","size":"is-medium","type":"is-primary","icon-right":"arrow-right","to":{
                            name: 'valuation.request',
                            params: {
                                lang: _vm.$route.params.lang,
                                action: 'address',
                            },
                            query: _vm.$route.query,
                        }}},[_c('span',[_vm._v(_vm._s(_vm.$t('next')))])]):_vm._e()],1)],1)])]),_c('b-modal',{attrs:{"trap-focus":"","has-modal-card":""},on:{"close":_vm.closeDestructiveSaveModal},model:{value:(_vm.isDestructiveSave),callback:function ($$v) {_vm.isDestructiveSave=$$v},expression:"isDestructiveSave"}},[_c('div',{staticClass:"card modal-card p-5 destructive-save-modal"},[_c('h4',{staticClass:"is-size-5 has-text-weight-bold has-text-black"},[_vm._v(" "+_vm._s(_vm.$t('destructive_safeguard.title'))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('destructive_safeguard.content'))+" ")]),_c('div',{staticClass:"btn-group"},[_c('b-button',{on:{"click":_vm.closeDestructiveSaveModal}},[_vm._v(" "+_vm._s(_vm.$t('destructive_safeguard.cancel'))+" ")]),_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.next_page(true)}}},[_vm._v(" "+_vm._s(_vm.$t('destructive_safeguard.confirm'))+" ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }