<template>
    <div class="valuation_page valuer_tool-default-layout">
        <div
            v-if="getFeatures && getFeatures.building_id"
            class="valuer_tool-default-layout-map_container"
        >
            <re-map
                :building-id="getFeatures.building_id"
                :parcel-ids="getFeatures.parcel_ids"
                :level="getFeatures.level"
                :region="getFeatures.region"
                :center="{
                    lat: getFeatures.f_lat,
                    lng: getFeatures.f_lng,
                }"
                :map-scene2d="get_map_scene2d"
                :read-only="true"
                :focus-layer="'building'"
                :layers-control-visible="false"
                :options="{
                    zoomControl: true,
                    attributionControl: false,
                    scrollWheelZoom: false,
                    attributionControl: false,
                    scrollWheelZoom: false,
                    doubleClickZoom: true,
                    boxZoom: true,
                    dragging: true,
                }"
                :aerial-tile-visible="false"
                :cadastral-tile-visible="false"
                class="has-ratio"
                @update:mapScene2d="SET_MAP_SCENE2D($event)"
            ></re-map>
        </div>
        <div class="valuer_tool-default-layout-grid">
            <h1 class="valuer_tool-title">Valuation</h1>
            <value-card />
            <value-card post-reno />
            <grid-card title="General comments & remarks" is-full>
                <b-input type="textarea" rows="5" />
            </grid-card>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import ReMap from '@/components/common/Map.vue'
import ValueCard from './ValueCard.vue'
import GridCard from '../../elements/GridCard'

export default {
    name: 'Valuation',
    components: {
        ReMap,
        GridCard,
        ValueCard,
    },
    computed: {
        ...mapGetters('valuationStore/Address', ['getFeatures', 'get_map_scene2d']),
    },
    methods: {
        ...mapMutations('valuationStore/Address', ['SET_MAP_SCENE2D']),
    },
}
</script>
