<template>
    <div class="interior_tab">
        <h1 class="interior_tab-title">Interior</h1>
        <checkboxes-card feature="comfort" config-location="ovm_features" />
        <multi-choice-card
            feature="interior_state"
            config-location="ovm_features"
            small
        />
        <other-impacting-card
            type="positive"
            feature="interior_positive"
            config-location="ovm_features"
        />
        <other-impacting-card
            type="negative"
            feature="interior_negative"
            config-location="ovm_features"
        />
    </div>
</template>
<script>
import CheckboxesCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard/variants/CheckboxesCard.vue'
import OtherImpactingCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard/variants/OtherImpactingCard.vue'
import MultiChoiceCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard/variants/MultiChoiceCard.vue'
export default {
    name: 'InteriorTab',
    components: {
        OtherImpactingCard,
        MultiChoiceCard,
        CheckboxesCard,
    },
}
</script>
<style lang="scss" scoped>
.interior_tab {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 1fr;
    align-items: start;

    &-title {
        grid-column: 1 / 3;
        color: #001837;
        font-size: 24px;
        font-weight: 700;
    }
}
</style>
