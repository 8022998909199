<template>
    <grid-card is-full :title="$t('features.f_garage_present')">
        <template slot="header">
            <label class="flex-label">
                <h5>{{ $t('valuer_tool.exterior.garage.question') }}</h5>
                <b-checkbox v-model="f_garage_present" />
            </label>
        </template>
        <template v-if="f_garage_present" slot="default">
            <div class="grid_card-content_grid">
                <div
                    v-for="(garage, i) in garages"
                    :key="`garage_${i}`"
                    class="content_grid-item content_grid-item--full garage_row"
                >
                    <h4>Garage {{ i + 1 }}</h4>
                    <b-field horizontal label="Garage area">
                        <b-input v-model="garages[i].area" type="number" />
                    </b-field>
                    <div class="toggles">
                        <b-radio-button
                            v-model="garages[i].is_interior"
                            :native-value="true"
                            type="is-primary"
                            @input="() => toggleFloorDiagram(false)"
                        >
                            <span>Interior</span>
                        </b-radio-button>
                        <b-radio-button
                            v-model="garages[i].is_interior"
                            :native-value="false"
                            type="is-primary"
                            @input="() => toggleFloorDiagram(false)"
                        >
                            <span>Exterior</span>
                        </b-radio-button>
                    </div>
                    <comment-button big :active="garage.comment" />
                    <icon-button icon="delete" @click="removeGarage(i)" />
                </div>
                <clear-button
                    class="content_grid-item content_grid-item--full add-garage"
                    @click="addGarage"
                >
                    Add garage +
                </clear-button>
            </div>
        </template>
    </grid-card>
</template>
<script>
import GridCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard'
import CommentButton from '@/apps/Valuation/components/ValuerTool/elements/CommentButton.vue'
import { mapGetters, mapMutations } from 'vuex'
import IconButton from '@/components/elements/IconButton.vue'

export default {
    name: 'Garage',
    components: {
        GridCard,
        IconButton,
        CommentButton,
    },
    data: () => ({
        f_garage_present: false,
        garages: [],
    }),
    computed: {
        ...mapGetters('valuationStore/Address', ['getOVMFeatures', 'getFeatures']),
    },
    watch: {
        // Debounce everything to not trigger updates on every keystroke
        f_garage_present(val) {
            if (val) {
                if (Array.isArray(this.garages) && this.garages.length === 0) {
                    this.addGarage()
                } else if (!Array.isArray(this.garages)) {
                    this.garages = []
                    this.addGarage()
                }
            }
            this.SET_FEATURES({
                f_garage_present: val,
            })
        },
        garages: {
            deep: true,
            handler(val) {
                this.SET_OVM_FEATURES({
                    garages: val,
                })
            },
        },
    },
    mounted() {
        const generatedGarages = []

        if (this.getFeatures?.f_n_closed_garage) {
            for (let i = 0; i < this.getFeatures.f_n_closed_garage; i++) {
                generatedGarages.push({
                    area: 0,
                    is_interior: true,
                    comment: null,
                })
            }
        }
        if (this.getFeatures?.f_n_parking_spot) {
            for (let i = 0; i < this.getFeatures.f_n_parking_spot; i++) {
                generatedGarages.push({
                    area: 0,
                    is_interior: false,
                    comment: null,
                })
            }
        }

        this.f_garage_present =
            this.getFeatures?.f_garage_present || generatedGarages.length > 0 || false

        this.garages =
            this.getOVMFeatures.hasOwnProperty('garages') &&
            Array.isArray(this.getOVMFeatures.garages) &&
            this.getOVMFeatures.garages.length
                ? this.getOVMFeatures.garages
                : generatedGarages
    },
    methods: {
        ...mapMutations('valuationStore/Address', ['SET_OVM_FEATURES', 'SET_FEATURES']),
        addGarage() {
            this.garages.push({
                area: 0,
                is_interior: true,
                comment: null,
            })
        },
        removeGarage(i) {
            this.garages.splice(i, 1)
        },
    },
}
</script>
<style lang="scss" scoped>
.garage_row {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;

    h4 {
        flex-grow: 2;
    }
}

.add-garage {
    color: #009cff;
    font-size: 14px;
    font-weight: 700;
    align-items: flex-start;
    padding: 0;
}
</style>
<style lang="scss">
.garage_row {
    .toggles {
        display: flex;

        .control {
            label.b-radio.button.radio {
                border-radius: 0 !important;
            }

            &:first-child {
                label.b-radio.button.radio {
                    border-radius: 4px 0 0 4px !important;
                }
            }

            &:last-child {
                label.b-radio.button.radio {
                    border-radius: 0 4px 4px 0 !important;
                }
            }
        }
    }

    .field.is-horizontal {
        align-items: center;
        margin-bottom: 0;
        gap: 12px;

        .field-label {
            flex-grow: 2;
            padding-top: 0;
            margin-right: 0;

            label {
                white-space: nowrap;
                text-align: right;
            }
        }
    }
}
</style>
