<template>
    <div class="upload_block">
        <div
            v-for="(doc, i) in pending_documents"
            :key="`pending_${i}`"
            class="pending_doc"
        >
            <div class="pending_doc-placeholder" />
            <h5 class="pending_doc-name">
                {{ doc.name }}
            </h5>
            <div class="pending_doc-placeholder" />
        </div>
        <b-field
            class="upload_area"
            :class="{ 'upload_area--disabled': isComponentDisabled }"
        >
            <b-upload
                v-model="pending_documents"
                multiple
                drag-drop
                expanded
                :disabled="isComponentDisabled"
                :accept="acceptedTypes"
                @input="upload_document"
            >
                <section class="section">
                    <div class="content has-text-centered">
                        <p>
                            <b>Click to upload</b>
                            or drag and drop
                        </p>
                        <p class="subtext">
                            PNG, JPG, or PDF (max. 20mb)
                        </p>
                    </div>
                </section>
            </b-upload>
        </b-field>
    </div>
</template>
<script>
import utils from '@/shared/plugins/utils'

export default {
    name: 'UploadArea',
    props: {
        purpose: {
            type: String,
            default: 'specifications',
        },
        type: {
            validator(value) {
                return ['picture', 'document', '*'].includes(value)
            },
            default: '*',
        },
        docParams: {
            type: Object,
            default: () => ({}),
        },
        requestRef: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            uploading: false,
            pending_documents: [],
        }
    },
    computed: {
        isComponentDisabled() {
            // Necessary to use more detailed syntax so we
            // don't mistake it for the 'disabled' prop
            return this.uploading || this.disabled
        },
        acceptedTypes() {
            switch (this.type) {
                case 'picture':
                    return 'image/*'
                case 'document':
                    return 'pdf'
                default:
                    return '*'
            }
        },
    },
    methods: {
        upload_document() {
            this.uploading = true

            let requests = this.pending_documents.map((doc) => {
                let formData = new FormData()
                formData.append('attachment', doc)

                return this.$axios.post(
                    utils.urlJoin(this.$config.VALUATION_API_URL, [
                        '/request',
                        this.requestRef,
                        '/documents',
                    ]),
                    formData,
                    { params: { purpose: this.purpose, ...this.docParams } }
                )
            })

            Promise.all(requests).then((responses) => {
                responses.forEach((r) => {
                    this.$emit('uploaded', r.data)
                })

                this.uploading = false
                this.pending_documents = []
            })
        },
    },
}
</script>
<style lang="scss" scoped>
@keyframes pending {
    0% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}

.upload_block {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    .pending_doc {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        min-width: 416px;
        padding: 8px;
        background: white;
        gap: 16px;
        border-radius: 4px;
        border: 1px solid #d6e2e9;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
            0px 1px 3px 0px rgba(16, 24, 40, 0.1);
        width: 100%;
        background: linear-gradient(
            90deg,
            #dbdbdb 25%,
            rgba(219, 219, 219, 0.5) 50%,
            #dbdbdb 75%
        );
        background-origin: padding-box;
        background-position-x: 18.4244%;
        background-position-y: 50%;
        background-repeat-x: repeat;
        background-repeat-y: repeat;
        background-size: 400% 100%;
        animation: pending 1.5s infinite;

        &-placeholder {
            display: flex;
            width: 32px;
            height: 32px;
        }

        &-name {
            flex-grow: 2;
            color: #001837;
            font-size: 16px;
            font-weight: 500;
        }
    }
}
</style>
<style lang="scss">
@import '@/shared/assets/style/buefy.scss';
.upload_area {
    width: 100%;
    margin-top: 16px;

    .upload-draggable {
        border-radius: 8px !important;
        border: 1px dashed #bcc6cc !important;
        background: white !important;
        padding: 16px 24px !important;
        align-self: stretch !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
        gap: 4px !important;

        transition: all 0.1s ease-out !important;

        .section {
            padding: 0;

            .content {
                display: flex !important;
                flex-direction: column !important;
                align-items: center !important;
                gap: 4px !important;

                p {
                    color: #475467;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    margin: 0 !important;

                    b {
                        color: #009cff;
                        font-weight: 500;
                    }

                    &.subtext {
                        font-size: 12px;
                        line-height: 18px;
                    }
                }
            }
        }

        &:hover {
            border: 1px dashed #009cff !important;
            background: rgba(0, 156, 255, 0.2) !important;
        }
    }

    &--disabled {
        .upload-draggable {
            border-color: #9ba1a5;

            .content {
                p,
                b {
                    color: $dark-invert !important;
                }
            }
        }
    }
}
</style>
