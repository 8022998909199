<template>
    <clear-button class="buttn__wht" @click="$emit('click')">
        <slot />
    </clear-button>
</template>
<script>
import ClearButton from '@/components/elements/ClearButton.vue'

export default {
    name: 'WhiteButton',
    components: {
        ClearButton,
    },
}
</script>
<style lang="scss" scoped>
.buttn__wht {
    background: white;
    padding: 8px 12px;
    border-radius: 5px;
    border: 1px solid #d6e2e9;
    color: #001837;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    transition: all 0.2s ease-out;
    box-shadow: 0px 1px 2px 0px rgba(0, 24, 55, 0.06),
        0px 4px 6px 0px rgba(0, 24, 55, 0.06);

    &:hover {
        background: rgba(0, 156, 255, 0.1);
    }
}
</style>
