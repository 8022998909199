<template>
    <svg
        width="100%"
        viewBox="0 0 434 40"
        :class="{ interactive: interactive }"
        class="epc_array"
    >
        <g
            class="epc_arrow"
            :class="{ selected: currentLabel === 'F' }"
            @click="$emit('select-epc', 'F')"
        >
            <path
                d="M0 0H125.981L134.678 19.6461L125.981 39.2922H0L8.69707 19.6461L0 0Z"
                fill="#d62015"
                class="epc_arrow-bg"
            />
            <path
                d="M69.0685 22.3225H65.0711V26.7168H63.1899V15.9736H69.8328V17.7078H65.0711V20.6324H69.0685V22.3225Z"
                class="epc_arrow-label"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabel === 'E' }"
            @click="$emit('select-epc', 'E')"
        >
            <path
                d="M129.871 0H175.868L184.565 19.6461L175.868 39.2922H129.871L138.568 19.6461L129.871 0Z"
                fill="#fa9101"
                class="epc_arrow-bg"
            />
            <path
                d="M161.308 26.7168H154.636V15.9736H161.308V17.7078H156.517V20.4708H160.808V22.1315H156.517V24.9826H161.308V26.7168Z"
                class="epc_arrow-label"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabel === 'D' }"
            @click="$emit('select-epc', 'D')"
        >
            <path
                d="M179.758 0H225.756L234.453 19.6461L225.756 39.2922H179.758L188.455 19.6461L179.758 0Z"
                fill="#fdd400"
                class="epc_arrow-bg"
            />
            <path
                d="M207.386 26.7168H203.521V15.9736H207.283C210.458 15.9736 212.648 18.1634 212.648 21.3673C212.648 24.527 210.502 26.7168 207.386 26.7168ZM207.107 17.7078H205.402V24.9826H207.21C209.356 24.9826 210.664 23.6011 210.664 21.3673C210.664 19.0893 209.326 17.7078 207.107 17.7078Z"
                class="epc_arrow-label"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabel === 'C' }"
            @click="$emit('select-epc', 'C')"
        >
            <path
                d="M229.645 0H275.642L284.339 19.6461L275.642 39.2922H229.645L238.342 19.6461L229.645 0Z"
                fill="#fdf300"
                class="epc_arrow-bg"
            />
            <path
                d="M257.42 26.8932C254.29 26.8932 252.262 24.7181 252.262 21.3526C252.262 18.0165 254.363 15.7973 257.508 15.7973C260.051 15.7973 261.947 17.2816 262.314 19.589H260.33C259.963 18.3398 258.89 17.605 257.464 17.605C255.48 17.605 254.231 19.0452 254.231 21.3379C254.231 23.6158 255.495 25.0855 257.464 25.0855C258.919 25.0855 260.036 24.3213 260.389 23.1308H262.343C261.932 25.3941 259.963 26.8932 257.42 26.8932Z"
                class="epc_arrow-label"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabel === 'B' }"
            @click="$emit('select-epc', 'B')"
        >
            <path
                d="M279.532 0H325.529L334.226 19.6461L325.529 39.2922H279.532L288.229 19.6461L279.532 0Z"
                fill="#d8db1a"
                class="epc_arrow-bg"
            />
            <path
                d="M303.45 26.7168V15.9736H307.727C309.843 15.9736 311.136 17.0759 311.136 18.8689C311.136 20.0593 310.607 20.9117 309.593 21.3379C310.725 21.7053 311.298 22.5577 311.298 23.8069C311.298 25.644 310.019 26.7168 307.8 26.7168H303.45ZM307.565 17.6343H305.331V20.559H307.609C308.638 20.559 309.211 20.0152 309.211 19.0599C309.211 18.134 308.623 17.6343 307.565 17.6343ZM307.727 22.1462H305.331V25.0561H307.727C308.785 25.0561 309.373 24.5417 309.373 23.5717C309.373 22.6606 308.77 22.1462 307.727 22.1462Z"
                class="epc_arrow-label"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabel === 'A' }"
            @click="$emit('select-epc', 'A')"
        >
            <path
                d="M329.419 0H375.416L384.113 19.6461L375.416 39.2922H329.419L338.116 19.6461L329.419 0Z"
                fill="#9ade27"
                class="epc_arrow-bg"
            />
            <path
                d="M353.653 26.7168H351.698L355.578 15.9736H357.503L361.383 26.7168H359.399L358.532 24.2331H354.52L353.653 26.7168ZM356.298 19.1775L355.078 22.6459H357.988L356.754 19.1775C356.665 18.8982 356.563 18.5749 356.533 18.3545C356.489 18.5602 356.401 18.8835 356.298 19.1775Z"
                class="epc_arrow-label"
            />
        </g>
        <g
            class="epc_arrow"
            :class="{ selected: currentLabel === 'A+' }"
            @click="$emit('select-epc', 'A+')"
        >
            <path
                d="M379.306 0H425.303L434 19.6461L425.303 39.2922H379.306L388.003 19.6461L379.306 0Z"
                fill="#00bd00"
                class="epc_arrow-bg"
            />
            <path
                d="M398.38 26.7168H396.426L400.306 15.9736H402.231L406.111 26.7168H404.127L403.26 24.2331H399.247L398.38 26.7168ZM401.026 19.1775L399.806 22.6459H402.716L401.481 19.1775C401.393 18.8982 401.29 18.5749 401.261 18.3545C401.217 18.5602 401.129 18.8835 401.026 19.1775ZM407.443 21.0145H410.368V18.0899H411.867V21.0145H414.791V22.5136H411.867V25.4529H410.368V22.5136H407.443V21.0145Z"
                class="epc_arrow-label"
            />
        </g>
    </svg>
</template>
<script>
export default {
    name: 'EpcArrayFlanders',
    props: {
        interactive: {
            type: Boolean,
            default: false,
        },
        currentLabel: {
            type: String,
            default: null,
        },
    },
}
</script>
<style lang="scss" scoped>
@import './epcArrayStyle.scss';
</style>
