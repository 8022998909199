<template>
    <div class="exterior_page valuer_tool-default-layout">
        <div
            v-if="getFeatures && getFeatures.building_id"
            class="valuer_tool-default-layout-map_container"
        >
            <re-map
                :building-id="getFeatures.building_id"
                :parcel-ids="getFeatures.parcel_ids"
                :level="getFeatures.level"
                :region="getFeatures.region"
                :center="{
                    lat: getFeatures.f_lat,
                    lng: getFeatures.f_lng,
                }"
                :map-scene2d="get_map_scene2d"
                :read-only="true"
                :focus-layer="'building'"
                :layers-control-visible="false"
                :options="{
                    zoomControl: true,
                    attributionControl: false,
                    scrollWheelZoom: false,
                    attributionControl: false,
                    scrollWheelZoom: false,
                    doubleClickZoom: true,
                    boxZoom: true,
                    dragging: true,
                }"
                :aerial-tile-visible="false"
                :cadastral-tile-visible="false"
                class="has-ratio"
                @update:mapScene2d="SET_MAP_SCENE2D($event)"
            ></re-map>
        </div>
        <div class="valuer_tool-default-layout-grid">
            <h1 class="valuer_tool-title">Surroundings</h1>
            <flood-map />
            <multi-choice-card title="Environment" feature="environment" />
            <multi-choice-card
                title="Level of indirect traffic"
                feature="indirect_traffic_level"
            />
            <multi-choice-card
                title="Proximity of indirect street traffic"
                feature="environment"
            />
            <h1 class="valuer_tool-title">Mobility</h1>
            <multi-choice-card title="Public transport" feature="public_transport" />
            <multi-choice-card title="Education" feature="education" />
            <multi-choice-card title="Recreation, sports, culture" feature="recreation" />
            <multi-choice-card title="Shops & services" feature="shops" />
            <multi-choice-card title="Healthcare" feature="healthcare" />
            <h1 class="valuer_tool-title">Other</h1>
            <other-impacting-card type="positive" feature="other_positive" />
            <other-impacting-card type="negative" feature="other_positive" />
        </div>
    </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import ReMap from '@/components/common/Map.vue'
import FloodMap from './cards/FloodMap.vue'
import MultiChoiceCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard/variants/MultiChoiceCard.vue'
import OtherImpactingCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard/variants/OtherImpactingCard.vue'

export default {
    name: 'Exterior',
    components: {
        ReMap,
        FloodMap,
        MultiChoiceCard,
        OtherImpactingCard,
    },
    computed: {
        ...mapGetters('valuationStore/Address', ['getFeatures', 'get_map_scene2d']),
        building_type() {
            return this.getFeatures.f_building_type
        },
        is_house() {
            return utils.is_house(this.building_type)
        },
        is_apartment() {
            return utils.is_apartment(this.building_type)
        },
        is_plot() {
            return utils.is_plot(this.building_type)
        },
        is_building() {
            return utils.is_building(this.building_type)
        },
    },
    methods: {
        ...mapMutations('valuationStore/Address', ['SET_MAP_SCENE2D']),
    },
}
</script>
