<template>
    <div id="sidebar">
        <sidebar-summary />
        <sidebar-section
            title="General information"
            :active="isActiveRoute('general')"
            @click="goTo('general')"
        />
        <sidebar-section
            title="Exterior"
            :active="isActiveRoute('exterior')"
            @click="goTo('exterior')"
        />
        <sidebar-section
            title="Interior"
            :active="isActiveRoute('interior')"
            @click="goTo('interior')"
        />
        <!-- <sidebar-section v-if="is_apartment" title="Common parts" /> -->
        <sidebar-section
            title="Surroundings"
            :active="isActiveRoute('surroundings')"
            @click="goTo('surroundings')"
        />
        <!-- <sidebar-section title="Renovation" /> -->
        <sidebar-section
            title="Valuation"
            :active="isActiveRoute('value')"
            @click="goTo('value')"
        />
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import SidebarSummary from './Summary.vue'
import SidebarSection from './Section.vue'
import utils from '@/shared/plugins/utils'

export default {
    name: 'Sidebar',
    components: {
        SidebarSummary,
        SidebarSection,
    },
    computed: {
        ...mapGetters('valuationStore/Address', ['getFeatures']),
        activeRoute() {
            return this.$route.name.toLowerCase()
        },
        is_apartment() {
            return utils.is_apartment(this.getFeatures.f_building_type)
        },
    },
    methods: {
        isActiveRoute(name) {
            return this.activeRoute.startsWith('valuation.valuer-app.' + name)
        },
        goTo(target) {
            let fullTarget = 'valuation.valuer-app.' + target

            if (fullTarget.toLowerCase() !== this.activeRoute) {
                let { params, query } = this.$route
                this.$router.push({ name: fullTarget, params, query })
            }

            document
                .getElementById('valuer_tool-content')
                .scroll({ top: 0, behavior: 'smooth' })
        },
    },
}
</script>
<style lang="scss" scoped>
#sidebar {
    width: 300px;
    height: 100%;
    border-right: 1px solid rgba(214, 226, 233, 1);
    background: #fff;
    position: sticky;
    top: 0;
    left: 0;
}
</style>
