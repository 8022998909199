<template>
    <grid-card :title="$t(`${configLocation}.${feature}`)" :is-full="wide">
        <checkboxes
            :value="checked"
            :options="values"
            :wide="wide"
            :feature="feature"
            @select="selectValue"
        />
        <!-- <div class="checkboxes" :class="{ 'checkboxes--wide': wide }">
            <b-checkbox
                v-for="(option, i) in values"
                :key="`${feature}-answer_${i}`"
                v-model="checked"
                :native-value="option.value"
            >
                {{ option.name }}
            </b-checkbox>
        </div> -->
    </grid-card>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import utils from '@/shared/plugins/utils'
import GridCard from '..'
import Checkboxes from '../../Checkboxes'

export default {
    name: 'CheckboxesCard',
    components: {
        GridCard,
        Checkboxes,
    },
    props: {
        feature: {
            type: String,
            required: true,
        },
        configLocation: {
            type: String,
            default: 'dvm_features',
        },
        wide: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        checked: [],
    }),
    computed: {
        ...mapGetters('valuationStore/Address', ['getFullBuildingData']),
        values() {
            return utils
                .generateValues(this.feature, this.value)
                .map((item) => ({ ...item, name: this.$t(`value.${item.value}`) }))
        },
    },
    watch: {
        checked(val) {
            let key = this.configLocation
            let values = {}
            values[this.feature] = val
            this.SET_UNIVERSAL({ key, values })
        },
    },
    mounted() {
        this.checked = this.getFullBuildingData[this.configLocation].hasOwnProperty(
            this.feature
        )
            ? this.getFullBuildingData[this.configLocation][this.feature]
            : []
    },
    methods: {
        ...mapMutations('valuationStore/Address', ['SET_UNIVERSAL']),
        selectValue(value) {
            this.checked = value
        },
    },
}
</script>
<style lang="scss" scoped>
.checkboxes {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
    row-gap: 16px;
    justify-items: stretch;
    align-items: center;

    &--wide {
        grid-template-columns: repeat(4, 1fr);
    }
}
</style>
