<i18n>
{
    "en-BE":{
        "analysis": "Analysis",
        "extreme_value": "Extreme value for",
        "no_bedroom": "0 bedrooms!",
        "price": "Price",
        "touching_sides": "Touching sides"

    },
    "fr-BE":{
        "analysis": "Analyse",
        "extreme_value": "Valeur extrême pour",
        "no_bedroom": "0 chambre à coucher!",
        "price": "Prix",
        "touching_sides": "Touching sides"

    },
    "nl-BE":{
        "analysis": "Analysis",
        "extreme_value": "Extreme value for",
        "no_bedroom": "0 bedrooms!",
        "price": "Price",
        "touching_sides": "Touching sides"
    }
}
</i18n>
<template>
    <div class="is-w100">
        <Map
            :center="[features.f_lat, features.f_lng]"
            :region="features.region"
            :population="avmTransaction.reference_listings"
            :assessed="features"
            :price="avmTransaction.estimation.asking_price_q50"
            :prices="prices"
            :price-per-sqm-array="pricePerSqmArray"
            :address="address"
            :update-remarks="updateRemarks"
        />

        <div
            class="is-flex is-size-4 mt-5 is-flex-wrap-wrap is-justify-content-space-between mb-5 valuations-prices"
        >
            <span>
                {{ $t('features.transaction_value') }}:
                <span class="has-text-primary has-text-weight-bold">
                    {{
                        withSpaces(
                            Math.round(
                                avmTransaction.estimation.asking_price_q50 / 1000
                            ) * 1000
                        )
                    }}
                    €
                </span>
            </span>
            <span>
                {{ $t('valuation.forced_sale_value') }}:
                {{ withSpaces(forcedSaleValue) }} €
            </span>
            <span v-if="is_building">
                {{ $t('valuation.rental_value') }}:
                {{
                    withSpaces(
                        Math.round(avmRental.estimation.asking_price_q50 / 10) * 10
                    )
                }}
                €
            </span>
            <span v-if="is_building">
                {{ $t('valuation.reconstruction_value') }}:
                {{ withSpaces(reconstructionValue) }} €
            </span>
        </div>
        <h1 class="is-size-4 has-text-black has-text-weight-bold">
            {{ $t('analysis') }}
        </h1>
        <!-- <div>
            <h3
                v-for="warning in warnings"
                :key="`warning_${warning}`"
                class="is-size-5 has-text-danger has-text-weight-bold"
            >
                {{ $t('extreme_value') }} {{ $t(warning) }}!
            </h3>
            <h3 v-if="is_apartment && !features.f_bedrooms">{{ $t('no_bedroom') }}</h3>
        </div> -->
        <div class="is-avm-grid">
            <kernel-chart
                :data-array="prices"
                :target-value="targetPrice"
                title="Price"
            />
            <div>
                <h3 class="is-size-5 has-text-weight-bold">{{ $t('price') }}</h3>
                <ul>
                    <li>Assessed: {{ withSpaces(targetPrice) }}</li>
                    <li>Median: {{ withSpaces(medianPrice) }}</li>
                    <li>Average: {{ withSpaces(meanPrice) }}</li>
                    <li>Deviance: {{ withSpaces(priceDeviance) }}</li>
                    <li class="has-text-black has-text-weight-semibold is-size-5">
                        %={{ priceTile }}
                    </li>
                </ul>
            </div>
            <template v-if="is_building">
                <kernel-chart
                    :data-array="epcs"
                    :target-value="targetEpc"
                    :title="$t('features.f_epc')"
                />
                <div>
                    <h3
                        class="is-size-5 has-text-weight-bold"
                        :class="{
                            'has-text-danger': warnings.includes('features.f_epc'),
                        }"
                    >
                        {{ $t('features.f_epc')
                        }}{{
                            warnings.includes('features.f_epc') ? ' (EXTREME VALUE)' : ''
                        }}
                    </h3>
                    <ul>
                        <li>Assessed: {{ targetEpc }}</li>
                        <li>Median: {{ medianEpc }}</li>
                        <li>Average: {{ meanEpc }}</li>
                        <li>Deviance: {{ epcDeviance }}</li>
                        <li class="has-text-black has-text-weight-semibold is-size-5">
                            %={{ epcTile }}
                        </li>
                    </ul>
                    <div class="is-flex is-align-items-center mt-2">
                        <b-input v-model="influence.epc" type="number" size="is-small" />
                        <span class="ml-1 ">%</span>
                    </div>
                </div>

                <kernel-chart
                    :data-array="years"
                    :target-value="targetYear"
                    :title="$t('features.f_construction_year')"
                    :is-year="true"
                />
                <div>
                    <h3
                        class="is-size-5 has-text-weight-bold"
                        :class="{
                            'has-text-danger': warnings.includes(
                                'features.f_construction_year'
                            ),
                        }"
                    >
                        {{ $t('features.f_construction_year')
                        }}{{
                            warnings.includes('features.f_construction_year')
                                ? ' (EXTREME VALUE)'
                                : ''
                        }}
                    </h3>
                    <ul>
                        <li>Assessed: {{ targetYear }}</li>
                        <li>Median: {{ medianYear }}</li>
                        <li>Average: {{ meanYear }}</li>
                        <li>Deviance: {{ yearDeviance }}</li>
                        <li class="has-text-black has-text-weight-semibold is-size-5">
                            %={{ yearTile }}
                        </li>
                    </ul>
                    <div class="is-flex is-align-items-center mt-2">
                        <b-input v-model="influence.year" type="number" size="is-small" />
                        <span class="ml-1">%</span>
                    </div>
                </div>

                <kernel-chart
                    :data-array="livingAreas"
                    :target-value="targetLivingArea"
                    :title="$t('features.f_living_area')"
                />
                <div>
                    <h3
                        class="is-size-5 has-text-weight-bold"
                        :class="{
                            'has-text-danger': warnings.includes(
                                'features.f_living_area'
                            ),
                        }"
                    >
                        {{ $t('features.f_living_area')
                        }}{{
                            warnings.includes('features.f_living_area')
                                ? ' (EXTREME VALUE)'
                                : ''
                        }}
                    </h3>
                    <ul>
                        <li>Assessed: {{ targetLivingArea }}</li>
                        <li>Median: {{ medianLiving }}</li>
                        <li>Average: {{ meanLiving }}</li>
                        <li>Deviance: {{ livingDeviance }}</li>
                        <li class="has-text-black has-text-weight-semibold is-size-5">
                            %={{ livingTile }}
                        </li>
                    </ul>
                    <div class="is-flex is-align-items-center mt-2">
                        <b-input
                            v-model="influence.living"
                            type="number"
                            size="is-small"
                        />
                        <span class="ml-1">%</span>
                    </div>
                </div>
            </template>

            <kernel-chart
                v-if="!is_apartment"
                :data-array="parcelAreas"
                :target-value="targetParcelArea"
                :title="$t('features.f_parcel_area')"
            />
            <div v-if="!is_apartment" class="flex-grow-1 flex-shrink-0">
                <h3
                    class="is-size-5 has-text-weight-bold"
                    :class="{
                        'has-text-danger': warnings.includes('features.f_parcel_area'),
                    }"
                >
                    {{ $t('features.f_parcel_area')
                    }}{{
                        warnings.includes('features.f_parcel_area')
                            ? ' (EXTREME VALUE)'
                            : ''
                    }}
                </h3>
                <ul>
                    <li>Assessed: {{ targetParcelArea }}</li>
                    <li>Median: {{ medianParcel }}</li>
                    <li>Average: {{ meanParcel }}</li>
                    <li>Deviance: {{ parcelDeviance }}</li>
                    <li class="has-text-black has-text-weight-semibold is-size-5">
                        %={{ parcelTile }}
                    </li>
                </ul>
                <div v-if="!is_apartment" class="is-flex is-align-items-center mt-2">
                    <b-input v-model="influence.parcel" type="number" size="is-small" />
                    <span class="ml-1">%</span>
                </div>
            </div>

            <template v-if="is_house">
                <kernel-chart
                    :data-array="buildingAreas"
                    :target-value="targetBuildingArea"
                    :title="$t('features.f_building_area')"
                />
                <div class="flex-grow-1 flex-shrink-0">
                    <h3
                        class="is-size-5 has-text-weight-bold"
                        :class="{
                            'has-text-danger': warnings.includes(
                                'features.f_building_area'
                            ),
                        }"
                    >
                        {{ $t('features.f_building_area')
                        }}{{
                            warnings.includes('features.f_building_area')
                                ? ' (EXTREME VALUE)'
                                : ''
                        }}
                    </h3>
                    <ul>
                        <li>Assessed: {{ targetBuildingArea }}</li>
                        <li>Median: {{ medianBuilding }}</li>
                        <li>Average: {{ meanBuilding }}</li>
                        <li>Deviance: {{ buildingDeviance }}</li>
                        <li class="has-text-black has-text-weight-semibold is-size-5">
                            %={{ buildingTile }}
                        </li>
                    </ul>
                    <div v-if="!is_apartment" class="is-flex is-align-items-center mt-2">
                        <b-input
                            v-model="influence.building"
                            type="number"
                            size="is-small"
                        />
                        <span class="ml-1">%</span>
                    </div>
                </div>

                <kernel-chart
                    :data-array="gardenAreas"
                    :target-value="targetGardenArea"
                    :title="$t('features.f_garden_area')"
                />
                <div class="flex-grow-1 flex-shrink-0">
                    <h3
                        class="is-size-5 has-text-weight-bold"
                        :class="{
                            'has-text-danger': warnings.includes(
                                'features.f_garden_area'
                            ),
                        }"
                    >
                        {{ $t('features.f_garden_area')
                        }}{{
                            warnings.includes('features.f_garden_area')
                                ? ' (EXTREME VALUE)'
                                : ''
                        }}
                    </h3>
                    <ul>
                        <li>Assessed: {{ targetGardenArea }}</li>
                        <li>Median: {{ medianGarden }}</li>
                        <li>Average: {{ meanGarden }}</li>
                        <li>Deviance: {{ gardenDeviance }}</li>
                        <li class="has-text-black has-text-weight-semibold is-size-5">
                            %={{ gardenTile }}
                        </li>
                    </ul>
                    <div v-if="!is_apartment" class="is-flex is-align-items-center mt-2">
                        <b-input
                            v-model="influence.garden"
                            type="number"
                            size="is-small"
                        />
                        <span class="ml-1">%</span>
                    </div>
                </div>

                <kernel-chart
                    :data-array="touchingSides"
                    :target-value="targetTouchingSides"
                    :is-sides="true"
                    :title="$t('touching_sides')"
                />
                <div class="flex-grow-1 flex-shrink-0">
                    <h3
                        class="is-size-5 has-text-weight-bold"
                        :class="{
                            'has-text-danger': warnings.includes('touching_sides'),
                        }"
                    >
                        {{ $t('touching_sides')
                        }}{{
                            warnings.includes('touching_sides') ? ' (EXTREME VALUE)' : ''
                        }}
                    </h3>
                    <ul>
                        <li>Assessed: {{ targetTouchingSides }}</li>
                        <li>Median: {{ medianSides }}</li>
                        <li>Average: {{ meanSides }}</li>
                        <li>Deviance: {{ sidesDeviance }}</li>
                        <li class="has-text-black has-text-weight-semibold is-size-5">
                            %={{ sidesTile }}
                        </li>
                    </ul>
                    <div v-if="!is_apartment" class="is-flex is-align-items-center mt-2">
                        <b-input
                            v-model="influence.sides"
                            type="number"
                            size="is-small"
                        />
                        <span class="ml-1">%</span>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import * as d3 from 'd3'
import KernelChart from './KernelChart.vue'
import Map from './ReferencesMap.vue'
import utils from '@/shared/plugins/utils'
import { mapMutations, mapGetters } from 'vuex'

export default {
    name: 'AVM',
    components: {
        KernelChart,
        Map,
    },
    props: {
        address: {
            type: Object,
            required: true,
        },
        avmRental: {
            type: Object,
            required: false,
            default: () => {
                return { asking_price_q50: 0 }
            },
        },
        avmTransaction: {
            type: Object,
            required: true,
        },
        features: {
            type: Object,
            required: true,
        },
        reconstructionValue: {
            type: Number,
            required: true,
        },
        forcedSaleValue: {
            type: Number,
            required: true,
        },
        updateRemarks: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            spider_chart_options: {
                responsive: true,
                maintainAspectRatio: false,
                height: 800,
                width: 800,
                tension: 10,
                scales: {
                    r: {
                        angleLines: {
                            display: false,
                        },
                    },
                    suggestedMax: 100,
                },
            },
            influence: {
                epc: 0,
                year: 0,
                living: 0,
                building: 0,
                parcel: 0,
                garden: 0,
                sides: 0,
            },
        }
    },
    computed: {
        ...mapGetters('valuationStore/Address', ['getValuation']),
        building_type() {
            return this.features.f_building_type
        },
        is_house() {
            return utils.is_house(this.building_type)
        },
        is_apartment() {
            return utils.is_apartment(this.building_type)
        },
        is_building() {
            return utils.is_building(this.building_type)
        },
        prices() {
            const array = this.avmTransaction.reference_listings.map(({ prijs }) => prijs)
            array.sort((a, b) => a - b)
            return array
        },
        targetPrice() {
            return (
                Math.round(this.avmTransaction.estimation.asking_price_q50 / 1000) * 1000
            )
        },
        medianPrice() {
            return Math.round(d3.quantileSorted(this.prices, 0.5) / 1000) * 1000
        },
        meanPrice() {
            return Math.round(d3.mean(this.prices) / 1000) * 1000
        },
        priceDeviance() {
            return Math.round(d3.deviation(this.prices) / 1000) * 1000
        },
        priceTile() {
            return (
                (d3.bisect(this.prices, this.targetPrice) / this.prices.length) *
                100
            ).toFixed(2)
        },
        pricePerSqmArray() {
            const array = this.avmTransaction.reference_listings.map(
                ({ prijs, f_living_area }) => Math.round(prijs / f_living_area)
            )
            array.sort((a, b) => a - b)
            return array
        },
        epcs() {
            const array = this.avmTransaction.reference_listings.map(({ f_epc }) => f_epc)
            array.sort((a, b) => a - b)
            return array
        },
        targetEpc() {
            return this.avmTransaction.input_data.f_epc
        },
        medianEpc() {
            return Math.round(d3.quantileSorted(this.epcs, 0.5) * 100) / 100
        },
        meanEpc() {
            return Math.round(d3.mean(this.epcs) * 100) / 100
        },
        epcDeviance() {
            return Math.round(d3.deviation(this.epcs) * 100) / 100
        },
        epcTile() {
            return (
                (d3.bisect(this.epcs, this.targetEpc) / this.epcs.length) *
                100
            ).toFixed(2)
        },
        years() {
            const array = this.avmTransaction.reference_listings.map(
                ({ f_construction_year }) => f_construction_year
            )

            if (
                this.targetYear > Math.max(...array) ||
                this.targetYear < Math.min(...array)
            ) {
                array.push(this.targetYear)
            }

            array.sort((a, b) => a - b)
            return array
        },
        targetYear() {
            return this.avmTransaction.input_data.f_construction_year
        },
        medianYear() {
            return Math.round(d3.quantileSorted(this.years, 0.5))
        },
        meanYear() {
            return Math.round(d3.mean(this.years))
        },
        yearDeviance() {
            return Math.round(d3.deviation(this.years))
        },
        yearTile() {
            return (
                (d3.bisect(this.years, this.targetYear) / this.years.length) *
                100
            ).toFixed(2)
        },
        livingAreas() {
            const array = this.avmTransaction.reference_listings.map(
                ({ f_living_area }) => f_living_area
            )
            array.sort((a, b) => a - b)
            return array
        },
        targetLivingArea() {
            return this.avmTransaction.input_data.f_living_area
        },
        medianLiving() {
            return Math.round(d3.quantileSorted(this.livingAreas, 0.5) * 100) / 100
        },
        meanLiving() {
            return Math.round(d3.mean(this.livingAreas) * 100) / 100
        },
        livingDeviance() {
            return Math.round(d3.deviation(this.livingAreas) * 100) / 100
        },
        livingTile() {
            return (
                (d3.bisect(this.livingAreas, this.targetLivingArea) /
                    this.livingAreas.length) *
                100
            ).toFixed(2)
        },
        parcelAreas() {
            const array = this.avmTransaction.reference_listings.map(
                ({ f_parcel_area }) => f_parcel_area
            )
            array.sort((a, b) => a - b)
            return array
        },
        targetParcelArea() {
            return this.avmTransaction.input_data.f_parcel_area
        },
        medianParcel() {
            return Math.round(d3.quantileSorted(this.parcelAreas, 0.5) * 100) / 100
        },
        meanParcel() {
            return Math.round(d3.mean(this.parcelAreas) * 100) / 100
        },
        parcelDeviance() {
            return Math.round(d3.deviation(this.parcelAreas) * 100) / 100
        },
        parcelTile() {
            return (
                (d3.bisect(this.parcelAreas, this.targetParcelArea) /
                    this.parcelAreas.length) *
                100
            ).toFixed(2)
        },
        buildingAreas() {
            const array = this.avmTransaction.reference_listings.map(
                ({ f_building_area }) => f_building_area
            )
            array.sort((a, b) => a - b)
            return array
        },
        targetBuildingArea() {
            return this.avmTransaction.input_data.f_building_area
        },
        medianBuilding() {
            return Math.round(d3.quantileSorted(this.buildingAreas, 0.5) * 100) / 100
        },
        meanBuilding() {
            return Math.round(d3.mean(this.buildingAreas) * 100) / 100
        },
        buildingDeviance() {
            return Math.round(d3.deviation(this.buildingAreas) * 100) / 100
        },
        buildingTile() {
            return (
                (d3.bisect(this.buildingAreas, this.targetBuildingArea) /
                    this.buildingAreas.length) *
                100
            ).toFixed(2)
        },
        gardenAreas() {
            const array = this.avmTransaction.reference_listings.map(
                ({ f_garden_area }) => f_garden_area
            )
            array.sort((a, b) => a - b)
            return array
        },
        targetGardenArea() {
            return this.avmTransaction.input_data.f_garden_area
        },
        medianGarden() {
            return Math.round(d3.quantileSorted(this.gardenAreas, 0.5) * 100) / 100
        },
        meanGarden() {
            return Math.round(d3.mean(this.gardenAreas) * 100) / 100
        },
        gardenDeviance() {
            return Math.round(d3.deviation(this.gardenAreas) * 100) / 100
        },
        gardenTile() {
            return (
                (d3.bisect(this.gardenAreas, this.targetGardenArea) /
                    this.gardenAreas.length) *
                100
            ).toFixed(2)
        },
        touchingSides() {
            const array = this.avmTransaction.reference_listings.map(
                ({ f_touching_sides }) => f_touching_sides
            )
            array.sort((a, b) => a - b)
            return array
        },
        targetTouchingSides() {
            return this.avmTransaction.input_data.f_touching_sides
        },
        medianSides() {
            return d3.quantileSorted(this.touchingSides, 0.5)
        },
        meanSides() {
            return d3.mean(this.touchingSides)
        },
        sidesDeviance() {
            return d3.deviation(this.touchingSides)
        },
        sidesTile() {
            return (
                (d3.bisect(this.touchingSides, this.targetTouchingSides) /
                    this.touchingSides.length) *
                100
            ).toFixed(2)
        },
        warnings() {
            const warnings = []

            if (this.epcTile < 5 || this.epcTile > 95) {
                warnings.push('features.f_epc')
            }
            if (this.yearTile < 5 || this.yearTile > 95) {
                warnings.push('features.f_construction_year')
            }
            if (this.livingTile < 5 || this.livingTile > 95) {
                warnings.push('features.f_living_area')
            }

            if (!this.is_apartment) {
                if (this.parcelTile < 5 || this.parcelTile > 95) {
                    warnings.push('features.f_parcel_area')
                }
                if (this.buildingTile < 5 || this.buildingTile > 95) {
                    warnings.push('features.f_building_area')
                }
                if (this.gardenTile < 5 || this.gardenTile > 95) {
                    warnings.push('features.f_garden_area')
                }
                if (this.sidesTile < 5 || this.sidesTile > 95) {
                    warnings.push('touching_sides')
                }
            }

            return warnings
        },
    },
    watch: {
        influence: {
            handler() {
                this.SET_INFLUENCE({ ...this.influence })
            },
            deep: true,
        },
    },
    mounted() {
        this.influence = this.getValuation.influence
    },
    methods: {
        ...mapMutations('valuationStore/Address', ['SET_INFLUENCE']),
        withSpaces(number) {
            return utils.numberWithSpaces(number)
        },
    },
}
</script>
<style lang="scss" scoped>
.valuations-prices {
    span {
        flex-basis: 50%;
    }
}

.is-avm-grid {
    display: grid;
    grid-template-columns: minmax(700px, auto) minmax(75px, auto);
    align-items: center;
    overflow-x: auto;
}
</style>
