<template>
    <div
        ref="bubble"
        :style="{
            left: left,
            top: top,
            position: position,
            'margin-left': marginLeft,
            'margin-right': marginRight,
        }"
        class="bubble"
        :class="{ 'bubble--top': isTop }"
    >
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'BubbleText',
    props: {
        isTop: {
            type: Boolean,
            default: false,
        },
        offsetH: {
            type: String,
            default: 'unset',
        },
        offsetV: {
            type: String,
            default: 'unset',
        },
        pos: {
            type: String,
            default: 'auto',
        },
    },
    data() {
        return {
            left: this.$props.offsetH,
            top: this.$props.offsetV,
            position: this.$props.offsetH === 'unset' ? 'relative' : 'absolute',
            marginLeft: this.$props.pos === 'left' ? 'unset' : 'auto',
            marginRight: this.$props.pos === 'right' ? 'unset' : 'auto',
        }
    },
}
</script>

<style scoped>
.bubble {
    width: fit-content;
    font-size: 12px;
    padding: 8px;
    background: #172d49;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    color: white;
    text-align: center;
    box-shadow: 0px 2px 14px rgba(0, 47, 80, 0.2);
    margin-inline: auto;
}

.bubble--blue {
    background: #17a3fd;
}

.bubble:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 10px 5px 0;
    border-color: #172d49 transparent;
    display: block;
    width: 0;
    z-index: 1;
    bottom: -10px;
    margin-inline-start: -5px;
    left: 50%;
}

.bubble--blue:after {
    border-color: #17a3fd transparent;
}

.bubble--top:after {
    border-width: 0 5px 10px;
    top: -10px;
    bottom: unset;
}
</style>
