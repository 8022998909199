var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('grid-card',{staticClass:"flood_map_card",attrs:{"no-padding":"","is-full":""}},[_c('re-map',{staticClass:"has-ratio",attrs:{"building-id":_vm.getFeatures.building_id,"parcel-ids":_vm.getFeatures.parcel_ids,"level":_vm.getFeatures.level,"region":_vm.getFeatures.region,"center":{
            lat: _vm.getFeatures.f_lat,
            lng: _vm.getFeatures.f_lng,
        },"map-scene2d":_vm.get_map_scene2d,"read-only":true,"focus-layer":'building',"layers-control-visible":true,"options":{
            zoomControl: true,
            attributionControl: false,
            scrollWheelZoom: false,
            attributionControl: false,
            scrollWheelZoom: false,
            doubleClickZoom: true,
            boxZoom: true,
            dragging: true,
        },"aerial-tile-visible":false,"cadastral-tile-visible":false},on:{"update:mapScene2d":function($event){return _vm.SET_MAP_SCENE2D($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }