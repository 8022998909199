<template>
    <button class="clear-button" @click="$emit('click')">
        <slot />
    </button>
</template>
<script>
export default {
    name: 'ClearButton',
}
</script>
<style lang="scss" scoped>
.clear-button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    color: grey;

    &:hover {
        color: black;
    }
}
</style>
