<template>
    <grid-card>
        <template slot="header">
            <h5 class="grid_card-title">
                {{ title }}
            </h5>
        </template>
        <template slot="default">
            <div class="value_grid">
                <div v-if="!postReno" class="value_grid-entry value_grid-entry--grey">
                    <h6>AVM estimated price</h6>
                    <h6>
                        {{ priceFormat(getAvmTransactionEstimations.asking_price_q50) }}
                    </h6>
                </div>
                <div class="value_grid-entry">
                    <h6>Transaction value</h6>
                    <h6>
                        {{ priceFormat(getAvmTransactionEstimations.asking_price_q50) }}
                    </h6>
                </div>
                <div class="value_grid-entry">
                    <h6>Forced sale value</h6>
                    <h6>{{ priceFormat(forced_sale_value) }}</h6>
                </div>
                <div class="value_grid-entry">
                    <h6>Monthly rental value</h6>
                    <h6>
                        {{ priceFormat(getAvmRentalEstimations.asking_price_q50, false) }}
                    </h6>
                </div>
                <div v-if="!postReno" class="value_grid-entry value_grid-entry--grey">
                    <h6>Reconstruction value</h6>
                    <h6>
                        {{ priceFormat(reconstruction_value) }}
                    </h6>
                </div>
                <div v-if="!postReno" class="value_grid-entry value_grid-entry--grey">
                    <h6>Cadastral income</h6>
                    <h6>€ 123.000,00</h6>
                </div>
            </div>
        </template>
    </grid-card>
</template>
<script>
import GridCard from '../../elements/GridCard'
import utils from '@/shared/plugins/utils'
import { mapGetters } from 'vuex'

export default {
    name: 'ValueCard',
    components: {
        GridCard,
    },
    props: {
        postReno: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        cadastral_income: null,
        reconstruction_value: null,
        reconstruction_value_loaded: false,
        analyticsShown: false,
    }),
    computed: {
        ...mapGetters('valuationStore/Address', [
            'getAvmTransactionEstimations',
            'getAvmRentalEstimations',
            'getFeatures',
        ]),
        building_type() {
            return this.getFeatures.f_building_type
        },
        is_plot() {
            return utils.is_plot(this.building_type)
        },
        forced_sale_value() {
            return utils.forced_sale_value(
                this.getAvmTransactionEstimations.asking_price_q50
            )
        },
        title() {
            return this.$t(
                `valuer_tool.valuation.value_${this.postReno ? 'post' : 'pre'}_reno`
            )
        },
    },
    mounted() {
        if (!this.is_plot) {
            this.getReconstructionValue()
        }
    },
    methods: {
        priceFormat(x, thousands = true) {
            return utils.priceFormat(x, thousands)
        },
        getReconstructionValue() {
            if (this.is_plot) return 'N/A'
            let url = utils.urlJoin(process.env.VUE_APP_API_URL || '', [
                'estimate',
                'reconstruction',
                this.getFeatures.building_id,
            ])
            url += `?f_building_type=${this.getFeatures.f_building_type}&f_living_area=${this.getFeatures.f_living_area}`
            this.$axios.get(url).then((res) => {
                this.reconstruction_value = res.data.reconstruction_value
                    ? Math.round(res.data.reconstruction_value / 1000) * 1000
                    : null
                this.reconstruction_value_loaded = true
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.value_grid {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &-entry {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        h6 {
            color: #001837;
            font-size: 14px;
            font-weight: 500;
        }

        &--grey {
            opacity: 0.5;
        }
    }
}
</style>
