<template>
    <div class="reference_listing">
        <div
            class="reference_listing-header"
            @click="$emit('select', { listing, src: 'list' })"
        >
            <div class="reference_listing-header-left">
                <div class="icon-badge">
                    <svg-icon
                        icon="marker"
                        color="#0096FF"
                        :fill="false"
                        height="20"
                        scale="1"
                    />
                </div>
                <p class="reference_listing-header-left-address">
                    {{ listing.streetname }} {{ listing.streetnumber }}
                    <br />
                    {{ listing.postalcode }} {{ listing.municipality }}
                </p>
            </div>
            <div class="reference_listing-header-right">
                <div class="reference_listing-header-right-price">
                    {{ priceFormat(listing.price_corr) }}
                </div>
                <img :src="chevron" :class="{ toggled: selected, chevron: true }" />
            </div>
        </div>
        <div v-if="selected" class="reference_listing-content">
            <div
                v-if="listing.description"
                class="reference_listing-content-description_container"
            >
                <h4 class="reference_listing-content-description_container-title">
                    Description
                </h4>
                <p
                    class="reference_listing-content-description_container-content"
                    :class="
                        hasLongDescription && !show_long_descr
                            ? 'reference_listing-content-description_container-content--short'
                            : 'reference_listing-content-description_container-content--long'
                    "
                >
                    {{ listing.description }}
                </p>
                <clear-button
                    v-if="hasLongDescription"
                    class="show_more_buttn"
                    @click="toggleDescription"
                >
                    Show {{ show_long_descr ? 'less' : 'more' }}
                </clear-button>
            </div>
            <table class="reference_listing-content-table">
                <thead>
                    <th />
                    <th>{{ $t('valuer_tool.reference_listing.assessed') }}</th>
                    <th>{{ $t('valuer_tool.reference_listing.reference') }}</th>
                </thead>
                <tr>
                    <td>{{ $t('valuer_tool.reference_listing.listing_date') }}</td>
                    <td>{{ dateFormat(referenceDate) }}</td>
                    <td>
                        {{ dateFormat(listingDate(listing.toegevoegd_num)) }}
                    </td>
                </tr>
                <template v-if="is_building">
                    <tr>
                        <td>
                            {{ $t('features.f_epc') }}
                        </td>
                        <td>
                            {{ getFeatures.f_epc }}
                        </td>
                        <td>
                            {{ Math.round(listing.f_epc) }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {{ $t('features.f_construction_year') }}
                        </td>
                        <td>
                            {{ getFeatures.f_construction_year }}
                        </td>
                        <td>
                            {{ Math.round(listing.f_construction_year) }}
                        </td>
                    </tr>
                    <tr v-if="is_house">
                        <td>
                            {{ $t('valuer_tool.reference_listing.touching_sides') }}
                        </td>
                        <td>
                            {{ getFeatures.f_touching_sides }}
                        </td>
                        <td>
                            {{ listing.f_touching_sides }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {{ $t('features.f_living_area') }}
                        </td>
                        <td>
                            {{ Math.round(getFeatures.f_living_area) }}
                        </td>
                        <td>
                            {{ Math.round(listing.f_living_area) }}
                        </td>
                    </tr>
                    <tr v-if="is_house">
                        <td>
                            {{ $t('features.f_building_area') }}
                        </td>
                        <td>
                            {{ Math.round(getFeatures.f_building_area) }}
                        </td>
                        <td>
                            {{ Math.round(listing.f_building_area) }}
                        </td>
                    </tr>
                </template>
                <tr v-if="!is_apartment">
                    <td>
                        {{ $t('features.f_parcel_area') }}
                    </td>
                    <td>
                        {{ Math.round(getFeatures.f_parcel_area) }}
                    </td>
                    <td>
                        {{ Math.round(listing.f_parcel_area) }}
                    </td>
                </tr>
                <tr v-if="is_house">
                    <td>
                        {{ $t('features.f_garden_area') }}
                    </td>
                    <td>
                        {{ Math.round(getFeatures.f_garden_area) }}
                    </td>
                    <td>
                        {{ Math.round(listing.f_garden_area) }}
                    </td>
                </tr>
                <tr>
                    <td>
                        {{ $t('valuer_tool.reference_listing.distance') }}
                    </td>
                    <td>
                        ---
                    </td>
                    <td>
                        {{ Math.round(listing.distance) }}
                    </td>
                </tr>
                <tr>
                    <td>
                        {{ $t('valuer_tool.reference_listing.price') }}
                    </td>
                    <td>
                        {{ priceFormat(price) }}
                    </td>
                    <td>{{ priceFormat(listing.prijs) }}</td>
                </tr>
                <tr>
                    <td>
                        {{ $t('valuer_tool.reference_listing.price_sqm') }}
                    </td>
                    <td>
                        {{
                            priceFormat(
                                price /
                                    (is_building
                                        ? getFeatures.f_living_area
                                        : getFeatures.f_parcel_area),
                                false
                            )
                        }}
                    </td>
                    <td>
                        {{
                            priceFormat(
                                listing.prijs /
                                    (is_building
                                        ? listing.f_living_area
                                        : listing.f_parcel_area),
                                false
                            )
                        }}
                    </td>
                </tr>
                <tr>
                    <td>Corrected price</td>
                    <td>
                        {{ priceFormat(price) }}
                    </td>
                    <td>
                        {{ priceFormat(listing.price_corr) }}
                    </td>
                </tr>
                <tr>
                    <td>Corrected price/sqm</td>
                    <td>
                        {{
                            priceFormat(
                                price /
                                    (is_building
                                        ? getFeatures.f_living_area
                                        : getFeatures.f_parcel_area),
                                false
                            )
                        }}
                    </td>
                    <td>
                        {{
                            priceFormat(
                                listing.price_corr /
                                    (is_building
                                        ? listing.f_living_area
                                        : listing.f_parcel_area),
                                false
                            )
                        }}
                    </td>
                </tr>
                <tr>
                    <td>GMaps</td>
                    <td>
                        <a :href="googleMaps(center)" target="_blank">
                            URL
                        </a>
                    </td>
                    <td>
                        <a :href="googleMaps([listing.lat, listing.lng])" target="_blank">
                            URL
                        </a>
                    </td>
                </tr>
                <tr v-if="is_house">
                    <td>
                        {{ $t('features.level') }}
                    </td>
                    <td>
                        {{ getFeatures.level }}
                    </td>
                    <td>
                        {{ listing.level }}
                    </td>
                </tr>
                <tr v-if="is_apartment">
                    <td>
                        {{ $t('features.f_balcony_present') }}
                    </td>
                    <td>
                        {{
                            getFeatures.f_balcony_present
                                ? $t('value.yes')
                                : $t('value.no')
                        }}
                    </td>
                    <td>
                        {{ listing.f_balcony_present ? $t('value.yes') : $t('value.no') }}
                    </td>
                </tr>
                <tr v-if="is_apartment">
                    <td>
                        {{ $t('features.f_bedrooms') }}
                    </td>
                    <td>
                        {{ getFeatures.f_bedrooms }}
                    </td>
                    <td>
                        {{ listing.f_bedrooms }}
                    </td>
                </tr>
                <tr>
                    <td>
                        {{ $t('valuer_tool.reference_listing.streetnumber') }}
                    </td>
                    <td>
                        {{ getAddress.streetnumber }}
                    </td>
                    <td>
                        {{ listing.streetnumber }}
                    </td>
                </tr>
                <!-- <tr>
                    <td>
                        {{ $t('use_as_reference') }}
                    </td>
                    <td />
                    <td>
                        <a @click="() => addListingToRemarks(listing)">
                            {{ $t('add_to_remarks') }}
                        </a>
                    </td>
                </tr> -->
            </table>
        </div>
    </div>
</template>
<script>
import chevron from '@/shared/assets/icons/chevron.svg'
import ClearButton from '@/components/elements/ClearButton.vue'
import utils from '@/shared/plugins/utils'
import { mapGetters } from 'vuex'

export default {
    name: 'Listing',
    components: {
        ClearButton,
    },
    props: {
        listing: {
            type: Object,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        chevron,
        show_long_descr: false,
    }),
    computed: {
        ...mapGetters('valuationStore/Address', [
            'getFeatures',
            'getAddress',
            'getAvmTransactionEstimations',
        ]),
        hasLongDescription() {
            return this.listing.description.length > 130
        },
        building_type() {
            return this.getFeatures.f_building_type
        },
        is_building() {
            return utils.is_building(this.building_type)
        },
        is_apartment() {
            return utils.is_apartment(this.building_type)
        },
        is_house() {
            return utils.is_house(this.building_type)
        },
        referenceDate() {
            return this.$config.ESTIM_DATA_SCHEMA_DATE
        },
        center() {
            return [this.getFeatures.f_lat, this.getFeatures.f_lng]
        },
        price() {
            return this.getAvmTransactionEstimations.asking_price_q50
        },
    },
    methods: {
        toggleDescription() {
            this.show_long_descr = !this.show_long_descr
        },
        listingDate(days) {
            const refDate = new Date(this.referenceDate)
            refDate.setDate(refDate.getDate() + days)
            return refDate.toISOString().split('T')[0]
        },
        dateFormat(date) {
            return date.replace(/-/g, '/')
        },
        googleMaps([lat, lng]) {
            return `https://www.google.com/maps?q=${lat},${lng}&z=19`
        },
        priceFormat(x, thousands = true) {
            return utils.priceFormat(x, thousands)
        },
    },
}
</script>
<style lang="scss" scoped>
.reference_listing {
    &-header {
        border-bottom: 1px solid #d6e2e9;
        padding: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #001837;
        cursor: pointer;

        .icon-badge {
            height: 32px;
            width: 32px;
            border-radius: 50%;
            background: rgba(0, 150, 255, 0.2);
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-left {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 12px;

            &-address {
                font-size: 12px;
                font-weight: 500;
                line-height: normal;
            }
        }

        &-right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 12px;

            &-price {
                font-size: 18px;
                font-weight: 700;
                line-height: normal;
            }
        }

        img.chevron {
            width: 13px;
            &.toggled {
                transform: scaleY(-1);
            }
        }
    }

    &-content {
        border-bottom: 1px solid #d6e2e9;
        padding: 24px;
        font-size: 12px;

        &-description_container {
            margin-bottom: 16px;

            &-title {
                color: #798da6;
                font-weight: 500;
                line-height: normal;
            }

            &-content {
                color: #001837;
                font-weight: 500;
                --lh: 1rem;
                line-height: var(--lh);
                transition: all 0.2s ease-out;
                margin-top: 4px;

                &--short {
                    max-height: calc(var(--lh) * 2);
                    overflow: hidden;
                }

                &--long {
                    max-height: fit-content;
                }
            }

            .show_more_buttn {
                color: #009cff;
                font-size: 12px;
                font-weight: 700;
                padding: 0;
                margin-top: 4px;
            }
        }

        &-table {
            font-size: 12px;
            width: 100%;

            th,
            td {
                padding: 4px 0;

                &:first-child {
                    padding-right: 32px;
                }

                &:last-child {
                    padding-left: 40px;
                    padding-right: 8px;
                }

                &:nth-child(2) {
                    padding-left: 12px;
                    padding-right: 12px;
                    background: #009cff26;
                }
            }

            thead {
                th {
                    padding-top: 8px;
                    color: #001837;
                    font-weight: 700;
                    line-height: normal;

                    &:nth-child(2) {
                        border-radius: 8px 8px 0 0;
                    }
                }
            }

            tr {
                td {
                    color: #001837;
                    font-weight: 500;
                    line-height: normal;

                    &:first-child {
                        color: #798da6;
                        font-weight: 500;
                        line-height: normal;
                    }
                }

                &:last-child {
                    td {
                        padding-bottom: 8px;

                        &:nth-child(2) {
                            border-radius: 0 0 8px 8px;
                        }
                    }
                }
            }
        }
    }
}
</style>
