<template>
    <grid-card :title="$t(`${configLocation}.${feature}`)" :is-full="full">
        <multi-choice
            :wide-options="!full && !small"
            :options="values"
            :vertical="small"
            :feature="feature"
            @select="selectValue"
        />
    </grid-card>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import utils from '@/shared/plugins/utils'
import GridCard from '..'
import MultiChoice from '../../MultiChoice.vue'
export default {
    name: 'MultiChoiceCard',
    components: {
        GridCard,
        MultiChoice,
    },
    props: {
        feature: {
            type: String,
            required: true,
        },
        configLocation: {
            type: String,
            default: 'dvm_features',
        },
        small: {
            type: Boolean,
            default: false,
        },
        full: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        value: null,
    }),
    computed: {
        ...mapGetters('valuationStore/Address', ['getFullBuildingData']),
        values() {
            return utils
                .generateValues(this.feature, this.value)
                .map((item) => ({ ...item, name: this.$t(`value.${item.value}`) }))
        },
    },
    watch: {
        value(val) {
            let key = this.configLocation
            let values = {}
            values[this.feature] = val
            this.SET_UNIVERSAL({ key, values })
        },
    },
    mounted() {
        this.value = this.getFullBuildingData[this.configLocation].hasOwnProperty(
            this.feature
        )
            ? this.getFullBuildingData[this.configLocation][this.feature]
            : null
    },
    methods: {
        ...mapMutations('valuationStore/Address', ['SET_UNIVERSAL']),
        selectValue(value) {
            this.value = value
        },
    },
}
</script>
