<template>
    <div class="ers-page">
        <div class="ers-page-header" />
        <transition mode="out-in" :name="transitionName">
            <router-view @open-summary-modal="openSummary"></router-view>
        </transition>
        <summary-modal ref="summaryModal" :unsubmit="unsubmitRequest" />
    </div>
</template>

<script>
import SummaryModal from './components/pages/result/SummaryModal'
export default {
    name: 'ERSLayout',
    components: {
        SummaryModal,
    },
    data() {
        return {
            transitionName: 'fade',
            unsubmitRequest: false,
        }
    },
    mounted() {},
    methods: {
        openSummary(action) {
            if (action.unsubmit) this.unsubmitRequest = true
            this.$refs.summaryModal.open()
        },
    },
}
</script>

<style lang="scss">
.ers-next-bttn-dark {
    background: #001837 !important;
}

.ers-page {
    background: #f9fafc;
    max-width: 100vw;
    flex-grow: 1;
    position: relative;
    &-content {
        padding: 1.25rem 3.75rem;
        display: flex;
        flex-direction: column;
        gap: 2.38rem;
    }

    &-section {
        display: flex;
        flex-direction: column;
        min-width: 100%;
        gap: 1.5rem;
    }

    &-header {
        width: 100%;
        padding: 60px;
        min-height: 180px;
        background: linear-gradient(180deg, rgba(249, 250, 252, 0) 0%, #f9fafc 100%),
            url('../../assets/img/city.jpg') no-repeat,
            lightgray 0px -250px / 100% 356.25% no-repeat;
        background-size: cover;
        background-position: center right;
    }
    &-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        max-width: 100%;

        p {
            color: #798da6;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    h1 {
        color: #001837;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    &-info {
        display: flex;
        padding: 1rem;
        align-items: center;
        gap: 1rem;
        border-radius: 0.3125rem;
        background: rgba(0, 156, 255, 0.1);
        max-width: fit-content;
        color: #6583a8;

        img {
            display: flex;
            width: 2.5rem;
            height: 2.5rem;
            padding: 0.625rem;
            justify-content: center;
            align-items: center;
            gap: 0.625rem;
            border-radius: 3.125rem;
            background: rgba(0, 156, 255, 0.2);
        }
    }
}

.ers-page-modal {
    background: rgba(0, 24, 55, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: 2000;
    display: flex;

    &__card {
        display: flex;
        padding: 1.5rem;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
        align-self: stretch;
        border-radius: 0.3125rem;
        border: 1px solid #d6e2e9;
        background: #fff;
        margin: auto;

        &-text {
            max-width: 27rem;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;

            h2 {
                color: #001837;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 700;
                line-height: 1.5625rem;
            }
            p {
                color: #798da6;
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        &-buttons {
            display: flex;
            justify-content: space-between;
            gap: 0.75rem;
            padding: 1.5rem;
            padding-block-end: 0;
            margin-inline: -1.5rem;
            align-self: stretch;
            border-top: 1px solid #d6e2e9;
            background: #fff;
        }
    }
}
</style>
