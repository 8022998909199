<template>
    <div class="ers-page-content">
        <div class="ers-page-title">
            <h1>
                {{ $t('energy.heating.question') }}
                <Tooltip :label="$t('energy.heating.tooltip')" />
            </h1>
        </div>

        <div class="ers-page-section">
            <div class="ers-page-section__title">
                <svg-icon
                    icon="heating-fuel-gas"
                    width="1rem"
                    height="1.57rem"
                    color="#798DA6"
                />
                <p>{{ $t(`value.gas`) }}</p>
            </div>
            <div class="ers-page-section-choices">
                <div
                    v-for="type in gasTypes"
                    :key="type.value"
                    class="ers-page-section-choices__item"
                    :class="{
                        active: isActive('gas', type.value),
                    }"
                    @click="setHeating('gas', type.value)"
                >
                    <div class="ers-page-icon-wrapper">
                        <svg-icon
                            icon="heating-fuel-gas"
                            width="1.625rem"
                            height="2.56613rem"
                            :color="isActive('gas', type.value) ? '#0096ff' : '#798DA6'"
                        />
                    </div>
                    <p>{{ $t(`value.${type.value}`) }}</p>
                </div>
            </div>
        </div>

        <div class="ers-page-section">
            <div class="ers-page-section__title">
                <svg-icon
                    icon="heating-fuel-oil"
                    width="1rem"
                    height="1.57rem"
                    color="#798DA6"
                />
                <p>{{ $t(`value.oil`) }}</p>
            </div>
            <div class="ers-page-section-choices">
                <div
                    v-for="type in oilTypes"
                    :key="type.value"
                    class="ers-page-section-choices__item"
                    :class="{
                        active: isActive('oil', type.value),
                    }"
                    @click="setHeating('oil', type.value)"
                >
                    <div class="ers-page-icon-wrapper">
                        <svg-icon
                            icon="heating-fuel-oil"
                            width="1.625rem"
                            height="2.56613rem"
                            :color="isActive('oil', type.value) ? '#0096ff' : '#798DA6'"
                        />
                    </div>
                    <p>{{ $t(`value.${type.value}`) }}</p>
                </div>
            </div>
        </div>

        <div class="ers-page-section">
            <div class="ers-page-section__title">
                <svg-icon
                    icon="heating-fuel-electric"
                    width="1rem"
                    height="1.57rem"
                    color="#798DA6"
                />
                <p>{{ $t(`value.electricity`) }}</p>
            </div>
            <div class="ers-page-section-choices">
                <div
                    v-for="type in electricityTypes"
                    :key="type.value"
                    class="ers-page-section-choices__item"
                    :class="{
                        active: isActive('electricity', type.value),
                    }"
                    @click="setHeating('electricity', type.value)"
                >
                    <div class="ers-page-icon-wrapper">
                        <svg-icon
                            icon="heating-fuel-electric"
                            width="1.625rem"
                            height="2.56613rem"
                            :color="
                                isActive('electricity', type.value)
                                    ? '#0096ff'
                                    : '#798DA6'
                            "
                        />
                    </div>
                    <p>{{ $t(`value.${type.value}`) }}</p>
                </div>
            </div>
        </div>

        <div class="ers-page-section">
            <div class="ers-page-section__title">
                <p>{{ $t(`value.other`) }}</p>
            </div>
            <div class="ers-page-section-types">
                <div
                    v-for="type in fuels"
                    :key="type.value"
                    class="ers-page-section-choices__item"
                    :class="{
                        active: getSelectedFuel === type.value,
                    }"
                    @click="setHeating(type.value, getOtherTypes(type.value))"
                >
                    <div class="ers-page-icon-wrapper">
                        <svg-icon
                            :icon="type.icon"
                            width="1.625rem"
                            height="2.56613rem"
                            :color="
                                getSelectedFuel === type.value ? '#0096ff' : '#798DA6'
                            "
                        />
                    </div>
                    <p>{{ $t(`value.${type.value}`) }}</p>
                </div>
            </div>
        </div>

        <div v-if="showInstallYear">
            <h1 class="installation-year-title">
                {{ $t('features_question.installation_year') }}
            </h1>
            <year-selector
                v-model="heating_attributes[0].installation_year"
                :minimum="getFeatures.f_construction_year || 1800"
            />
        </div>

        <div class="column is-12 mt-2">
            <div class="is-flex is-justify-content-space-between">
                <b-button
                    id="nextButton"
                    size="is-medium"
                    type="is-dark"
                    icon-left="arrow-left"
                    @click="changePage(false)"
                >
                    {{ $t('common.previous') }}
                </b-button>
                <submit-button
                    @on-click="
                        $emit('open-summary-modal', {
                            unsubmit: false,
                        })
                        changePage(true)
                    "
                />
                <b-button
                    v-if="showNextButton"
                    :class="{ 'ers-next-bttn-dark': hasRequestBeenUnsubmitted }"
                    size="is-medium"
                    type="is-primary"
                    icon-right="arrow-right"
                    @click="changePage(true)"
                >
                    <span>{{ $t('common.next') }}</span>
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import MultipleChoice from '../../elements/MultipleChoice.vue'
import YearSelector from '../../elements/YearSelector.vue'
import SubmitButton from '../../elements/SubmitButton.vue'
import Tooltip from '../../elements/Tooltip.vue'

export default {
    name: 'Heating',
    components: {
        YearSelector,
        Tooltip,
        SubmitButton,
    },
    props: {
        proceedFn: {
            type: Function,
            required: false,
            default: null,
        },
        cancelFn: {
            type: Function,
            required: false,
            default: null,
        },
    },
    data: () => ({
        heating_attributes: [
            {
                heating_type: null,
                fuel: null,
                installation_year: null,
            },
        ],
        isFirstPass: true,
        gasTypes: [
            {
                value: 'boiler:standard',
            },
            {
                value: 'boiler:condensing',
            },
            {
                value: 'stove',
            },
        ],
        oilTypes: [
            {
                value: 'boiler:condensing',
            },
            {
                value: 'stove',
            },
        ],
        electricityTypes: [
            {
                value: 'heat_pump',
            },
            {
                value: 'electric_heater',
            },
        ],
    }),

    computed: {
        ...mapGetters('ersStore', [
            'getFeatures',
            'getOtherData',
            'hasRequestBeenUnsubmitted',
        ]),
        fuels() {
            return [
                {
                    value: 'wood',
                    icon: 'heating-fuel-wood',
                },
                {
                    value: 'pellets',
                    icon: 'heating-fuel-pellets',
                },
                {
                    value: 'coal',
                    icon: 'heating-fuel-coal',
                },
                {
                    value: 'district_heating',
                    icon: 'heating-fuel-district-heating',
                },
            ]
        },
        types() {
            let types = []

            if (['gas'].includes(this.heating_attributes[0].fuel)) {
                types.push({
                    value: 'boiler:standard',
                })
            }

            if (['gas', 'oil'].includes(this.heating_attributes[0].fuel)) {
                types.push({
                    value: 'boiler:condensing',
                })
            }

            if (
                ['gas', 'oil', 'wood', 'pellets', 'coal'].includes(
                    this.heating_attributes[0].fuel
                )
            ) {
                types.push({
                    value: 'stove',
                })
            }

            if (['electricity'].includes(this.heating_attributes[0].fuel)) {
                types.push({
                    value: 'heat_pump',
                })
                types.push({
                    value: 'electric_heater',
                })
            }
            return types
        },
        showNextButton() {
            if (
                this.heating_attributes[0].fuel === 'district_heating' ||
                (this.heating_attributes[0].fuel &&
                    this.heating_attributes[0].heating_type &&
                    this.heating_attributes[0].installation_year >=
                        this.getFeatures.f_construction_year)
            )
                return true
            return false
        },
        showHeatingType() {
            return (
                this.heating_attributes[0].fuel &&
                !['district_heating', 'wood', 'coal', 'pellets'].includes(
                    this.heating_attributes[0].fuel
                )
            )
        },
        showInstallYear() {
            return (
                ['wood', 'coal', 'pellets'].includes(this.heating_attributes[0].fuel) ||
                (this.heating_attributes[0].heating_type &&
                    this.heating_attributes[0].heating_type !== 'district_heating')
            )
        },
        getSelectedFuel() {
            return this.heating_attributes[0].fuel
        },
        getSelectedHeatingType() {
            return this.heating_attributes[0].heating_type
        },
        isActive() {
            return (fuel, type) => {
                return (
                    this.getSelectedFuel === fuel && this.getSelectedHeatingType === type
                )
            }
        },
    },
    watch: {
        heating_attributes: {
            handler() {
                setTimeout(() => {
                    document.getElementById('nextButton').scrollIntoView()
                }, 50)
            },
            deep: true,
        },
        getSelectedFuel: {
            handler(newValue, oldValue) {
                // consumption datas needs to be reseted in case the user change his consumption fuel
                if (oldValue === null) return
                if (newValue !== oldValue) {
                    this.RESET_CONSUMPTION()
                }
            },
        },
    },
    mounted() {
        let heating_attribute = { ...this.getFeatures.heating_attributes[0] }
        if (heating_attribute.heating_type === 'district_heating') {
            heating_attribute.fuel = 'district_heating'
            heating_attribute.installation_year = this.getFeatures.f_construction_year
        } else {
            heating_attribute.heating_type = this.unsplit_type(
                heating_attribute.heating_type,
                heating_attribute.subtype
            )
        }
        delete heating_attribute.subtype
        if (heating_attribute.heating_type) {
            this.isFirstPass = false
            this.heating_attributes = [heating_attribute]
            if (!this.heating_attributes[0].installation_year)
                this.heating_attributes[0].installation_year = this.getFeatures.f_construction_year
        }
    },
    methods: {
        ...mapMutations('ersStore', ['UPDATE_STATUS']),
        ...mapMutations('ersStore', ['SET_HEATING_ATTRIBUTES', 'RESET_CONSUMPTION']),
        ...mapActions('ersStore', ['set_and_submit_feature']),
        changePage(isNextPage) {
            let heating_attributes = [
                {
                    ...this.heating_attributes[0],
                },
            ]
            if (
                heating_attributes[0].fuel === null &&
                heating_attributes[0].heating_type === null
            ) {
                // in case the user click on previous before filling the heating
                return this.$router.push({
                    name: isNextPage ? 'ers.solar-panels' : 'ers.window-glazing',
                    params: {
                        lang: this.$route.params.lang,
                    },
                    query: this.$route.query,
                })
            }
            if (this.heating_attributes[0].fuel === 'district_heating') {
                heating_attributes[0].heating_type = 'district_heating'
                heating_attributes[0].subtype = null
            } else {
                let split_type = this.split_type(this.heating_attributes[0].heating_type)
                heating_attributes[0].heating_type = split_type.heating_type
                heating_attributes[0].subtype = split_type.subtype
            }

            const nextPage = isNextPage ? 'ers.solar-panels' : 'ers.window-glazing'

            const feature = {
                heating_attributes: heating_attributes,
            }

            this.set_and_submit_feature({ feature, nextPage })
            this.$router.push({
                name: nextPage,
                params: {
                    lang: this.$route.params.lang,
                },
                query: this.$route.query,
            })
        },
        split_type(heating_type) {
            if (heating_type.includes(':')) {
                return {
                    heating_type: heating_type.split(':')[0],
                    subtype: heating_type.split(':')[1],
                }
            } else {
                return {
                    heating_type: heating_type,
                    subtype: null,
                }
            }
        },
        unsplit_type(heating_type, subtype) {
            if (subtype) {
                return `${heating_type}:${subtype}`
            } else {
                return heating_type
            }
        },
        setHeating(fuel, heating_type) {
            const newType = { heating_type, fuel }
            newType.installation_year = this.getFeatures.f_construction_year
            this.heating_attributes = [newType]
        },
        getOtherTypes(fuel) {
            if (['wood', 'pellets', 'coal'].includes(fuel)) {
                return 'stove'
            }

            if (['district_heating'].includes(fuel)) {
                return 'district_heating'
            }
        },
    },
}
</script>

<style lang="scss">
.ers-page-icon-wrapper {
    display: flex;
    width: 5rem;
    height: 5rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 3.75rem;
    border: 1px solid rgba(121, 141, 166, 0.2);
    background: rgba(121, 141, 166, 0.1);
}

.installation-year-title {
    margin-block-end: 2.38rem;
}

.ers-page-section {
    display: flex;
    flex-wrap: wrap;
    &__title {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        margin-block-end: 1.5rem;

        p {
            color: #001837;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }
    &-types {
        display: flex;
        gap: 1.5rem;
        flex-wrap: wrap;
    }
    &-choices {
        display: flex;
        flex-wrap: wrap;
        gap: 1.5rem;

        &__item {
            display: flex;
            width: 14.8125rem;
            padding: 1.5rem;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            border-radius: 0.3125rem;
            border: 1px solid #d6e2e9;
            background: #fff;
            &.active {
                div {
                    background: rgba(0, 156, 255, 0.2);
                }
            }

            &:hover {
                box-shadow: 0px 2px 14px rgba(0, 150, 255, 0.3);
            }

            p {
                color: #001837;
                text-align: center;
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-block-start: 1.25rem;
            }

            &.active {
                border: 1px solid #0096ff;
                box-shadow: 0px 2px 14px rgba(0, 150, 255, 0.3);

                p {
                    color: #0096ff;
                }
            }
        }
    }
}

@import '@/shared/assets/style/buefy.scss';

.is-small-tooltip {
    font-size: 14px;
    .info {
        height: 20px;
        width: 20px;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.is-tootip-btn {
    height: 24px;
    width: 24px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.is-input-number {
    .icons {
        width: 40px;
        font-size: 20px;
        div {
            height: 25px;
            &:first-child {
                border-top-right-radius: 4px;
                border-top: 1px solid;
                border-bottom: 1px solid;
                border-right: 1px solid;
                border-color: #e5eaed;
            }
            &:last-child {
                border-bottom: 1px solid;
                border-right: 1px solid;
                border-color: #e5eaed;
                border-bottom-right-radius: 4px;
            }
        }
    }
}

.grid-layout {
    display: grid;
    align-items: center;
    justify-items: start;
    column-gap: 1rem;
    row-gap: 0.5rem;
}

.heating-grid {
    grid-template-columns: repeat(3, auto);
}

.ventilation-grid {
    grid-template-columns: repeat(1, auto);
}

.renewables-grid {
    grid-template-columns: repeat(4, auto);
}

.additional-grid {
    grid-template-columns: repeat(3, auto);
}

.consumers-grid {
    grid-template-columns: repeat(2, auto);
}

.consumption-grid {
    grid-template-columns: repeat(2, auto);
}
</style>
