<template>
    <div class="documents_modal">
        <div class="documents_modal-header">
            <h4 class="documents_modal-header-title">State - Documents</h4>
            <clear-button>
                <svg-icon icon="close" />
            </clear-button>
        </div>
        <div class="documents_modal-content">
            <document
                v-for="doc in getDocuments"
                :key="`doc_${doc.document_ref}`"
                :file-name="doc.filename"
                :document-ref="doc.document_ref"
                :file-type="doc.content_type"
                @delete="delete_document"
            />
            <upload-area :request-ref="getRequestRef" @uploaded="add_documents" />
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ClearButton from '@/components/elements/ClearButton.vue'
import Document from './Document.vue'
import UploadArea from '@/apps/Valuation/components/ValuerTool/elements/UploadArea.vue'
import utils from '@/shared/plugins/utils'

export default {
    name: 'Documents',
    components: {
        ClearButton,
        Document,
        UploadArea,
    },
    data: () => ({
        uploading: false,
    }),
    computed: {
        ...mapGetters('valuationStore', ['getDocuments', 'getRequestRef']),
    },
    methods: {
        ...mapActions('valuationStore', ['remove_document', 'add_documents']),
        delete_document(document_ref) {
            this.$axios
                .delete(
                    utils.urlJoin(this.$config.VALUATION_API_URL, [
                        'document',
                        document_ref,
                    ])
                )
                .then((_) => {
                    this.remove_document(document_ref)
                })
        },
    },
}
</script>
<style lang="scss" scoped>
.documents_modal {
    height: fit-content;
    width: fit-content;
    border-radius: 8px;
    border: 1px solid #d6e2e9;
    background: white;
    box-shadow: 0px 1px 2px 0px rgba(0, 24, 55, 0.06),
        0px 4px 6px 0px rgba(0, 24, 55, 0.06);
    overflow: hidden;

    &-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        height: 32px;
        padding: 24px;
        border-bottom: 1px solid #d6e2e9;

        &-title {
            color: #001837;
            font-size: 20px;
            font-weight: 700;
            flex-grow: 1;
        }
    }

    &-content {
        display: flex;
        padding: 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
    }
}
</style>
