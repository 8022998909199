var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"valuation_page valuer_tool-default-layout"},[(_vm.getFeatures && _vm.getFeatures.building_id)?_c('div',{staticClass:"valuer_tool-default-layout-map_container"},[_c('re-map',{staticClass:"has-ratio",attrs:{"building-id":_vm.getFeatures.building_id,"parcel-ids":_vm.getFeatures.parcel_ids,"level":_vm.getFeatures.level,"region":_vm.getFeatures.region,"center":{
                lat: _vm.getFeatures.f_lat,
                lng: _vm.getFeatures.f_lng,
            },"map-scene2d":_vm.get_map_scene2d,"read-only":true,"focus-layer":'building',"layers-control-visible":false,"options":{
                zoomControl: true,
                attributionControl: false,
                scrollWheelZoom: false,
                attributionControl: false,
                scrollWheelZoom: false,
                doubleClickZoom: true,
                boxZoom: true,
                dragging: true,
            },"aerial-tile-visible":false,"cadastral-tile-visible":false},on:{"update:mapScene2d":function($event){return _vm.SET_MAP_SCENE2D($event)}}})],1):_vm._e(),_c('div',{staticClass:"valuer_tool-default-layout-grid"},[_c('h1',{staticClass:"valuer_tool-title"},[_vm._v("Valuation")]),_c('value-card'),_c('value-card',{attrs:{"post-reno":""}}),_c('grid-card',{attrs:{"title":"General comments & remarks","is-full":""}},[_c('b-input',{attrs:{"type":"textarea","rows":"5"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }