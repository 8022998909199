<template>
    <b-field class="admin-roles" :class="{ disabled: !editable }">
        <b-taginput
            :data="filteredRoles"
            size="is-small"
            autocomplete
            :allow-new="true"
            :open-on-focus="true"
            icon="label"
            :disabled="!editable"
            placeholder="Add a role"
            :value="roles"
            @input="
                {
                    $emit('input', $event)
                    autocomplete_text = ''
                }
            "
            @typing="autocomplete_text = $event"
        >
            <template #default="props">
                <b-taglist attached>
                    <b-tag type="is-primary">
                        {{ props.option.split(':')[0] }}
                    </b-tag>
                    <b-tag type="is-primary is-light" :closable="false">
                        {{ props.option.split(':')[1] }}
                    </b-tag>
                </b-taglist>
            </template>
            <template #selected="props">
                <b-taglist v-for="(role, index) in props.tags" :key="index" attached>
                    <b-tag type="is-primary">{{ role.split(':')[0] }}</b-tag>
                    <b-tag
                        type="is-primary is-light"
                        :closable="editable && !unremovable.includes(role)"
                        @close="remove_role(index)"
                    >
                        {{ role.split(':')[1] }}
                    </b-tag>
                </b-taglist>
            </template>
        </b-taginput>
    </b-field>
</template>

<script>
const ALL_ROLES = [
    '-:admin',
    'ers:submitter',
    'ers:substitute',
    'ers:reporter',
    'dvm:submitter',
    'dvm:substitute',
    'dvm:valuer',
    'dvm:dispatcher',
    'dvm:reporter',
    'ovm:valuer',
    'ovm:borrower',
    'ovm:dispatcher',
    'ovm:reporter',
]

export default {
    name: 'AdminRoles',
    model: {
        prop: 'roles',
        event: 'input',
    },
    props: {
        roles: { type: Array, required: true },
        editable: { type: Boolean, default: false },
        unremovable: { type: Array, default: () => [] },
    },
    data() {
        return {
            autocomplete_text: '',
        }
    },
    computed: {
        filteredRoles() {
            return ALL_ROLES.filter((role) => !this.roles.includes(role)).filter(
                (role) => {
                    return (
                        role
                            .toLowerCase()
                            .indexOf(this.autocomplete_text.toLowerCase()) >= 0
                    )
                }
            )
        },
    },
    methods: {
        remove_role(index) {
            this.$emit(
                'input',
                this.roles.filter((item, idx) => index !== idx)
            )
        },
    },
}
</script>

<style lang="scss">
.admin-roles {
    .tag {
        * {
            line-height: 1rem;
        }
    }

    &.disabled {
        .autocomplete.control {
            display: none;
        }
    }
}
</style>
