var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ers-page"},[_c('div',{staticClass:"ers-page-content"},[_c('div',{staticClass:"ers-page-title"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('energy.basement.question'))+" "),_c('Tooltip',{attrs:{"label":_vm.$t('energy.basement.tooltip')}})],1)]),_c('yes-no',{model:{value:(_vm.has_basement),callback:function ($$v) {_vm.has_basement=$$v},expression:"has_basement"}}),_c('div',{staticClass:"is-flex is-justify-content-space-between"},[_c('b-button',{attrs:{"size":"is-medium","type":"is-dark","icon-left":"arrow-left","tag":"router-link","to":{
                    name: 'ers.attic',
                    params: {
                        lang: _vm.$route.params.lang,
                    },
                    query: _vm.$route.query,
                }},on:{"click":function($event){return _vm.set_and_submit_feature({
                        has_basement: _vm.has_basement,
                    })}}},[_vm._v(" "+_vm._s(_vm.$t('common.previous'))+" ")]),_c('submit-button',{on:{"on-click":function($event){_vm.$emit('open-summary-modal', {
                        unsubmit: false,
                    })
                    _vm.nextPage()}}}),(_vm.has_basement !== null)?_c('b-button',{class:{ 'ers-next-bttn-dark': _vm.hasRequestBeenUnsubmitted },attrs:{"size":"is-medium","type":"is-primary","icon-right":"arrow-right"},on:{"click":_vm.nextPage}},[_c('span',[_vm._v(_vm._s(_vm.$t('common.next')))])]):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }