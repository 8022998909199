import i18n from '@/i18n'
import ValuationRequest from './components/ValuationRequest.vue'
import Avm from './components/avm_demo.vue'
import Dvm from './components/dvm'
import Report from './components/report/Report.vue'

import ValuerTool from './components/ValuerTool'
import General from './components/ValuerTool/pages/General'
import RefMap from './components/ValuerTool/pages/RefMap'
import Exterior from './components/ValuerTool/pages/Exterior'
import Surroundings from './components/ValuerTool/pages/Surroundings'
import InteriorPage from './components/ValuerTool/pages/Interior'
import InteriorTab from './components/ValuerTool/pages/Interior/tabs/InteriorTab.vue'
import Floorplan from './components/ValuerTool/pages/Interior/tabs/Floorplan'
import Floors from './components/ValuerTool/pages/Interior/tabs/Floorplan/Floors.vue'
import Rooms from './components/ValuerTool/pages/Interior/tabs/Floorplan/Rooms.vue'
import Valuation from './components/ValuerTool/pages/Valuation'

const valuationRoutes = [
    {
        name: 'valuation',
        redirect: {
            name: 'valuation.request',
        },
        path: '/valuation',
    },
    {
        name: 'valuation.request',
        component: ValuationRequest,
        path: '/valuation/request/:action',
    },
    {
        name: 'valuation.edit',
        path: '/valuation/request/edit',
    },
    {
        name: 'valuation.dvm',
        component: Dvm,
        path: '/valuation/dvm',
    },
    {
        name: 'valuation.avm',
        component: Avm,
        path: '/valuation/report/:step',
    },
    {
        name: 'valuation.report',
        component: Report,
        path: '/valuation/report',
    },
    {
        name: 'valuation.valuer-app',
        component: ValuerTool,
        path: '/valuation/valuer-app',
        redirect: {
            name: 'valuation.valuer-app.general',
        },
        children: [
            {
                name: 'valuation.valuer-app.general',
                path: 'general-info',
                component: General,
            },
            {
                name: 'valuation.valuer-app.ref_map',
                path: 'map',
                component: RefMap,
            },
            {
                name: 'valuation.valuer-app.exterior',
                path: 'exterior',
                component: Exterior,
            },
            {
                name: 'valuation.valuer-app.surroundings',
                path: 'surroundings',
                component: Surroundings,
            },
            {
                name: 'valuation.valuer-app.value',
                path: 'value',
                component: Valuation,
            },
            {
                name: 'valuation.valuer-app.interior',
                path: 'interior',
                component: InteriorPage,
                redirect: {
                    name: 'valuation.valuer-app.interior.info',
                },
                children: [
                    {
                        name: 'valuation.valuer-app.interior.info',
                        path: 'info',
                        component: InteriorTab,
                    },
                    {
                        name: 'valuation.valuer-app.interior.floorplan',
                        path: 'floorplan',
                        component: Floorplan,
                        redirect: {
                            name: 'valuation.valuer-app.interior.floorplan.floors',
                        },
                        children: [
                            {
                                name: 'valuation.valuer-app.interior.floorplan.floors',
                                path: 'floors',
                                component: Floors,
                            },
                            {
                                name: 'valuation.valuer-app.interior.floorplan.rooms',
                                path: 'rooms',
                                component: Rooms,
                            },
                        ],
                    },
                ],
            },
        ],
    },
]

export default valuationRoutes
