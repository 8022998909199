<template>
    <div class="exterior_page valuer_tool-default-layout">
        <div
            v-if="
                getFullValuationStore.Address.features &&
                    getFullValuationStore.Address.features.building_id
            "
            class="valuer_tool-default-layout-map_container"
        >
            <re-map
                :building-id="getFullValuationStore.Address.features.building_id"
                :parcel-ids="getFullValuationStore.Address.features.parcel_ids"
                :level="getFullValuationStore.Address.features.level"
                :region="getFullValuationStore.Address.features.region"
                :center="{
                    lat: getFullValuationStore.Address.features.f_lat,
                    lng: getFullValuationStore.Address.features.f_lng,
                }"
                :map-scene2d="getFullValuationStore.Address.map_scene2d"
                :read-only="true"
                :focus-layer="'building'"
                :layers-control-visible="false"
                :options="{
                    zoomControl: true,
                    attributionControl: false,
                    scrollWheelZoom: false,
                    attributionControl: false,
                    scrollWheelZoom: false,
                    doubleClickZoom: true,
                    boxZoom: true,
                    dragging: true,
                }"
                :aerial-tile-visible="false"
                :cadastral-tile-visible="false"
                class="has-ratio"
                @update:mapScene2d="SET_MAP_SCENE2D($event)"
            ></re-map>
        </div>
        <div class="valuer_tool-default-layout-grid">
            <h1 class="valuer_tool-title">Exterior</h1>
            <touching-sides />
            <historical />
            <multi-choice-card full feature="style_of_house" />
            <multi-choice-card full feature="exterior_state" />
            <multi-choice-card full feature="facade_material" />
            <multi-choice-card full feature="roof_material" />
            <roof-information />
            <solar-panels />
            <multi-choice-card full feature="window_type" config-location="features" />
            <grid-card title="Photos" />
            <grid-card title="Plans" />
            <h1 class="valuer_tool-title">Parcel</h1>
            <garden />
            <swimming-pool />
            <grid-card title="Photos" />
            <grid-card title="Plans" />
            <h1 class="valuer_tool-title">Annexes</h1>
            <garage />
            <annex feature="pool_house" />
            <annex feature="stables" />
            <annex feature="barn" />
            <grid-card title="Photos" />
            <grid-card title="Plans" />
        </div>
    </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import ReMap from '@/components/common/Map.vue'
import GridCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard'
import TouchingSides from './cards/Exterior/TouchingSides.vue'
import Historical from './cards/Exterior/Historical.vue'
import MultiChoiceCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard/variants/MultiChoiceCard.vue'
import RoofInformation from './cards/Exterior/RoofInformation.vue'
import SolarPanels from './cards/Exterior/SolarPanels.vue'
import Garden from './cards/Parcel/Garden.vue'
import SwimmingPool from './cards/Parcel/SwimmingPool.vue'
import Garage from './cards/Annexes/Garage.vue'
import Annex from './cards/Annexes/Annex.vue'

export default {
    name: 'Exterior',
    components: {
        ReMap,
        GridCard,
        TouchingSides,
        Historical,
        MultiChoiceCard,
        RoofInformation,
        SolarPanels,
        Garden,
        SwimmingPool,
        Garage,
        Annex,
    },
    computed: {
        ...mapGetters('valuationStore', ['getFullValuationStore']),
        ...mapGetters('valuationStore/Address', ['getFeatures']),
        building_type() {
            return this.getFeatures.f_building_type
        },
        is_house() {
            return utils.is_house(this.building_type)
        },
        is_apartment() {
            return utils.is_apartment(this.building_type)
        },
        is_plot() {
            return utils.is_plot(this.building_type)
        },
        is_building() {
            return utils.is_building(this.building_type)
        },
    },
    methods: {
        ...mapMutations('valuationStore/Address', ['SET_MAP_SCENE2D']),
    },
}
</script>
