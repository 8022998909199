<template>
    <grid-card>
        <div class="touching_sides-card single_line_card">
            <h5>Touching sides</h5>
            <b-input v-model="f_touching_sides" type="number" expanded />
        </div>
    </grid-card>
</template>
<script>
import GridCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard'
import { mapGetters, mapMutations } from 'vuex'
import debounce from 'lodash/debounce'

export default {
    name: 'TouchingSides',
    components: {
        GridCard,
    },
    data: () => ({
        f_touching_sides: 0,
    }),
    computed: {
        ...mapGetters('valuationStore/Address', ['getFeatures']),
    },
    watch: {
        // Debounce everything to not trigger updates on every keystroke
        f_touching_sides: debounce(function(val) {
            this.SET_FEATURES({
                f_touching_sides: val,
            })
        }, 250),
    },
    mounted() {
        this.f_touching_sides = this.getFeatures.f_touching_sides
    },
    methods: {
        ...mapMutations('valuationStore/Address', ['SET_FEATURES']),
    },
}
</script>
