<template>
    <grid-card is-full :title="$t(`ovm_features.${feature}`)">
        <template slot="header">
            <label class="flex-label">
                <h5>{{ $t(`valuer_tool.exterior.${feature}.question`) }}</h5>
                <b-checkbox v-model="has_annex" />
            </label>
        </template>
        <template v-if="has_annex" slot="default">
            <div class="grid_card-content_grid three-cols">
                <div class="content_grid-item">
                    <label>{{ $t(`ovm_features.${feature}_area`) }}</label>
                    <b-input v-model="annex_area" type="number" />
                </div>
            </div>
        </template>
    </grid-card>
</template>
<script>
import GridCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard'
import { mapGetters, mapMutations } from 'vuex'

export default {
    name: 'Annex',
    components: {
        GridCard,
    },
    props: {
        feature: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        has_annex: false,
        annex_area: null,
    }),
    computed: {
        ...mapGetters('valuationStore/Address', ['getOVMFeatures']),
    },
    watch: {
        // Debounce everything to not trigger updates on every keystroke
        has_annex(val) {
            let newFeatures = {}
            newFeatures[this.feature] = val
            this.SET_OVM_FEATURES(newFeatures)
        },
        annex_area(val) {
            let newFeatures = {}
            newFeatures[`${this.feature}_area`] = val
            this.SET_OVM_FEATURES(newFeatures)
        },
    },
    mounted() {
        this.has_annex =
            (this.getOVMFeatures.hasOwnProperty(this.feature) &&
                this.getOVMFeatures[this.feature]) ||
            false
        this.annex_area =
            (this.getOVMFeatures.hasOwnProperty(`${this.feature}_area`) &&
                this.getOVMFeatures[`${this.feature}_area`]) ||
            null
    },
    methods: {
        ...mapMutations('valuationStore/Address', ['SET_OVM_FEATURES']),
    },
}
</script>
