<template>
    <div class="ers-content address-page is-fluid is-max-desktop">
        <div
            class="columns is-mobile is-multiline is-variable"
            :class="[$vssWidth >= 768 ? 'is-6' : 'is-12']"
        >
            <div class="ers-content__texts px-3">
                <div class="column is-12 p-0">
                    <div class="ers-content__title">
                        {{ $t('energy.address.title') }}
                        <Tooltip :label="$t('energy.address.title_tooltip')" />
                    </div>
                </div>

                <div class="column is-12 p-0 py-3">
                    <div class="ers-content__subtitle">
                        {{ $t('energy.address.subtitle') }}
                    </div>
                </div>
            </div>

            <div class="column is-12 pb-1 columns">
                <!-- ADDRESS COLUMN -->
                <div class="column p-0">
                    <div class="column px-0">
                        <auto-complete
                            ref="autoComplete"
                            @selectedItem="setAddress($event)"
                        />
                    </div>

                    <div v-if="isActive || notFound" id="extra" class="px-0">
                        <!-- streetname input section -->
                        <div class="column px-0">
                            <div class="column px-0">
                                <b-field
                                    class="mt-1"
                                    :label="$t('address.streetname')"
                                    expanded
                                    :type="{ 'is-danger': $v.address.streetname.$error }"
                                    :message="[
                                        !$v.address.streetname.required &&
                                        $v.address.streetname.$error
                                            ? $t('validation.field_required')
                                            : null,
                                    ]"
                                >
                                    <b-input
                                        v-model="streetname"
                                        size="is-medium"
                                        lazy
                                        placeholder="Kleine Schuurstraat"
                                        @blur="
                                            updateAddress()
                                            $v.address.streetname.$touch()
                                        "
                                        @input="$v.address.streetname.$touch()"
                                    ></b-input>
                                </b-field>
                            </div>
                        </div>
                        <div class="columns is-variable is-multiline is-6">
                            <!-- streetnumber input section -->
                            <div class="column is-6">
                                <b-field
                                    :label="$t('address.streetnumber')"
                                    expanded
                                    :type="{
                                        'is-danger': $v.address.streetnumber.$error,
                                    }"
                                    :message="[
                                        !$v.address.streetnumber.required &&
                                        $v.address.streetnumber.$error
                                            ? $t('validation.field_required')
                                            : null,
                                    ]"
                                >
                                    <b-input
                                        v-model="streetnumber"
                                        size="is-medium"
                                        placeholder="8"
                                        lazy
                                        @blur="$v.address.streetnumber.$touch()"
                                        @input="
                                            updateAddress()
                                            $v.address.streetnumber.$touch()
                                        "
                                    ></b-input>
                                </b-field>
                            </div>
                            <!-- box number input section -->
                            <div class="column is-6">
                                <b-field :label="$t('address.boxnumber')" expanded>
                                    <b-input
                                        v-model="boxnumber"
                                        size="is-medium"
                                        placeholder="0"
                                    ></b-input>
                                </b-field>
                            </div>
                            <!-- postalcode input section -->
                            <div class="column is-6">
                                <b-field
                                    :label="$t('address.postalcode')"
                                    expanded
                                    :type="{
                                        'is-danger': $v.address.postalcode.$error,
                                    }"
                                    :message="[
                                        $v.address.postalcode.$error &&
                                        !$v.address.postalcode.required
                                            ? $t('address_page.field_required')
                                            : null,
                                        $v.address.postalcode.$error &&
                                        !$v.address.postalcode.minLength
                                            ? $t('validation.min_4_characters')
                                            : null,
                                        $v.address.postalcode.$error &&
                                        !$v.address.postalcode.numeric
                                            ? $t('validation.only_numbers')
                                            : null,
                                        $v.address.postalcode.$error &&
                                        !$v.address.postalcode.postalCodeValid
                                            ? `This postal code doesn't exists`
                                            : null,
                                    ]"
                                >
                                    <b-input
                                        v-model="postalcode"
                                        size="is-medium"
                                        lazy
                                        placeholder="0960"
                                        @blur="
                                            updateAddress()
                                            $v.address.postalcode.$touch()
                                        "
                                        @input="$v.address.postalcode.$touch()"
                                    ></b-input>
                                </b-field>
                            </div>
                            <!-- municipality input section -->
                            <div class="column is-6">
                                <b-field
                                    :label="$t('address.municipality')"
                                    expanded
                                    :type="{
                                        'is-danger': $v.address.municipality.$error,
                                    }"
                                    :message="[
                                        !$v.address.municipality.required &&
                                        $v.address.municipality.$error
                                            ? $t('validation.field_required')
                                            : null,
                                    ]"
                                >
                                    <b-input
                                        v-model="municipality"
                                        size="is-medium"
                                        lazy
                                        placeholder="Gent"
                                        @blur="
                                            updateAddress()
                                            $v.address.municipality.$touch()
                                        "
                                        @input="$v.address.municipality.$touch()"
                                    ></b-input>
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- MAP COLUMN -->
                <address-map
                    :address="getAddress.full_address"
                    @reset-map="handleReset"
                    @next-page="confirmBuilding"
                    @building-not-found="handleCheckPostalCode"
                    @update-address="updateAddress()"
                />
            </div>
        </div>
        <div v-if="is3dModelNotFound" class="ers-page-modal">
            <div class="ers-page-modal__card">
                <img src="@/assets/icons/ic-cube-warning.svg" alt="" srcset="" />
                <div class="ers-page-modal__card-text">
                    <h2>
                        {{ $t('energy.address.map.no_model.title') }}
                    </h2>
                    <p>
                        {{ $t('energy.address.map.no_model.subtitle') }}
                    </p>
                </div>
                <div class="ers-page-modal__card-buttons">
                    <b-button size="is-medium" type="is-dark" @click="resetPage">
                        <span>
                            {{ $t('energy.address.map.no_model.change_address') }}
                        </span>
                    </b-button>
                    <b-button
                        size="is-medium"
                        type="is-primary"
                        @click="nextPage(+getAddress.effective_level)"
                    >
                        <span>{{ $t('energy.address.map.no_model.start') }}</span>
                    </b-button>
                </div>
            </div>
        </div>
        <b-loading
            v-model="isLoading"
            class="ers-page-address-loading"
            :is-full-page="true"
            :can-cancel="false"
        ></b-loading>
        <div class="column is-12 mt-5 pl-0">
            <div class="is-flex is-justify-content-space-between">
                <b-button
                    size="is-medium"
                    type="is-dark"
                    icon-left="arrow-left"
                    tag="router-link"
                    :to="{
                        name: 'ers.building-type',
                        params: {
                            lang: $route.params.lang,
                        },
                        query: $route.query,
                    }"
                >
                    <span>{{ $t('common.previous') }}</span>
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import { required, numeric, minLength } from 'vuelidate/lib/validators'
import utils from '@/shared/plugins/utils'
import AutoComplete from '@/shared/components/AutoComplete'
import AddressMap from './AddressMap'
import PostalCodes from './postal_codes.json'
import Tooltip from '../../elements/Tooltip'

import { mapMutations, mapGetters, mapActions } from 'vuex'

const postalCodeValid = (value) => {
    return PostalCodes.filter((item) => +item.postcode === +value).length > 0
}

export default {
    name: 'Address',
    components: {
        AutoComplete,
        AddressMap,
        Tooltip,
    },
    data() {
        return {
            isLoading: false,
            is3dModelNotFound: false,
            addressData: null,
            notFound: false,
        }
    },
    validations: {
        address: {
            streetname: {
                required,
            },
            postalcode: {
                required,
                numeric,
                minLength: minLength(4),
                postalCodeValid,
            },
            streetnumber: {
                required,
            },
            municipality: {
                required,
            },
        },
    },
    computed: {
        ...mapGetters('ersStore', [
            'getAddress',
            'getFeatures',
            'getMapScene3d',
            'getRequestRef',
            'getBuildingType',
        ]),
        isActive() {
            return this.getAddress.streetname
        },
        streetname: {
            get() {
                return this.$store.state.ersStore.address_text.streetname
            },
            set(value) {
                this.SET_ADDRESS_TEXT({ streetname: value })
            },
        },
        streetnumber: {
            get() {
                return this.$store.state.ersStore.address_text.streetnumber
            },
            set(value) {
                this.SET_ADDRESS_TEXT({ streetnumber: value })
            },
        },
        boxnumber: {
            get() {
                return this.$store.state.ersStore.address_text.boxnumber
            },
            set(value) {
                this.SET_ADDRESS_TEXT({ boxnumber: value })
            },
        },
        postalcode: {
            get() {
                return this.$store.state.ersStore.address_text.postalcode
            },
            set(value) {
                this.SET_ADDRESS_TEXT({ postalcode: value })
            },
        },
        municipality: {
            get() {
                return this.$store.state.ersStore.address_text.municipality
            },
            set(value) {
                this.SET_ADDRESS_TEXT({ municipality: value })
            },
        },
        address() {
            return this.$store.state.ersStore.address_text
        },
        getFormCondition() {
            if (this.$v.address.$invalid) {
                return true
            }
            return false
        },
    },
    methods: {
        ...mapMutations('ersStore', [
            'SET_ADDRESS_TEXT',
            'SET_MAP_SCENE3D',
            'SET_MAP_SCENE2D',
            'RESET_MAP',
            'RESET_ADDRESS',
        ]),
        ...mapActions('ersStore', [
            'match_address',
            'fetch_map_data',
            'auto_select_parcels',
            'fetch_features',
            'fetch_level',
        ]),
        getMap(lvl) {
            if (lvl < 2) return (this.is3dModelNotFound = true)

            const url = `/map/scene${lvl < 3 ? '2d' : ''}?building_id=${
                this.getAddress.building_id
            }&hide_attributions=true&num_buildings=5`
            this.$axios.get(url).then((res) => {
                lvl < 3 ? this.SET_MAP_SCENE2D(res.data) : this.SET_MAP_SCENE3D(res.data)
            })
        },
        updateAddress() {
            const params = `streetname=${this.streetname}&streetnumber=${this.streetnumber}&postalcode=${this.postalcode}&municipality=${this.municipality}&minimum_level=0`
            this.$axios
                .get(`/match?${params}`)
                .then(({ data }) => {
                    this.setAddress(data)
                })
                .catch((e) => console.log(e))
        },
        setAddress(val) {
            if (val.type === 'not_found') {
                this.notFound = true
            }
            if (!val) return
            val.effective_level = val.level
            if (val) {
                this.$axios
                    .get(`/features/${val.building_id}`)
                    .then(({ data }) => {
                        const { exterior_wall_area } = data
                        if (
                            val.effective_level === '3' &&
                            (!exterior_wall_area || exterior_wall_area <= 0)
                        ) {
                            val.effective_level = '2'
                        }
                    })
                    .catch((e) => console.log(e))
                    .finally(() => {
                        this.SET_ADDRESS_TEXT(val)
                        this.RESET_MAP()
                        this.getMap(+val.effective_level)
                        this.addressData = val
                    })
            }
        },
        nextPage(effectiveLevel = null) {
            const nextPage = 'ers.attic'
            this.is3dModelNotFound = false
            this.isLoading = true
            this.match_address()
                .catch((error) => console.log(error))
                .then(() => {
                    this.$axios
                        .patch(
                            utils.urlJoin(
                                this.$config.VALUATION_API_URL,
                                `request/house/${this.getRequestRef}`
                            ),
                            {
                                address: this.getAddress,
                                features: {
                                    f_building_type: this.getBuildingType,
                                    effective_level: (
                                        effectiveLevel || this.getAddress.effective_level
                                    ).toString(),
                                    building_id: this.getAddress.building_id,
                                },
                                other_data: {
                                    last_page_info: {
                                        name: nextPage,
                                    },
                                },
                            }
                        )
                        .catch((error) => console.log(error))
                        .then(() => this.fetch_features())
                        .then(() => {
                            this.$router.push({
                                name: nextPage,
                                params: {
                                    lang: this.$route.params.lang,
                                },
                                query: this.$route.query,
                            })
                        })
                })
        },
        async confirmBuilding() {
            const level = await this.fetch_level()
            if (+level < 3) {
                this.is3dModelNotFound = true
            } else {
                this.nextPage(+this.getAddress.effective_level)
            }
        },
        resetPage() {
            this.RESET_ADDRESS()
            this.RESET_MAP()
            this.is3dModelNotFound = false
            this.$refs.autoComplete.reset()
        },
        handleReset(val) {
            this.getMap(val)
        },
        handleCheckPostalCode() {
            const isPostalCodeValid = PostalCodes.find(
                (item) => item.postcode === this.getAddress.postalcode
            )
            if (isPostalCodeValid) {
                this.nextPage(1)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.building-container {
    width: 100%;
    aspect-ratio: 1;
    border: 1px solid #e5eaed;
    border-radius: 5px;
    position: relative;

    .address-block {
        border-bottom: 1px solid #e4eaed;
    }

    .building-not-found {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background: rgba(241, 70, 104, 0.2);
        border: 1px solid #f14668;
        box-shadow: 0px 2px 14px rgba(0, 47, 80, 0.1);
        border-radius: 5px;
        height: fit-content;
        width: fit-content;
    }
}

.ers-content {
    &__texts {
        // background: blue;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    &__title {
        color: #001837;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        max-width: 39.1875rem;
    }
    &__subtitle {
        color: #798da6;
        font-family: Satoshi;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        max-width: 34.8125rem;
    }
}
</style>
