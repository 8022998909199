<!-- eslint-disable vue/no-v-html -->
<template>
    <b-tooltip v-if="label" :label="label" multilined position="is-top" size="is-large">
        <div>
            <span>
                <img
                    v-if="!warning"
                    class="ers-tooltip-icon"
                    src="@/assets/icons/ic-tooltip2.svg"
                    alt=""
                />
                <img
                    v-else
                    class="ers-tooltip-icon"
                    src="@/assets/icons/ic-tooltip-warning.svg"
                    alt=""
                />
            </span>
        </div>
    </b-tooltip>
    <div v-else-if="html" class="ers-tooltip-modal-container">
        <div class="ers-tooltip-text" @click.stop="isOpen = true">
            <p class="ers-tooltip-more-info">
                {{ $t('energy.shared.tooltip_more_info') }}
            </p>
            <img
                class="ers-tooltip-chevron"
                src="@/assets/icons/ic-chevron-right.svg"
                alt=""
                srcset=""
            />
        </div>
        <b-modal v-model="isOpen" :can-cancel="['x', 'escape']" class="ers-modal">
            <div class="ers-tooltip-modal">
                <div class="ers-tooltip-popup-modal-title">
                    <img
                        src="@/assets/icons/bttn-close-black.svg"
                        class="ers-modal-close-bttn"
                        alt=""
                        @click.stop="isOpen = false"
                    />
                </div>
                <vue-markdown :source="html" class="tooltip-markdown" />
            </div>
        </b-modal>
    </div>
</template>

<script>
import VueMarkdown from 'vue-markdown-render'

export default {
    name: 'Tooltip',
    components: {
        VueMarkdown,
    },
    props: {
        label: {
            type: String,
            default: null,
        },
        html: {
            type: String,
            default: null,
        },
        warning: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isOpen: false,
        }
    },
    watch: {
        isOpen(newVal) {
            this.$emit('update-tooltip-state', newVal)
        },
    },
}
</script>

<style lang="scss">
.ers-modal {
    .modal-background {
        background: rgba(0, 24, 55, 0.4);
    }

    .modal-content {
        button {
            display: none;
        }
        overflow-x: hidden;
    }
}

.ers-tooltip-modal-container {
    display: inline;
    max-width: fit-content;
}

.ers-tooltip-icon {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
}

.ers-tooltip-modal {
    display: flex;
    max-width: 43.75rem;
    padding: 2rem 2rem 2.5rem 2rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    border-radius: 0.3125rem;
    background: #fff;
    margin: auto;
    position: relative;
}

.ers-tooltip-popup-modal-title {
    position: absolute;
    left: 0;
    top: 2rem;
    left: 92%;
}

.ers-modal-close-bttn {
    width: 1.5rem;
    height: 1.5rem;
    margin-block: auto;
    cursor: pointer;
}

.ers-tooltip-text {
    display: flex;
    gap: 0.25rem;
    max-width: fit-content;
    cursor: pointer;
    &:hover {
        .ers-tooltip-more-info {
            text-decoration: underline;
        }
    }
    .ers-tooltip-more-info {
        display: flex;
        min-width: fit-content;
        color: #009cff;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding-top: 0.25rem;
    }
    .ers-tooltip-chevron {
        margin-top: auto;
    }
}

.tooltip-markdown {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    h1,
    h3 {
        color: #001837;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    h2 {
        color: #001837;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.25rem; /* 142.857% */
    }
    p,
    li {
        color: #001837;
        font-family: Satoshi;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
    }
    li:before {
        content: '- ';
    }
}
</style>
